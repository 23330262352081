import React, { useRef, useEffect } from 'react';
import { useLocation, Switch, Link } from 'react-router-dom';

import LayoutLanding from '../context/layoutLanding';

import { IoIosArrowForward } from 'react-icons/io';

import img from '../../static/assets/images/stl-lock.webp';

export default function LandingAbout() {

  const childRef = useRef();
  let location = useLocation();

  //useEffect(() => {
  //  const page = location.pathname;
  //  document.body.classNameList.add('is-loaded')
  //  childRef.current.init();
  //  trackPage(page);
  //  // eslint-disable-next-line react-hooks/exhaustive-deps
  //}, [location]);

  return (

    <LayoutLanding>


      <div class="">
              {/* <section class="container pt-12 shadow-lg h-100 bg-white rounded"> */}

              <div id="img_case"
                class="shadow w-100 "
              >
                  <img 
                    class="w-full w-100 h-[700px] overflow-hidden object-cover"
                    src={img} 
                    alt="img"
                  >

                  </img>
              </div>
              <div class="flex w-100 mt-7">
                <p class="ibm text-gray-400 mx-auto">
                    Image of Saint-Lambert Lock, QC.
                </p>
              </div> 


              <div class=" w-100 flex py-20 bg-white mx-auto px-24 space-x-20">


                  <div>
                  <header class=" space-y-1">
                    <h1 class="ibm mt-3 text-5xl tracking-tight font-extrabold text-gray-700">
                      About LDF
                    </h1>
                    <p class="ibm text-xl font-bold text-gray-300">
                      Since 1988
                    </p>
                  </header>

                  <div class="">
                    <p class="ibm leading-relaxed text-md py-12 pb-20">
                      Our transport, warehousing and logistics company is dedicated to providing flexible and reliable solutions for all of our clients' shipping needs. 
                      We understand that every business is unique and has specific requirements for their shipments, which is why we offer a wide range of services and customizable options.
                      Our mission is to consistently deliver on-time, cost-effective transportation and logistics services that meet and exceed the expectations of our clients.
                      <br></br>
                      <br></br>                    
                      We strive to be the best in the industry by constantly improving our processes and investing in technologies to ensure the best possible service.                            
                        We are always available to answer questions, address concerns, and offer solutions to any challenges that may arise.
                        Ultimately, our goal is to be a reliable and trusted partner for all of your transportation, warehousing and logistics needs.
                      </p>
                    </div>
                    </div>

                    <div>
                    <h2 class="ibm  text-4xl tracking-tight font-extrabold  text-gray-700">
                      Our Mission
                    </h2>
                    <p class="ibm py-4">
                          We have a long history of providing prompt and efficient services
                          to the marine industry. Originally, we specialized in transporting goods to vessels, 
                          but soon expanded our services to include transporting crew for all Canadian vessels, 
                          warehousing merchandise, providing laundry and mail services, and anything else our clients required.
                          <br></br>
                          <br></br>
                          Over the past 35 years, we have developed a reputation for high-quality service and 
                          a commitment to meeting our clients' needs. As part of this commitment, we constantly strive 
                          to improve our services and expand our capabilities, leveraging our decades of experience 
                          to find innovative solutions to the challenges faced by our clients.
                          <br></br>
                          <br></br>
                          We recognize that our clients' needs are constantly changing, and we are determined to meet 
                          these needs with innovative solutions and a commitment to providing the highest levels 
                          of service. With a focus on efficiency, reliability, and customer satisfaction, we are confident 
                          that we can deliver value to our clients in new industries and continue to build on our legacy.
                          We look forward to working with new clients and expanding our services to new industries in the years to come.
                        </p>
                        </div>

              </div>

              


       

   
      </div>

  </LayoutLanding>


    // <LayoutLanding>

    //   <section class="text-gray-600 body-font flex h-100 w-100 ">
    //     <div class="container px-5 flex my-auto mx-auto border-4 shadow-lg bg-white">
    //       <div class="container-fluid p-8">

    //       <p class="leading-relaxed text-md p-4">
    //             Our transport, warehousing and logistics company is dedicated to providing flexible and reliable solutions for all of our clients' shipping needs. 
    //             <br></br>
    //             <br></br>
    //             We understand that every business is unique and has specific requirements for their shipments, which is why we offer a wide range of services and customizable options.
    //             Our mission is to consistently deliver on-time, cost-effective transportation and logistics services that meet and exceed the expectations of our clients.
    //             <br></br>
    //             <br></br>
    //             We strive to be the best in the industry by constantly improving our processes and investing in technologies to ensure the best possible service.                            
    //             We are always available to answer questions, address concerns, and offer solutions to any challenges that may arise.
    //             <br></br>
    //             <br></br>
    //             Ultimately, our goal is to be a reliable and trusted partner for all of your transportation, warehousing and logistics needs.
    //           </p>

    //         {/* <h1>ABOUT</h1> */}
    //         <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">About the growth of LDF</p>
    //         <p class="py-4">
    //         Founded in 1988, we have a long history of providing prompt and efficient services
    //         to the marine industry. Originally, we specialized in transporting goods to vessels, 
    //         but soon expanded our services to include transporting crew for all Canadian vessels, 
    //         warehousing merchandise, providing laundry and mail services, and anything else our clients required.
    //         <br></br>
    //         <br></br>
    //         Over the past 35 years, we have developed a reputation for high-quality service and 
    //         a commitment to meeting our clients' needs. As part of this commitment, we constantly strive 
    //         to improve our services and expand our capabilities, leveraging our decades of experience 
    //         to find innovative solutions to the challenges faced by our clients.
    //         <br></br>
    //         <br></br>
    //         We recognize that our clients' needs are constantly changing, and we are determined to meet 
    //         these needs with innovative solutions and a commitment to providing the highest levels 
    //         of service. With a focus on efficiency, reliability, and customer satisfaction, we are confident 
    //         that we can deliver value to our clients in new industries and continue to build on our legacy.
    //         We look forward to working with new clients and expanding our services to new industries in the years to come.
    //         </p>

    //       </div>
    //     </div>
    //   </section>
      
    // </LayoutLanding>
  );
}

















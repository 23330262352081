import { filter } from 'd3';
import React, {Component} from 'react'
import { Navigate, useNavigate, Link } from 'react-router-dom';

import { useState } from 'react'    

import { GoPlus } from "react-icons/go";

import { IoCheckmarkCircleSharp } from "react-icons/io5";


// ACTIVITY

// id = 111111111
// client = "desgagnes"
// type = "order"
// date = "2025-01-28"
// time = "12:00:00"
// title = "Order Received"
// reference = "1695740469132"

function formatActivityDate(dateString, timeString) {
    // dateString = "2025-01-28"
    // timeString = "12:00:00"
  
    // Split the date and time
    const [year, month, day] = dateString.split('-');
    const [hour, minute, second] = timeString.split(':');
  
    // Create a date in local time
    const activityDate = new Date(
      Number(year),
      Number(month) - 1,    // zero-based month
      Number(day),
      Number(hour),
      Number(minute),
      Number(second)
    );
  
    // Now do the same "today"/"yesterday" comparison in local time
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
  
    const activityDateOnly = activityDate.toDateString();
    const todayOnly = today.toDateString();
    const yesterdayOnly = yesterday.toDateString();
  
    if (activityDateOnly === todayOnly) {
      return 'Today';
    } else if (activityDateOnly === yesterdayOnly) {
      return 'Yesterday';
    }
  
    return activityDate.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  }
  


// Utility function to format the date as "Today", "Yesterday", or a normal date
function formatDate(dateString) {
    const activityDate = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000);
    
    console.log("TODAY DATE: ", today);
    
    const activityDateOnly = activityDate.toDateString();
    const todayOnly = today.toDateString();
    const yesterdayOnly = yesterday.toDateString();
  
    if (activityDateOnly === todayOnly) {
      return 'Today';
    } else if (activityDateOnly === yesterdayOnly) {
      return 'Yesterday';
    }
  
    return activityDate.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  }

function DateSeparator({ date }) {
    return (
        <div className="my-2 mt-3 text-gray-500 text-sm font-semibold uppercase tracking-wide">
        {date}
        </div>
    );
}

// {
//     type: "Order",
//     client: "Client B",
//     date: "2025-01-28",
//     time: "12:00",
//     title: "Order Received",
//     message: "Order received from Client B",
//     status: "Received"
//  },

function Activity({ activity }) {

    const jobId = activity.jobId;

    return (
      <div 
        className={activity.type=="order"?
            "flex w-100 p-4 shadow-sm rounded-md !mb-0 bg-gray-50 "
        : activity.type=="job"?
            "flex w-100 p-4 shadow-sm rounded-md !mb-0  bg-gray-50"
        :
            "flex w-100 p-4 shadow-sm rounded-md !mb-0  bg-gray-100"
        }
            
        >      
        
        <div class="flex flex-col">
            {/* <p className="flex ibm text-green-500 mb-0">Order Added <GoPlus class="my-auto ml-1"/></p> */}
            {/* darkseagreen */}
            {/* mediumspringgreen */}
            {/* <IoCheckmarkCircleSharp size={30} color="seagreen"/> */}

            {/* plus sign */}

            {/* ORDER  */}
            {activity.type == "order"
            ?
                <React.Fragment>
                    <div class="flex">              
                        <span class="p-1 ml-1">
                        <svg class="stroke-blue-500" width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 12H20M12 4V20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        </span>  
                        
                        <p class="ibm ml-2 my-auto text-blue-500">
                            A NEW ORDER WAS ADDED.
                        </p>
                    </div>

                    <div class="flex-col">
                        <p class="ibm ml-2 mb-0 text-black">
                            Order 
                                <Link
                                    class="text-blue-500 underline mx-2"
                                    to={`/orders?id=${activity.source_id}`}
                                >
                                    {activity.reference}
                                </Link>
                            was added to the system.
                        </p>
                    </div>
                </React.Fragment>

            // JOB
            : activity.type == "job"
            ?
                <React.Fragment>
                    <div class="flex">   
                        <span class="p-1 ml-1">             
                            <svg class=" fill-green-500" width="20px" height="20px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" >
                                <path d="M0 0h48v48H0z" fill="none"/>
                                <g id="Shopicon">
                                    <polygon points="18,33.172 6,21.172 3.171,24 18,38.828 44.829,12 42,9.172 	"/>
                                </g>
                            </svg>
                        </span>
                        
                        <p class="ibm ml-2 my-auto text-green-500">
                            A NEW JOB WAS COMPLETED.
                        </p>
                    </div>

                    <div class="flex-col">
                        <p class="ibm ml-2 mb-0 text-black">
                            Job to
                                <Link
                                    class="text-blue-500 underline mx-2 tracking-wide"
                                    to={`/jobs?id=${activity.source_id}`}
                                >
                                   {activity.reference}
                                </Link>
                            was completed.
                        </p>
                    </div>
                </React.Fragment>
                : activity.type == "message"
                ?
                <React.Fragment>
                    <div class="flex">                
                        <svg class="stroke-green-500" width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 12H20M12 4V20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        
                        <p class="ibm ml-2 text-green-500">
                            A new message was added.
                        </p>
                    </div>

                    <div class="flex-col">
                        <p class="ibm ml-2 mb-0 text-black">
                            Message 
                                <button
                                    class="text-blue-500 underline mx-2"
                                >
                                    #1695740469132
                                </button>
                            was added to the system.
                        </p>
                    </div>
                </React.Fragment>
                : null}
        </div>
        {/* <p>{activity.client}</p> */}
        <div class="flex flex-col ml-auto text-gray-400">
            <p>{activity.date} {activity.time}</p>            
        </div>
      </div>
    );
  }

  

export default function ActivityBoard({ data: initialData, limit: limit, filterType: filterType }) {
    console.log('ActivityBoard');

    const sortedData = [...initialData].sort(
        (a, b) => new Date(a.date) - new Date(b.date)
    );

    // 3) Initialize React state with the passed-in data
    const [data, setData] = useState(initialData);

    const today = new Date();

    return (  
        <div className="w-100 z-10 max-h-fit pr-4">
            <div className="w-100 space-y-4">
                
                {data.map((activity, index) => {
                    // limit
                    if (index >= limit) {
                        return null;
                    }
           
                    // Check if this is the first item or if the date changed since the previous
                    const prevActivity = index > 0 ? data[index - 1] : null;
                    const currentDateFormatted = formatActivityDate(activity.date, activity.time);
                    const prevDateFormatted = prevActivity
                        ? formatActivityDate(prevActivity.date, prevActivity.time)
                        : null;

                    const showSeparator =
                        index === 0 || currentDateFormatted !== prevDateFormatted;

                    if (filterType=="all") {
                        return (
                            <React.Fragment key={index}>
                                
                            {showSeparator && (
                                <DateSeparator date={currentDateFormatted} />
                            )}
                                <Activity activity={activity} />
                            </React.Fragment>
                        );                        
                    }

                    if (activity.type == filterType) {                        
                        return (
                            <React.Fragment key={index}>
                            {showSeparator && (
                                <DateSeparator date={currentDateFormatted} />
                            )}
                                <Activity activity={activity} />
                            </React.Fragment>
                        );                        
                    }

                    return null;


                })}
            </div>
        </div>
    );
}
import React, { useRef, useEffect, useState } from 'react';
import { Navigate, useNavigate, Link } from 'react-router-dom';

import moment from 'moment';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';

import Loading from '../modules/loading.jsx';

import { GrDocumentText } from "react-icons/gr";

const { resetPage } = React;

export default function DataTable({
  username, 
  loading, 
  editQuantities, 
  totalWeight,
  data, 
  columns, 
  editFunc, 
  fileFunc, 
  assignFunc, 
  selectData, 
  onEditQuantity, 
  editTableData, 
  onSort, 
  readMessage, 
  deleteMessage 
}) {

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [tableColor, setTableColor] = useState(JSON.parse(localStorage.getItem("tableColor")) || false);

    //const [dataCopy, setDataCopy] = useState(JSON.parse(JSON.stringify(data)));

    // when data changes, reset page to 0
    useEffect(() => {
      setPage(0);
      //alert("ALERT")

      let table_color = JSON.parse(localStorage.getItem("tableColor"))
      //console.log("DATA", data);
      //console.log(table_color)
      //console.log(tableColor)

    }, [data]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    //<div class="ml-auto h-full flex-col flex-grow">
    //<Paper sx={{ position:"relative", height: "calc(100% - 6rem)", width:'100%', overflow:"hidden" }}>
    //  <TableContainer sx={{ maxHeight: "94%", height:"100%" }}>
        
    return (
      <div id="dataTable" class="ml-auto h-full flex-col flex-grow">
          <Paper sx={{ position:"relative", height: "calc(100% - 0rem)", width:'100%', overflow:"hidden" }}>
            <TableContainer sx={{ maxHeight: "91%", height:"100%",  }}>
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead  sx={{"& th": {
                    color: "rgba(46, 46, 46)",
                    backgroundColor: "#e2e8f0",
                    cursor: "pointer",
                    fontSize: "0.8rem",
                    height: "50px",
                    //fontWeight: "bold",
                    //borderBottom: "2px solid slategray",
                    //borderTop: "2px solid slategray",
                  },
                  "& th:hover": {
                    backgroundColor: "#cbd5e1",
                  }
                }}>
                  {data?.length == 0 ? // IF THERE IS NO DATA
                    <TableRow>
                      <TableCell colSpan={columns.length} align="center"></TableCell>
                    </TableRow>
                  :
                  <TableRow>
                    {columns.map((column) => {
                      if (column.hidden) {
                        return null
                      }
                      if (
                          (username !== 'admin' && column.id == 'edit') || 
                          (username !== 'admin' && column.id == 'client') || 
                          (username !== 'admin' && column.id == 'assign')) {
                        return null
                      }                       
                      if (column.id == 'quantity') {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: "50px" }}
                            onClick={() => onSort(column.id)}
                          >
                            {column.label}
                          </TableCell>
                        )                                                    
                      }

                      else {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                            onClick={() => onSort(column.id)}
                          >
                            {column.label}
                          </TableCell>
                        )
                      }
                    })}
                  </TableRow>
                  }
                </TableHead>
                <TableBody sx={{height:"100%"}}>
                  {data?.length == 0?// IF THERE IS NO DATA
                   loading?
                    <section className="flex items-center justify-center w-100 mt-[10rem]">
                        <div className="max-w-md text-center">
                          <h2 id="loadingTitle" className="ibm mb-8 font-extrabold text-5xl !text-gray-400">
                            <span className="sr-only"></span>
                            LOADING
                          </h2>
                          <p className="text-2xl font-semibold !text-gray-400">Searching for results...</p>
                          <div className="max-w-md text-center h-6 pt-4 ">
                            <Loading type={3} class="w-100 h-36" />
                          </div>
                        </div>
                    </section>
                    :

                    <section className="flex items-center justify-center w-full mt-[10rem]">
                      <div className="max-w-md text-center">
                        <p id="loadingTitle" className="ibm mb-8 text-6xl text-gray-400">
                          NO DATA
                        </p>
                        <p className="ibm text-2xl font-semibold text-gray-400">
                          No results found for this search.
                        </p>
                      </div>
                    </section>

                    :null
                  }
                  {data?
                  data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {                      
                      return (
                        <TableRow 
                          hover 
                          role="checkbox" 
                          class={
                            tableColor?
                              row["status"]=="Inventory"?"bg-white":
                              row["status"]=="Pending"?"bg-white":
                              row["status"]=="Delivered"?"bg-white"://"bg-blue-100":
                              row["status"]=="Delayed"?"bg-yellow-100":
                              row["status"]=="Damaged"?"bg-red-200":
                              row["status"]=="Lost"?"bg-orange-200":
                              "bg-white"   
                            : "bg-white"                         
                           }
                           tabIndex={-1} 
                           key={row.code}>                                                   
                          {columns.map((column) => {  
                            const value = row[column.id];
                            if (!(
                              column.hidden ||
                              column.id == 'status' ||
                              column.id == 'id' ||
                              column.id == 'datetime' ||
                              column.id == 'client' ||
                              column.id == 'edit' || 
                              column.id == 'read' ||
                              column.id == 'doc' || 
                              column.id == 'selected' ||
                              column.id == 'assign' ||
                              column.id == 'delete_mail' ||
                              column.id == 'editDescription' ||
                              (column.id == 'quantity' && editQuantities))) {                           
                              return (
                                  <TableCell key={column.id} align={column.align}>
                                      {column.format && typeof value === 'text'
                                      ? column.format(value)
                                      : value}
                                  </TableCell>                            
                              );
                            } 
                            if (column.hidden && column.id != 'edit' && column.id != 'doc' && column.id != 'assign') {
                              return null;
                            }
                            if (column.id == 'editDescription') {
                              return (
                                  <TableCell key={column.id} align={column.align}>
                                      <input 
                                        value={row.description}
                                        class="border-blue-500 border-2 indent-2 rounded p-1 bg-white focus:!outline-none"
                                        type="text"
                                        maxLength={120}
                                        style={{width:"350px"}}
                                        onChange={(e) => editTableData(row.id, column.id, e.target.value)}                                      
                                      />
                                  </TableCell>
                              )
                            }

                            if (column.id == 'status') {
                              return (
                                  <TableCell key={column.id} align={column.align}>
                                    {value == "Inventory"?
                                      <span class="ibm flex text-xs p-2 rounded-lg border-slate-700 border ">
                                        <span class="mr-1 ml-1 my-auto">•</span>
                                          {value.toUpperCase()}
                                        </span>
                                    :value == "Pending"?
                                      <span class="ibm flex p-2 text-xs rounded-lg border-gray-200 border-2 text-gray-400 bg-gray-100">
                                        <span class="mr-2 ml-1">•</span>
                                        {value.toUpperCase()}
                                      </span>
                                    :value == "Delivered"?
                                      <span class="ibm flex p-2 text-xs rounded-lg border-green-200 border-2 text-green-400 bg-green-100">
                                        <span class="mr-2 ml-1">•</span>
                                        {value.toUpperCase()}
                                      </span>
                                      :
                                      <span>
                                        {value}
                                      </span>
                                    }
                                      
                                  </TableCell>
                              )
                            }

                            if (column.id == 'datetime') {
                              return (
                                  <TableCell key={column.id} align={column.align}>
                                      <span class="font-bold">
                                        {value}
                                      </span>
                                  </TableCell>
                              )
                            }
                                  
                            if (column.id == 'quantity' && editQuantities) {
                              return (
                                  <TableCell style={{width: '50px'}} key={column.id} align={column.align}>
                                      <input style={{width:"70px"}} value={value} min={1} class="border-blue-500 border-2 indent-2 rounded p-1 bg-white focus:!outline-none" type="number" onChange={(e) => onEditQuantity(row, e.target.value)}/>
                                  </TableCell>
                              )                            
                            } 
                            if (column.id == 'selected') {
                              return (
                                  <TableCell key={column.id} align={column.align}>
                                      <Checkbox onChange={() => selectData(row)} checked={row.selected?true:false}/>
                                  </TableCell>
                              )
                            }   
                            if (column.id == 'edit' && username=="admin") {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                    <span onClick={() => editFunc(row, row.code)} class="flex text-red-400 hover:underline cursor-pointer">
                                      {column.label}
                                    </span>
                                </TableCell> 
                              )
                            }
   
                            if (column.id == 'read' && username=="admin") {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                    <span onClick={() => readMessage(row, row.code)} class="flex text-blue-400 hover:underline cursor-pointer">
                                      {column.label}
                                    </span>
                                </TableCell> 
                              )
                            }
                            if (column.id == 'delete_mail' && username=="admin") {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                    <span onClick={() => deleteMessage(row, row.code)} class="flex text-red-400 hover:underline cursor-pointer">
                                      {column.label}
                                    </span>
                                </TableCell> 
                              )
                            }
                            if (column.id == 'assign' && username=="admin") {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                    <span onClick={() => assignFunc(row, row.code)} class="flex text-green-600 hover:underline cursor-pointer">
                                      {column.label}
                                    </span>
                                </TableCell> 
                              )
                            }
                            if (column.id == 'client' && username=="admin") {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                     {column.format && typeof value === 'text'
                                      ? column.format(value)
                                      : value}
                                </TableCell> 
                              )
                            }
                            if (column.id == 'id') {
                              return (
                                  <TableCell key={column.id} align={column.align}>
                                      <span id={value}>
                                        {value}
                                      </span>
                                  </TableCell>
                              )
                            }
                            if (column.id == 'doc') {
                              return (
                                <TableCell key={column.id} align={column.align} 
                                  //class=" px-2 border-b border-gray-300">                                    
                                  class="px-2 border-b border-gray-300 text-base text-gray-900  "
                                  >
                                      <span onClick={() => fileFunc(row, row.code)} class="flex rounded text-blue-600 p-1 cursor-pointer hover:bg-slate-200">
                                        <span class="doc p-1">
                                          <GrDocumentText color="blue" size={14}/>
                                        </span>
                                        {column.label}
                                      </span>                                    
                                </TableCell> 
                              )
                            }
                          })}
                        </TableRow>
                      );
                    })
                  :null}
                </TableBody>
              </Table>
            </TableContainer>
            
            <div class="p-2 flex mt-2">
              {totalWeight?
                <p class="ibm text-md indent-2">Total: {totalWeight} LBS</p>
              :null}
            </div>
            
            <TablePagination
              pageSize={100}
              rowsPerPageOptions={[100]}
              //rowsPerPageOptions={[50, 100, 200]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}              
              sx={{position:"absolute",bottom:0,right:0}}
            />
          </Paper>
      </div>
    );
    
}
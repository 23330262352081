import React, { useRef, useEffect, useState } from 'react';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import useLogout from '../hooks/useLogout';

import useAuth from '../hooks/useAuth';
import useRefreshToken from '../hooks/useRefreshToken';

import Sidebar from '../modules/sidebar';
import Table from '../modules/table';
import Layout from '../context/layoutPortal.jsx';

import FooterLanding from '../modules/footerLanding.jsx';

import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAxiosPrivateBlob from '../hooks/useAxiosPrivateBlob';

import { Tooltip } from 'react-tooltip'

import ActivityBoard from '../modules/activityBoard.jsx';

import { HiOutlineRefresh } from 'react-icons/hi';

import Loading from '../modules/loading.jsx';

import Plot from '../modules/plot.jsx';

import { FiInfo } from "react-icons/fi";
import { TbAlertSquare } from "react-icons/tb";



function arrayAlphebetize(array) {
  return array.sort((a, b) => a.localeCompare(b));
}

function removeDuplicatesFromArray(array) {
 
  return array.filter((a, b) => array.indexOf(a) === b);
}

export default function Dashboard() {

  const auth = useAuth();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  
  const monthList = [["01","January"], ["02","February"], ["03","March"], ["04","April"], ["05","May"], ["06","June"], ["07","July"], ["08","August"], ["09","September"], ["10","October"], ["11","November"], ["12","December"]];

  const [data, setData] = useState([]);    
  const [fetchLimit, setFetchLimit] = useState(100);
  const [isLoading, setIsLoading] = useState(false);
  const [filterLimit, setFilterLimit] = useState(20);

  const [filterType, setFilterType] = useState("all");

  const [totalActivityAll, setTotalActivityAll] = useState(0);
  const [totalActivityOrder, setTotalActivityOrder] = useState(0);
  const [totalActivityJob, setTotalActivityJob] = useState(0);

  const [plotSelectOpen, setPlotSelectOpen] = useState(false);

  
  useEffect(() => {
    //console.log(auth);
    //fetchActivityData();
        
    let data = fetchActivityData();


  }, []);

  const countTotals = (data) => {
    let total_activity_all = 0;
    let total_activity_order = 0;
    let total_activity_job = 0;

    for (let i = 0; i < data.length; i++) {
       if (data[i].type=="order") {
         total_activity_order = total_activity_order + 1;
       }
       if (data[i].type=="job") {
         total_activity_job = total_activity_job + 1;
       }
     }

    total_activity_all = total_activity_job + total_activity_order

    setTotalActivityAll(total_activity_all);
    setTotalActivityOrder(total_activity_order);
    setTotalActivityJob(total_activity_job);
  }

  const fetchActivityData = async () => {

      // *** for testing purposes, with the admin account, fetch all activity data,
      // can turn this off in production though?

      setIsLoading(true);

      try {

        const params = {
            client_name: auth.auth.name,
            filter_limit: filterLimit,             
            //date_filter: "2025",
        }

        const response = await axiosPrivate.get("/activity", {params: params}, { method: "GET", credentials: "include" });

        countTotals(response.data);
        setData(response.data);

        setIsLoading(false);

      } catch (error) {
        console.error("Error fetching activity data:", error);
        setIsLoading(false);
      }
  }

  const timeSeriesData = [
    { date: new Date('2023-01-01'), value: 13 },
    { date: new Date('2023-01-02'), value: 15 },
    { date: new Date('2023-01-03'), value: 18 },
    { date: new Date('2023-01-04'), value: 8 },
    { date: new Date('2023-01-05'), value: 14 },
    { date: new Date('2023-01-06'), value: 16 },
    { date: new Date('2023-01-07'), value: 14 },
    { date: new Date('2023-01-08'), value: 13 },
    { date: new Date('2023-01-09'), value: 19 },
    { date: new Date('2023-01-10'), value: 29 },
    { date: new Date('2023-01-11'), value: 4 },
    { date: new Date('2023-01-12'), value: 12 },
    { date: new Date('2023-01-13'), value: 13 },
    { date: new Date('2023-01-14'), value: 27 },
    { date: new Date('2023-01-15'), value: 22 },
    { date: new Date('2023-01-16'), value: 9 },
    { date: new Date('2023-01-17'), value: 16 },
    { date: new Date('2023-01-18'), value: 19 },
    { date: new Date('2023-01-19'), value: 21 },
    { date: new Date('2023-01-20'), value: 5 },
    { date: new Date('2023-01-21'), value: 14 },
    { date: new Date('2023-01-22'), value: 11 },
    { date: new Date('2023-01-23'), value: 3 },
    { date: new Date('2023-01-24'), value: 15 },
    { date: new Date('2023-01-25'), value: 23 },
    { date: new Date('2023-01-26'), value: 9 },
    { date: new Date('2023-01-27'), value: 7 },
    { date: new Date('2023-01-28'), value: 24 },
    { date: new Date('2023-01-29'), value: 3 },
    { date: new Date('2023-01-30'), value: 14 },
    { date: new Date('2023-01-31'), value: 13 },

  ];


  // id: '1', 
  // type: 'order', 
  // client: 'desgagnes', 
  // date: '2025-01-01', 
  // time: '12:00:00', 
  // reference: '1695705675926'


  // ORDERS
  // A table that displays orders that have been received by our facility. Both delivered and stocked orders can be viewed here.

  // OUTGOING
  // A list of your orders that have been picked up by us and are scheduled for delivery to any location you want.

  // INVENTORY
  // Items that are being warehoused by us for you. These goods can be delivered at any time upon request.

  // JOBS 
  // A list of past and currently scheduled jobs. Bills and delivery proofs can be viewed from the job cards.

  return (        

    <React.Fragment>
      <Layout>

        <div className="flex h-screen">
              {/* Left column (w-1/2) */}
              <div className="w-1/2 flex flex-col  pt-4">


                <div class="flex flex-col">
                      <h1 class="text-xl font-semibold leading-8 tracking-tight text-indigo-600">LOCKS AND DOCKS</h1>
                      <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                          A platform for all your logistic needs
                      </p>
                      <p class="mt-6 text-lg leading-8 text-gray-600">                    
                          We are constantly in the process of improving our services and adding new features to our web platform. 
                          You can expect new developments to be released on a regular basis.
                      </p>
                  </div>

                  <div class="flex flex-col space-y-5 mt-10">

                    <div
                      class="flex w-100 bg-blue-100 p-4 rounded-lg mb-auto border-blue-500 border-2"
                    >
                      <span class="p-1 flex">
                        <FiInfo 
                          size={25}
                          class="stroke-blue-600"
                        />
                        
                      </span>

                      <p class="text-blue-600 ml-4">
                      <p class="font-bold stroke-blue-600 mt-1 text-lg">UPDATE</p> 
                        We have updated the platform to make things simpler and more efficient. The functionality
                        has remained the same, but we've improved the user interface and added new some new features. 
                        You can now view all of your orders in the same place. 
                        
                      </p>

                    </div>

                    <div
                      class="flex w-100 bg-green-100 p-4 rounded-lg mb-auto border-green-500 border-2"
                    >
                      <span class="p-1">
                        <TbAlertSquare 
                          size={25}
                          class="stroke-green-600"
                        />
                      </span>
                      <p class="text-green-600 ml-4">
                        <p class="font-bold stroke-green-600 mt-1 text-lg">NOTICE</p> 
                         We currently have deicing salt and premium-quality rags for sale in large quantities.
                         Please contact us to add these items to your order.
                      </p>

                    </div>



                      {/* <div class="flex">
                          <div class="mr-auto">
                              <p class="mt-2 text-xl mb-0 font-bold tracking-tight text-gray-900 sm:text-4xl">
                                  Orders Received
                              </p>
                              <p class="text-lg leading-8 text-gray-600 mt-1">                    
                                  The total number of orders received by our facility.
                              </p>
                          </div>

                          <div class="relative inline-block my-auto">
                              <div>
                                  <button type="button" 
                                      onClick={() => setPlotSelectOpen(!plotSelectOpen)}
                                      class="inline-flex w-full max-w-[16rem] justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-900 ring-1 shadow-xs ring-gray-300 ring-inset hover:bg-gray-50" id="menu-button" aria-expanded="true" aria-haspopup="true"
                                  >
                                      <p class="mb-0 p-1">Data</p>
                                      <p class="font-bold mb-0 mt-1 whitespace-nowrap overflow-hidden text-ellipsis">                                    
                                          Plot select
                                      </p>
                                      <svg class="-mr-1 my-auto size-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                                          <path fill-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
                                      </svg>
                                  </button>                        
                              </div>
                              
                                  <div 
                                    className={`
                                      absolute left-0 z-10 mt-2 w-52 origin-top-right
                                      rounded-md bg-white ring-1 ring-black/5 shadow-lg focus:outline-hidden 
                                      transform transition-all duration-200
                                      ${plotSelectOpen ? 'scale-y-100 opacity-100' : 'scale-y-0 opacity-0 pointer-events-none'}
                                    `}
                                    role="menu"
                                    aria-orientation="vertical"
                                    aria-labelledby="menu-button"
                                    tabIndex="-1"
                                  >
                                      <div class="py-1 max-h-[20rem]" role="none">                                
                                          <div class="flex flex-row">
                                              <button class="ibm text-md align-middle ml-2 my-auto flex">Orders Received</button>                                            
                                          </div>                                        
                                          <div class="flex flex-row">
                                              <button class="ibm text-md align-middle ml-2 my-auto flex">Orders Warehoused</button>                                            
                                          </div>                                        
                                          <div class="flex flex-row">
                                              <button class="ibm text-md align-middle ml-2 my-auto flex">Jobs Completed</button>                                            
                                          </div>                                        
                                          
                                      </div>
                                  </div>
                              </div>

                      </div> */}

                      {/* <div class=" -ml-10 ">                                    
                          <Plot 
                            data={timeSeriesData}
                          />
                      </div> */}

                  </div>
              </div>

              {/* Right column (w-1/2) */}
              <div className="w-1/2 flex flex-col p-4">
                {/* Some top content that occupies minimal height */}
                <div className="flex items-center space-x-2 pb-4">
                  <div class="border border-black space-x-2 rounded-lg p-0.5 ">
                    <button 
                      onClick={() => setFilterType("all")}
                      className={filterType=="all"?
                        "h-10 border-slate-500 border rounded-lg bg-slate-100 text-gray-600 font-bold p-2 px-3"
                        : 
                        "h-10 rounded-lg bg-white border border-white text-gray-600 font-bold hover:bg-gray-100 p-2 px-3"}
                      >
                      View all <span className="rounded bg-slate-200 ml-2 p-1">{totalActivityAll}</span>
                    </button>
                    <button 
                      onClick={() => setFilterType("order")}
                      className={filterType=="order"?
                        "h-10 border-slate-500 border rounded-lg bg-slate-100 text-gray-600 font-bold p-2 px-3"
                        : 
                        "h-10 rounded-lg bg-white border border-white text-gray-600 font-bold hover:bg-gray-100 p-2 px-3"}
                      >
                      Orders <span className="rounded bg-slate-200 ml-2 p-1">{totalActivityOrder}</span>
                    </button>
                    <button 
                      onClick={() => setFilterType("job")}
                      className={filterType=="job"?
                        "h-10 border-slate-500 border rounded-lg bg-slate-100 text-gray-600 font-bold p-2 px-3"
                        : 
                        "h-10 rounded-lg bg-white border border-white text-gray-600 font-bold hover:bg-gray-100 p-2 px-3"}
                      >
                      Jobs <span className="rounded bg-slate-200 ml-2 p-1">{totalActivityJob}</span>
                    </button>
                  </div>
                </div>

                {/* 2. Make this div stretch to fill leftover space, and 3. scroll */}
                {isLoading?
                  <div className="flex w-100 overflow-auto bg-white">
                      <p id="loading" class="ibm text-lg text-center mt-10 !text-gray-500 font-semibold" >Loading Activity</p>                    
                  </div>
                :
                  data.length==0?                
                    <div className="flex bg-white w-100">
                      <p class="ibm mt-6 font-semibold text-gray-500 mx-auto">No activity yet. </p>                       
                    </div>
                  : filterType=="job" && totalActivityJob==0?
                    <div className="flex bg-white w-100">
                      <p class="ibm mt-6 font-semibold text-gray-500 mx-auto">No job activity yet. </p>
                    </div>
                  : filterType=="order" && totalActivityOrder==0?
                    <div className="flex bg-white w-100">
                      <p class="ibm mt-6 font-semibold text-gray-500 mx-auto">No order activity yet. </p>
                    </div>
                  :
                    <div id="activity-board" className="flex-1 overflow-auto bg-white">
                      <ActivityBoard 
                        data={data} 
                        limit={20}
                        filterType={filterType}
                      />                  
                    </div>
                }
              </div>
            </div>

      </Layout>    
      
      <FooterLanding />
    </React.Fragment>
    
  );
}

















import React, { useRef, useEffect, useState } from 'react';

import Popup from '../context/layoutPopup.jsx';
import Loading from './loading.jsx';

import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAuth from '../hooks/useAuth';

import { BsX } from 'react-icons/bs';


function arrayAlphebetize(array) {
    //console.log(array);
    return array.sort((a, b) => a.localeCompare(b));
}
  
function removeDuplicatesFromArray(array) {  
    return array.filter((a, b) => array.indexOf(a) === b);
} 



export default function MenuAssignInventory({ data, divisionMap, onToggleDisplay, uploadFile, updateParent, notify}) {

    const axiosPrivate = useAxiosPrivate();
    const auth = useAuth();

    const list_of_clients = arrayAlphebetize(removeDuplicatesFromArray(Object.keys(divisionMap)));

    const [isLoading, setIsLoading] = useState(false);
    const [newEditData, setNewEditData] = useState({...JSON.parse(JSON.stringify(data)), client:data.owner });
    const [isSelected, setIsSelected] = useState(false);
    const [displayConfirmationPopup, setDisplayConfirmationPopup] = useState(false);
    const [customDestination, setCustomDestination] = useState(true);
    const [originalQuantity, setOriginalQuantity] = useState(parseInt(data.quantity));
    const [originalWeight, setOriginalWeight] = useState(parseInt(data.weight));
    
    useEffect(() => {   
        try {
            if (data.owner !== "LOCKS AND DOCKS") { 
                setNewEditData({...newEditData, destination:divisionMap[data.owner].split(';')[0] })
            }
        } catch (err) {
            console.log(err);           
            //setNewEditData({...newEditData, destination:divisionMap[]})
        }
    }, []);    

    const preFlightChecks = () => {
        if (newEditData.owner == "") {
            notify("Please select an owner", "error");
            return false;
        }
        if (newEditData.owner == "LOCKS AND DOCKS") {
            notify("Can't assign order to LOCKS AND DOCKS", "error");
            return false;
        }
        if (newEditData.weight == "") {
            notify("Please enter a weight", "error");
            return false;
        }
        if (newEditData.quantity == "") {
            notify("Please enter a quantity", "error");
            return false;
        }
        //if newEditData.owner not in list_of_clients then notify
        if (!list_of_clients.includes(newEditData.owner)) {
            notify("Please select a valid owner.", "error");
            return false;
        }


        return true;
    }

    const onSubmitAssignOrder = async () => {

        const checked = preFlightChecks();
        if (!checked) return;

        try {
            console.log(newEditData)
            setIsLoading(true);
            const body = JSON.stringify({...newEditData, originalWeight: originalWeight, originalQuantity: originalQuantity});
            const response = await axiosPrivate.post("/inventory/assign", body, { method: "POST", credentials: "include" });                       
            //console.log(response);
            await updateParent();
            onToggleDisplay(false)
            setIsLoading(false);        
            notify(`Order created for ${newEditData.owner}`, "success");
        }
        catch (err) {
            console.log(err);
            setIsLoading(false);
            notify(`Error creating order for ${newEditData.owner}`, "error");
        }
             
    }

    const onChange = (e) => {    
        //console.log(newEditData)
        if (e.target.value.includes('"') || e.target.value.includes('`')) {}
        else {
            if (e.target.name === "quantity") {
                if (e.target.value < 0 || e.target.value > data.quantity) {
                    return;
                }
            }            
            if (e.target.name === "weight") {
                //console.log(e.target.value, '-----', data.weight);
                if (Number(e.target.value) < 0 || Number(e.target.value) > Number(data.weight)) {
                    return;
                }
            }
            setNewEditData({...newEditData, [e.target.name]: e.target.value});
        }
    }

        
    return (

        <Popup>

            <div class="flex absolute top-5 right-5">
                <button onClick={() => onToggleDisplay(false)} class="h-10 w-10 bg-gray-200 hover:bg-gray-300 rounded-full flex m-2">
                    <BsX class="m-auto" size={25}/>
                </button>
            </div>  

            {displayConfirmationPopup? // create a centered popup to confirm deletion
                <div id="popupMenu" class="absolute z-20 mx-auto my-auto inset-0 overflow-hidden h-1/4 p-2 w-1/2  bg-gray-500 text-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle" role="dialog" aria-modal="true" aria-labelledby="modal-title">
                    <div class="bg-gray-500 text-white sm:p-6 h-full w-100 flex">

                            <div class="flex">
                                <div class="ml-6">
                                    <h3 class="">Delete Inventory Item #{data.id}</h3>
                                    <p class="text-white">Are you sure you want to delete this inventory item?</p>
                                </div>
                            </div>
                            <div class="flex ml-auto mt-auto">
                                <button onClick={isLoading?null:() => setDisplayConfirmationPopup(false)} class="bg-gray-200 hover:bg-gray-300 text-black font-bold py-2 px-4 rounded mr-2">Cancel</button>                                      
                                <div class="">
                                    {!isLoading?
                                    <div onClick={null} class="w-28 h-10 flex bg-red-400 hover:bg-red-500 text-white font-bold mr-6 rounded mx-auto" type="button">
                                        <p class="flex m-auto">Confirm</p>
                                    </div> 
                                    :   
                                    <div class="w-28 h-10 flex bg-red-500 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                                        <span class="flex m-auto"><Loading type={1} /></span>
                                    </div> 
                                    }
                                </div> 

                            </div>

                    </div>
                </div>                                                    
            :null}

            <div id="innerEditMenu" class="h-3/4 pt-3 pb-0 w-3/4 inline-block bg-gray-200 rounded-lg text-left overflow-scroll shadow-xl transform transition-all sm:my-8 sm:align-middle" role="dialog" aria-modal="true" aria-labelledby="modal-title">            

                <div class="flex pb-4">
                    <div class="block ml-6">
                        <h5 class="">Assign Inventory Item</h5>
                        <p>Mark some/all of this item for delivery. Creates an entry in the orders table for destination.</p>
                    </div>
                    {/* <button onClick={() => onToggleDisplay(false)} class="flex ml-auto hover:bg-gray-200"><FiX/></button> */}
                </div>  

                <div class="flex flex-wrap -mx-3 mb-2 px-6">

                    <div class="w-full md:w-1/2 px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                        Owner *
                    </label>
                    <select name="client" onChange={(e) => setNewEditData({...newEditData, client: e.target.value, owner: e.target.value, destination: divisionMap[e.target.value].split(";")[0]})} class="shadow block w-full bg-white text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="owner" type="text">
                        <option value="LOCKS AND DOCKS">LOCKS AND DOCKS</option>
                        <option disabled>---------------</option>
                        {auth.auth?
                        list_of_clients.map(d => {
                            if (d == data.owner) {
                                return <option selected value={d}>{d}</option>
                            }
                            return <option value={d}>{d}</option> 
                        })
                    :
                    <option value="default">default</option>
                    }
                    </select></div>

                    <div class="w-full md:w-1/2 px-3">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Destination *
                            {customDestination
                            ?<button onClick={() => setCustomDestination(false)} class="text-blue-500 ml-4 hover:text-blue-600 cursor-pointer hover:underline">CUSTOM</button>
                            :<button onClick={() => setCustomDestination(true)} class="text-blue-500 ml-4 hover:text-blue-600 cursor-pointer hover:underline">PREDEFINED</button>                                                    
                            
                            }
                        </label>
                        {customDestination
                        ?
                        <select name="destination" onChange={(e) => setNewEditData({...newEditData, destination: e.target.value})} class="shadow block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="destination" type="text" >
                            {divisionMap[newEditData.client]?
                            divisionMap[newEditData.client].split(";").map(d => {
                                return <option value={d}>{d}</option>
                            })
                            :   
                            <option value="default">default</option>
                            }
                        </select>
                        :
                        <input maxLength={125} autoComplete='off' name="destination" onChange={(e) => onChange(e)} placeholder="Input custom destination..." class="shadow block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text"></input>
                        }
                    </div>

                </div>                  


                <div class="flex flex-wrap -mx-3 mb-2 px-6">
                    <div class="w-full md:w-1/2 px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                        Reference
                    </label>
                    <input maxLength={125} value={newEditData.reference} name="reference" onChange={(e) => onChange(e)} autoComplete="off" class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reference" type="text" placeholder="" />
                    </div>

                    <div class="w-full md:w-1/2 px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                        Supplier
                    </label>
                    <input maxLength={125} value={newEditData.supplier} name="supplier" onChange={(e) => onChange(e)} autoComplete="off" class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="supplier" type="text" placeholder="" />
                    </div>
                </div>

                <div class="flex flex-wrap -mx-3 mb-2 px-6">
                    <div class="w-full md:w-1/3 px-3">
                    <label for="quantity"  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Quantity
                    </label>
                    <input maxLength={9} min={1} max={data.quantity} step={1} value={newEditData.quantity} name="quantity" onChange={(e) => onChange(e)} autoComplete="off" class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="quantity" type="number" />
                    </div>

                    <div class="w-full md:w-1/3 px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="unit">
                        Unit
                    </label>
                    <input maxLength={125} value={newEditData.unit} name="unit" onChange={(e) => onChange(e)} autoComplete="off" class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="unit" type="text" />
                    </div>

                    <div class="w-full md:w-1/3 px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="weight">
                        Weight    ( <button value={data.weightType} name="weightType" onChange={(e) => setNewEditData({...newEditData, reference: "LBS"})} class="" style={newEditData.weightType=="LBS"?{color:"blue"}:{color:"black"}}>LBS</button> / <button value={newEditData.weightType} name="weightType" onChange={(e) => setNewEditData({...data, weightType: "KGS"})} class="" style={data.weightType=="KGS"?{color:"blue"}:{color:"black"}}>KGS</button> )
                    </label>
                    <input maxLength={9} step={0.1} min={0.1} value={newEditData.weight} name="weight" onChange={(e) => onChange(e)} autoComplete="off" class="shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="number" id="weight" />
                    </div>                    
                </div>

            

                <div class="w-full h-32 mr-3 px-7">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="notes">
                        Notes
                    </label>
                    <textarea maxLength={250} value={newEditData.note} name="note" onChange={(e) => onChange(e)} autoComplete="off" placeholder="Additional notes..." class="h-full shadow appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="notes" type="text" />
                </div> 

                <div class="flex flex-wrap -mx-3 mb-2 px-6 mt-5">

                    <div class="w-full md:w-1/3 px-3">
                        <label for="status" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                            Status
                        </label>
                        <select name="status" onChange={(e) => setNewEditData({...data, status: e.target.value})} class="shadow block w-full bg-white text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="status" >
                            <option selected={data.status=="Inventory"?true:false} value="Inventory">Inventory</option>
                            <option selected={data.status=="Delivered"?true:false} value="Delivered">Delivered</option>
                            <option selected={data.status=="Damaged"?true:false} value="Damaged">Damaged</option>
                            <option selected={data.status=="Lost"?true:false} value="Lost">Lost</option>
                            <option selected={data.status=="Delayeds"?true:false} value="Delayed">Delayed</option>
                        </select>
                    </div>

                    <div class="flex w-full md:w-1/3 px-3 mt-4 ml-auto">                            
                        {!isLoading?
                        <div onClick={onSubmitAssignOrder} class="w-36 h-10 flex bg-blue-500 hover:bg-blue-700 text-white font-bold mr-6 rounded mx-auto" type="button">
                            <p class="flex m-auto">Confirm</p>
                        </div> 
                        :   
                        <div class="w-36 h-10 flex bg-blue-700 text-white font-bold mr-6 rounded mx-auto pointer-events-none" type="button">
                            <span class="flex m-auto"><Loading type={1} /></span>
                        </div> 
                        }
                    </div> 

                
                </div>  
            </div>             

        </Popup>


    );
    
}
import React, { useRef, useEffect, useState } from 'react';
import { Navigate, useNavigate, Link } from 'react-router-dom';

import moment from 'moment';

import useAuth from '../hooks/useAuth';

import Table from '../modules/table';
import Searcher from '../modules/searcher.jsx'
import MenuEditOutgoing from '../modules/menuEditOutgoing.jsx';
import MenuDownloadData from '../modules/menuDownloadData';
import Loading from '../modules/loading';
import Layout from '../context/layoutPortal.jsx';
import Popup from '../context/layoutPopup.jsx';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAxiosPrivateBlob from '../hooks/useAxiosPrivateBlob';

import { HiOutlineRefresh } from 'react-icons/hi';
import Tooltip from '@mui/material/Tooltip';
import { BsX } from 'react-icons/bs';

import { Checkbox } from '@mui/material';

import { BsDownload } from "react-icons/bs";
import { BsLayoutThreeColumns } from "react-icons/bs";


function toTimestamp(strDate) {
    var datum = Date.parse(strDate);
    return datum;// /1000;
}

function arrayAlphebetize(array) {
    return array.sort((a, b) => a.localeCompare(b));
}

function removeDuplicatesFromArray(array) {
    return array.filter((a, b) => array.indexOf(a) === b);
}


export default function Outgoing({ notify }) {

    const auth = useAuth();    
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const axiosPrivateBlob = useAxiosPrivateBlob();

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [editData, setEditData] = useState({});
    
    const [clientList, setClientList] = useState(arrayAlphebetize(Object.keys(auth.auth.divisionMap)));
    const [destinationList, setDestinationList] = useState(arrayAlphebetize(removeDuplicatesFromArray(arrayAlphebetize(Object.values(auth.auth.divisionMap).flatMap((division) => division.split(';')).slice(1)))));
    const [showEditMenu, setShowEditMenu] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isSelected, setIsSelected] = useState(false);
    const [showTableMenu, setShowTableMenu] = useState(false);
    const [sort, setSort] = useState("up");

    const [searchGroup, setSearchGroup] = useState("");
    const [searchValue, setSearchValue] = useState("");

    const [filterClient, setFilterClient] = useState("All");
    const [filterDestination, setFilterDestination] = useState("All");

    const [PDFLink, setPDFLink] = useState("");
    const [PDFBlob, setPDFBlob] = useState(null);
    const [showPdfViewer, setShowPdfViewer] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);

    const [popupMessage, setPopupMessage] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [showDownloadDataMenu, setShowDownloadDataMenu] = useState(false);

    const [totalWeight, setTotalWeight] = useState(0.0);

    const [searcherPreset, setSearcherPreset] = useState("");

    const [statusFilterOpen, setStatusFilterOpen] = useState(false);
    const [destinationFilterOpen, setDestinationFilterOpen] = useState(false);
    const [columnFilterOpen, setColumnFilterOpen] = useState(false);
    const [clientFilterOpen, setClientFilterOpen] = useState(false);

    const [datasets, setDatasets] = useState({
        from:[],
        to:[], 
        carriers:[]
    });

    const searchPreferences = localStorage.getItem("searchPreferences") ? JSON.parse(localStorage.getItem("searchPreferences")) 
    : {
        inventory: true,
        pending: true,
        delivered: false,
        damaged: false,
        delayed: false,
        lost: false,
    };

    const [filterStatus, setFilterStatus] = useState(searchPreferences);

    const [columns, setColumns] = useState([        
        { 
            id: 'edit', 
            label: 'EDIT', 
            minWidth: 50,
            align: 'left',
            hidden: false,
            format: (value) => value.toLocaleString('en-US'),
        },
        { 
            id: 'doc', 
            label: 'DOC', 
            minWidth: 50,
            align: 'left',
            maxWidth: 50,
            hidden: false,
            format: (value) => value.toLocaleString('en-US'),
        },     
        {
            id: 'status',
            label: 'STATUS',
            minWidth: 120,
            align: 'center',
            hidden: false,
            format: (value) => value.toLocaleString('en-US'),
        },   
        {
            id: 'datetime',
            label: 'DATE/TIME',
            minWidth: 220,
            align: 'left',
            hidden: false,
            render: value => moment(new Date(value)).format('YYYY-MM-DD HH:mm:ss')
            //format: (value) => value.toLocaleString('en-US'),
        },        
        {
            id: 'client',
            label: 'CLIENT',
            minWidth: 170,
            align: 'left',
            hidden: false,
            format: (value) => value.toFixed(2),
        },
        {
            id: 'from',
            label: 'FROM',
            minWidth: 170,
            align: 'left',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'to',
            label: 'TO',
            minWidth: 170,
            align: 'left',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'reference',
            label: 'REFERENCE',
            minWidth: 170,
            align: 'left',
            hidden: false,
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'unit',
            label: 'UNIT',
            minWidth: 170,
            align: 'left',
            hidden: false,
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'quantity',
            label: 'QUANTITY',
            minWidth: 170,
            align: 'left',
            hidden: false,
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'weight',
            label: 'WEIGHT',
            minWidth: 170,
            align: 'left',
            hidden: false,
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'weightType',
            label: 'LBS/KGS',
            minWidth: 170,
            align: 'left',
            hidden: false,
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'id',
            //label: 'Size\u00a0(km\u00b2)',
            label: 'ID',
            minWidth: 170,
            align: 'left',
            hidden: false,
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'checked',
            label: 'LAST UPDATE',
            minWidth: 170,
            align: 'left',
            hidden: false,
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'description',
            label: 'DESCRIPTION',
            minWidth: 250,
            align: 'left',
            hidden: false,
            format: (value) => value.toLocaleString('en-US'),
        }, 
        {
            id: 'note',
            label: 'NOTE',
            minWidth: 170,
            align: 'left',
            hidden: false,
            format: (value) => value.toLocaleString('en-US'),
        },        
    ]);    
    

    useEffect(() => {       
        //console.log(auth);
        //console.log(data);
        fetchOutgoingData();

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
        
    }, []);        
    
    const fetchOutgoingData = async () => {      
        setIsLoading(true); 
        setData([]);

        // if there is a URL param for id, fetch that order
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        
        let response;

        if (id) { 

            response = await axiosPrivate.get("/outgoing/id/"+id, { method: "GET", credentials: "include" });

            setSearcherPreset(`id:${id}`);
        }
        else {
        
            const params = {
                user: auth.auth.name,
                filter_client: filterClient=="All"?"%":filterClient,
                filter_destination: filterDestination=="All"?"%":filterDestination,
                filter_status: filterStatus,
                search_group: searchGroup,
                search_value: searchValue,
            }

            response = await axiosPrivate.get("/outgoing", {params:params}, { method: "GET", credentials: "include" });                       
        }

        const fromList = [];
        const toList = [];
        const unitsList = [];
        const data = [];

        let total_weight = 0.0;

        //console.log(response.data)

        for (let i=0; i<response.data.length; ++i) {
            if(response.data[i].from) fromList.push(response.data[i].from);
            if(response.data[i].to) toList.push(response.data[i].to);
            if(response.data[i].unit) unitsList.push(response.data[i].unit);

            if(response.data[i].weight) {
                if(response.data[i].weightType=="KGS"){                    
                    total_weight = total_weight + Number(response.data[i].weight)/2.20462
                } else {
                    total_weight = total_weight + Number(response.data[i].weight)*1
                }
            }

            data.push({
                ...response.data[i], 
                checked: moment(response.data[i].checked).format('YYYY-MM-DD HH:mm:ss'), 
                datetime: moment(response.data[i].datetime).format('YYYY-MM-DD HH:mm:ss')
            });            
        }; 
        
        //console.log(response);
        setTotalWeight(total_weight.toFixed(2));
        setDatasets({            
            from: [... new Set(fromList)],
            to: [... new Set(toList)],
            unit: [... new Set(unitsList)],            
        })
        setData(data);
        setIsLoading(false);
    }

    const onSubmitSearch = async (group,value,filters) => {

        // check for URL param id
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');

        if(!value) {
            if (id) {
                navigate(`/outgoing`);
            }
            fetchOutgoingData();
        }
        
        else {

            if (value.startsWith("id:")) {

                // update the URL param id
                navigate(`/outgoing?id=${value.split(":")[1]}`);

                const id = value.split(":")[1];
                const response = await axiosPrivate.get("/outgoing/id/"+id, { method: "GET", credentials: "include" });

                const destinationsList = [];
                const suppliersList = [];
                const unitsList = [];
                const carriersList = [];
                const data = [];

                let total_weight = 0.0;

                for (let i=0; i<response.data.length; ++i) {
    
                    if(response.data[i].weight) {
                        if(response.data[i].weightType=="KGS"){                    
                            total_weight = total_weight + Number(response.data[i].weight)/2.20462
                        } else {
                            total_weight = total_weight + Number(response.data[i].weight)*1
                        }
                    }
        
                    data.push({
                        ...response.data[i], 
                        checked: moment(response.data[i].checked).format('YYYY-MM-DD HH:mm:ss'), 
                        datetime: moment(response.data[i].datetime).format('YYYY-MM-DD HH:mm:ss')
                    });            
                }; 

                setTotalWeight(total_weight.toFixed(2));

                setData(data);
                setSearcherPreset(`id:${id}`);
                return;
            }

            // the value doesn't start with id, so its not an ID search, therefore 
            // the URL should just be /outgoing
            navigate("/outgoing");

            setIsLoading(true);
            setData([]);

            const params = {
                user: auth.auth.name,
                filter_client: filterClient=="All"?"%":filterClient,
                filter_destination: filterDestination=="All"?"%":filterDestination,
                filter_status: filterStatus,
                search_group: group,
                search_value: value,
            }
            //if (group == "reference" || group == "waybill" || group == "id" || group == "units") {
            const data = [];
            const response = await axiosPrivate.get("/outgoing/search", {params:params}, { method: "GET", credentials: "include" });                       

            let total_weight = 0.0;

            for (let i=0; i<response.data.length; ++i) {
    
                if(response.data[i].weight) {
                    if(response.data[i].weightType=="KGS"){                    
                        total_weight = total_weight + Number(response.data[i].weight)/2.20462
                    } else {
                        total_weight = total_weight + Number(response.data[i].weight)*1
                    }
                }
    
                data.push({
                    ...response.data[i], 
                    checked: moment(response.data[i].checked).format('YYYY-MM-DD HH:mm:ss'), 
                    datetime: moment(response.data[i].datetime).format('YYYY-MM-DD HH:mm:ss')
                });            
            }; 

            setTotalWeight(total_weight.toFixed(2));
            //console.log(new_data);
            setData(data);
            setIsLoading(false);
        }
    } 

    const uploadFile = (event) => {
        //console.log(event.target.files[0]);
        selectedFile(event.target.files[0]);
        setIsSelected(true);
    };

    const editTableRow = (editData, code) => {
        setEditData(editData);
        setShowEditMenu(true);
    }

    const onSort = (colName) => {

        // ascending/descending: id/reference, quantity, weight, datetime, checked
        
        // alphabetical: destination, supplier, unit type, carrier, client
  
        // let colName = col.target.innerText.toLowerCase();
    
        let data_list = JSON.parse(JSON.stringify(data));
  
        //console.log(data_list);       
  
        
        if (colName=="waybill"||colName=="note"||colName=="doc"||colName=="edit"||colName=="description") {
            sorted_data = data_list
        }
  
        // NUMERICAL
        if (colName=="quantity"||colName=="id") {
  
            if (sort=="up") {
                var sorted_data = data_list.sort((a, b) => Number(b[colName])-Number(a[colName]));
            }
            if (sort=="down") {
                var sorted_data = data_list.sort((a, b) => Number(a[colName])-Number(b[colName]));
            }          
        }
  
        // WEIGHT
        if (colName=="weight") {
            if (sort=="up") {
                var sorted_data = data_list.sort((a, b) => Number(b[colName].split(" ")[0])-Number(a[colName].split(" ")[0]));
            }
            if (sort=="down") {
                var sorted_data = data_list.sort((a, b) => Number(a[colName].split(" ")[0])-Number(b[colName].split(" ")[0]));
            }  
        }
  
        // REFERENCE
        if (colName=="reference") {
  
            if (sort=="up") {
                var sorted_data = data_list.sort((a, b) => Number(b[colName].replace("-",""))-Number(a[colName].replace("-","")));
            }
            if (sort=="down") {
                var sorted_data = data_list.sort((a, b) => Number(a[colName].replace("-",""))-Number(b[colName].replace("-","")));
            }  
        }
  
        // ALPHABETICAL
        if (colName=="destination"||colName=="supplier"||colName=="unit"||colName=="carrier"||colName=="status"||colName=="client"||colName=="weightType"||colName=="from"||colName=="to") {
  
            if (sort=="up") {
                var sorted_data = data_list.sort(function SortArrayObjects(x, y){
                    if (x[colName] < y[colName]) {return -1;}
                    if (x[colName] > y[colName]) {return 1;}
                    return 0;
                })
            }
            if (sort=="down") {
                var sorted_data = data_list.sort(function SortArrayObjects(x, y){
                    if (x[colName] > y[colName]) {return -1;}
                    if (x[colName] < y[colName]) {return 1;}
                    return 0;
                })
            }
        }
  
        // CHRONOLOGICAL
        if (colName=="datetime"||colName=="checked") {
  
            if (sort=="up") {
                var sorted_data = data_list.sort((a, b) => toTimestamp(b["datetime"])-toTimestamp(a["datetime"]));
            }
            if (sort=="down") {
                var sorted_data = data_list.sort((a, b) => toTimestamp(a["datetime"])-toTimestamp(b["datetime"]));
            }      
        }

        setData(sorted_data);
        setSort(sort=="up"?"down":"up");   
    }

    const savePreferences = async (preferences) => {
        setIsLoading(true)
        //console.log(preferences);
        // save preferences to localStorage
        localStorage.setItem("searchPreferences", JSON.stringify(filterStatus));
        setIsLoading(false)
        notify("Preferences saved", "success");
    }

    const fetchPackingslip = async (data) => {
        //console.log(data);
        setIsLoading(true);  
        
        setShowPopup(true)
        setPopupMessage(["Please wait","Retrieving packing slip"])

        let id = data.id;
        await axiosPrivateBlob.get("/packingSlip", {params:{id:id}})
        .then(response => {
            const file = new Blob([response.data], { type: "application/pdf" });
            const fileURL = window.URL.createObjectURL(file);
            window.open(fileURL);
            setPDFBlob(file);
            setPDFLink(fileURL);
            setShowPdfViewer(true);
            setIsLoading(false);
            setShowPopup(false)
        })
        .catch(error => {
            console.log(error);
            setIsLoading(false);
            setShowPopup(false)
        });        
    }

    const displayDownloadDataMenu = () => {
        setShowDownloadDataMenu(!showDownloadDataMenu);
    }

    const changeStatusFilter = (status_obj) => {

        // change the searchPreferences status filter
        setFilterStatus(status_obj);

        // update the searchPreferences in localStorage
        localStorage.setItem("searchPreferences", JSON.stringify(status_obj));

    }

    useEffect(() => {
        fetchOutgoingData(); 
    }, [filterClient, filterDestination, filterStatus]);

    const onColumnFilter = (column) => {

        // { 
        //     id: 'edit', 
        //     label: 'EDIT', 
        //     minWidth: 50,
        //     align: 'left',
        //     format: (value) => value.toLocaleString('en-US'),
        // },

        console.log("COLUMN ---")
        console.log(column)
        
        // update the columns state, hide or show the column
        let column_array = JSON.parse(JSON.stringify(columns));
        for (let i=0; i<column_array.length; ++i) {
            if (column_array[i].id==column.id) {
                column_array[i].hidden = !column_array[i].hidden;
            }
        }

        setColumns(column_array);
    }

    const handleClickOutside = (event) => {
        // Check if the click is outside the dropdown
        console.log(event.target.value)
        console.log(event.target.name)
        if (!event.target.value ) {
            setStatusFilterOpen(false);            
            setColumnFilterOpen(false);
            setClientFilterOpen(false);
            setDestinationFilterOpen(false);
        }
      }



    return (

            <Layout>
                
                {/* <button class="bg-black text-white" onClick={testFetch}>FETCH ORDERS</button> */}
                <div class="flex w-2/3 space-x-4 pb-3">
                    <Link to="/orders" class="ibm text-2xl text-gray-300 hover:text-gray-600 mb-0 cursor-pointer no-underline ">Orders</Link>
                    <Link to="/outgoing" class="ibm text-2xl text-black mb-0 cursor-pointer font-bold">Outgoing</Link>
                    <Link to="/inventory" class="ibm text-2xl text-gray-300 hover:text-gray-600 mb-0 cursor-pointer no-underline">Inventory</Link>
                </div>
                <div class="w-2/3">
                    <p>
                        A list of your orders that have been picked up by us and are marked
                        for eventual delivery to a location of your choice.
                    </p>
                </div>
                <div class="flex w-100 pt-1 h-[50px] pb-16"> 
                    <Searcher 
                        preset={searcherPreset}
                        datasets={datasets}    
                        notify={notify}                        
                        onEnter={onSubmitSearch}
                    />
                </div>


                <div class="w-100 flex pt-2 pb-2 border-t-2 border-gray-200">

                    <div class="flex mr-auto space-x-2">

                        <div class="relative inline-block text-left">
                            <div>
                                <button type="button" 
                                    onClick={() => setStatusFilterOpen(!statusFilterOpen)}
                                    class="overflow-hidden inline-flex w-full max-w-[16rem] justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-900 ring-1 shadow-xs ring-gray-300 ring-inset hover:bg-gray-50" id="menu-button" aria-expanded="true" aria-haspopup="true">
                                    <p class="mb-0">Status</p>
                                    <p class="font-bold mb-0 ml-1 whitespace-nowrap overflow-hidden text-ellipsis">
                                        {filterStatus.inventory?"Inventory":""}
                                        {filterStatus.pending?", Pending":""}
                                        {filterStatus.delivered?", Delivered":""}
                                        {filterStatus.delayed?", Delayed":""}
                                        {/* if all none */}                                        
                                        {filterStatus.inventory==false&&filterStatus.pending==false&&filterStatus.delivered==false&&filterStatus.delayed==false?"None":""}
                                    </p>
                                    <svg class="-mr-1 size-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                                        <path fill-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
                                    </svg>
                                </button>                        
                            </div>
                            {/* {statusFilterOpen? */}
                                {/* <div class="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white ring-1 shadow-lg ring-black/5 focus:outline-hidden" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1"> */}
                                <div
                                className={`
                                  absolute left-0 z-10 mt-2 w-56 origin-top-right
                                  rounded-md bg-white ring-1 ring-black/5 shadow-lg focus:outline-hidden 
                                  transform transition-all duration-200
                                  ${statusFilterOpen ? 'scale-y-100 opacity-100' : 'scale-y-0 opacity-0 pointer-events-none'}
                                `}
                                role="menu"
                                aria-orientation="vertical"
                                aria-labelledby="menu-button"
                                tabIndex="-1"
                              >
                                    <div class="py-1" role="none">                                                                        
                                        
                                        <div class="flex flex-row">
                                            <Checkbox onClick={() => changeStatusFilter({...filterStatus, inventory:!filterStatus.inventory})} checked={filterStatus.inventory}/>
                                            <p class="ibm text-md align-middle ml-2 my-auto flex">Inventory</p>
                                        </div>
                                        <div class="flex flex-row">
                                            <Checkbox onClick={() => changeStatusFilter({...filterStatus, pending:!filterStatus.pending})} checked={filterStatus.pending}/>
                                            <p class="ibm text-md align-middle ml-2 my-auto flex">Pending</p>
                                        </div>
                                        <div class="flex flex-row">
                                            <Checkbox onClick={() => changeStatusFilter({...filterStatus, delivered:!filterStatus.delivered})} checked={filterStatus.delivered}/>
                                            <p class="ibm text-md align-middle ml-2 my-auto flex">Delivered</p>
                                        </div>
                                        <div class="flex flex-row">
                                            <Checkbox onClick={() => changeStatusFilter({...filterStatus, delayed:!filterStatus.delayed})} checked={filterStatus.delayed}/>
                                            <p class="ibm text-md align-middle ml-2 my-auto flex">Delayed</p>
                                        </div>
                                        {/* <div class="flex flex-row">
                                            <Checkbox onClick={() => setFilterStatus({...filterStatus, damaged:!filterStatus.damaged})} checked={filterStatus.damaged}/>
                                            <p class="ibm text-md align-middle ml-2 my-auto flex">Damaged</p>
                                        </div>
                                        <div class="flex flex-row">
                                            <Checkbox onClick={() => setFilterStatus({...filterStatus, lost:!filterStatus.lost})} checked={filterStatus.lost}/>
                                            <p class="ibm text-md align-middle ml-2 my-auto flex">Lost</p>
                                        </div> */}

                                    </div>
                                </div>
                            {/* :null} */}
                        </div>
                        

                        <div class="relative inline-block text-left">
                            <div>
                                <button type="button" 
                                    onClick={() => setDestinationFilterOpen(!destinationFilterOpen)}
                                    class="overflow-hidden inline-flex w-full max-w-[16rem] justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-900 ring-1 shadow-xs ring-gray-300 ring-inset hover:bg-gray-50" id="menu-button" aria-expanded="true" aria-haspopup="true">
                                    <p class="mb-0">From</p>
                                    <p class="font-bold mb-0 ml-1 whitespace-nowrap overflow-hidden text-ellipsis">
                                        All
                                    </p>
                                    <svg class="-mr-1 size-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                                        <path fill-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
                                    </svg>
                                </button>                        
                            </div>
                            {/* {destinationFilterOpen? */}
                                {/* <div class="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white ring-1 shadow-lg ring-black/5 focus:outline-hidden" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1"> */}
                                <div
                                className={`
                                  absolute left-0 z-10 mt-2 w-[400px] origin-top-right
                                  rounded-md bg-white ring-1 ring-black/5 shadow-lg focus:outline-hidden 
                                  transform transition-all duration-200
                                  ${destinationFilterOpen ? 'scale-y-100 opacity-100' : 'scale-y-0 opacity-0 pointer-events-none'}
                                `}
                                role="menu"
                                aria-orientation="vertical"
                                aria-labelledby="menu-button"
                                tabIndex="-1"
                              >
                                    <div class="py-1 overflow-y-scroll max-h-[24rem]" role="none">                                
                                        <div class="flex flex-row">
                                            <Checkbox onClick={() => setFilterDestination("All")} checked={filterDestination=="All"}/>
                                            <p class="ibm text-md align-middle ml-2 my-auto flex">All</p>
                                        </div>
                                        <hr class="border-gray-300"/>
                                        {destinationList.map((destination) => (
                                            <div class="flex flex-row">
                                                <Checkbox onClick={() => setFilterDestination(destination)} checked={filterDestination==destination}/>
                                                <p class="ibm text-md align-middle ml-2 my-auto flex">{destination}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            {/* :null} */}
                        </div>

                        {/* CLIENT */}
                        {auth.auth.username=="admin"?
                        <div class="relative inline-block text-left">
                           <div>
                                <button type="button" 
                                    onClick={() => setClientFilterOpen(!clientFilterOpen)}
                                    class="overflow-hidden inline-flex w-full max-w-[16rem] justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-900 ring-1 shadow-xs ring-red-300 ring-inset hover:bg-red-50" id="menu-button" aria-expanded="true" aria-haspopup="true">
                                    <p class="mb-0 text-red-500">Client</p>
                                    <p class="font-bold mb-0 ml-1 text-red-500 whitespace-nowrap overflow-hidden text-ellipsis">
                                        {filterClient}
                                    </p>
                                    <svg class="-mr-1 size-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                                        <path fill-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
                                    </svg>
                                </button>                        
                            </div>
                            <div
                                className={`
                                  absolute left-0 z-10 mt-2 w-[400px] origin-top-right
                                  rounded-md bg-white ring-1 ring-black/5 shadow-lg focus:outline-hidden 
                                  transform transition-all duration-200
                                  ${clientFilterOpen ? 'scale-y-100 opacity-100' : 'scale-y-0 opacity-0 pointer-events-none'}
                                `}
                                role="menu"
                                aria-orientation="vertical"
                                aria-labelledby="menu-button"
                                tabIndex="-1"
                              >
                                    <div class="py-1 overflow-y-scroll max-h-[24rem]" role="none">                                
                                        <div class="flex flex-row">
                                            <Checkbox onClick={() => setFilterClient("All")} checked={filterClient=="All"}/>
                                            <p class="ibm text-md align-middle ml-2 my-auto flex">All</p>
                                        </div>
                                        <hr class="border-gray-400"/>
                                        {clientList.map((client, index) => {                              
                                            return (
                                                <div class="flex flex-row">
                                                    <Checkbox onClick={() => setFilterClient(client)} checked={filterClient==client}/>
                                                    <p class=" text-md align-middle ml-2 my-auto flex">{client}</p>
                                                </div>
                                            )                                                                                    })} 

                                    </div>
                                </div>

                        </div>
                        :null}

                    </div>
                    

                    <div class="flex ml-auto space-x-4">         

                        {/* <div 
                            //onClick={clickDownload} 
                            onClick={displayDownloadDataMenu}
                            class="mr-2 w-36 h-10 outline flex bg-green-800 hover:bg-green-900 text-white font-bold rounded " type="button">
                            <p class="flex m-auto">Download<IoMdDownload class="ml-1" size={24}/></p>
                        </div>       
                        <div 
                            onClick={() => setShowTableMenu(true)} 
                            class="w-28 h-10 outline flex bg-blue-800 hover:bg-blue-900 text-white font-bold rounded " type="button">
                            <p class="flex m-auto">Filters</p>
                        </div>  */}

                        
                        {isLoading?

                        <button 
                            onClick={null}
                            class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-900 ring-1 shadow-xs ring-gray-300 ring-inset hover:bg-gray-50" id="menu-button" aria-expanded="true" aria-haspopup="true"
                        >
                            <span class="p-1 ml-1">
                                <Loading type={1} size={16} />
                            </span>
                            <span class="mr-1 my-auto">
                                Refresh 
                            </span>
                        </button>

                        : 

                        <button 
                            onClick={fetchOutgoingData}
                            class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-900 ring-1 shadow-xs ring-gray-300 ring-inset hover:bg-gray-50" id="menu-button" aria-expanded="true" aria-haspopup="true"
                        >                        
                            <span class="p-1 ">
                                <HiOutlineRefresh size={16} color={"inherit"}/>
                            </span>
                            <span class="mr-1 my-auto">
                                Refresh 
                            </span>
                        </button>
                        }

                        <div class="relative inline-block text-left">
                            <div>
                                <button type="button" 
                                    onClick={() => setColumnFilterOpen(!columnFilterOpen)}
                                    class="inline-flex w-full max-w-[16rem] justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-900 ring-1 shadow-xs ring-gray-300 ring-inset hover:bg-gray-50" id="menu-button" aria-expanded="true" aria-haspopup="true">
                                    <span class="p-1 mr-1">                                    
                                        <BsLayoutThreeColumns />
                                    </span>
                                    <p class="mb-0 my-auto">Columns</p>
                                    
                                    <svg class="-mr-1 size-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                                        <path fill-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
                                    </svg>
                                </button>                        
                            </div>
                            {/* {columnFilterOpen? */}
                                {/* <div class="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white ring-1 shadow-lg ring-black/5 focus:outline-hidden" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1"> */}
                                <div
                                className={`
                                  absolute left-0 z-10 mt-2 w-56 origin-top-right
                                  rounded-md bg-white ring-1 ring-black/5 shadow-lg focus:outline-hidden 
                                  transform transition-all duration-200
                                  ${columnFilterOpen ? 'scale-y-100 opacity-100' : 'scale-y-0 opacity-0 pointer-events-none'}
                                `}
                                role="menu"
                                aria-orientation="vertical"
                                aria-labelledby="menu-button"
                                tabIndex="-1"
                              >
                                    <div class="py-1 overflow-y-scroll max-h-[24rem]" role="none">                                  
                                        {/* <div class="flex flex-row">
                                            <Checkbox onClick={() => setFilterDestination("All")} checked={filterDestination=="All"}/>
                                            <p class="ibm text-md align-middle ml-2 my-auto flex">All</p>
                                        </div> */}
                                        {/* { 
                                            id: 'edit', 
                                            label: 'EDIT', 
                                            minWidth: 50,
                                            align: 'left',
                                            format: (value) => value.toLocaleString('en-US'),
                                        }, */}
                                        {/* <hr class="border-gray-300"/> */}
                                        {columns.map((column) => (
                                            column.id != "edit" && column.id !="doc"?
                                                <div class="flex flex-row pl-2">
                                                    <Checkbox onClick={() => onColumnFilter(column)} checked={!column.hidden}/>
                                                    <p class="ibm text-sm align-middle ml-2 my-auto flex">{column.label}</p>
                                                </div>
                                            :null                                            
                                        ))}
                                    </div>
                                </div>
                            {/* :null} */}
                        </div>

                        <div class="border-l border-gray-300 h-6 my-auto"></div>

                        <button 
                            onClick={displayDownloadDataMenu}
                            class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-slate-200 px-3 py-2 text-sm text-gray-900 ring-1 shadow-xs ring-slate-200 ring-inset hover:bg-slate-300" id="menu-button" aria-expanded="true" aria-haspopup="true">                        
                            <span class="p-1 mr-1">
                                <BsDownload size={16} />
                            </span>
                            <span class="my-auto">
                                Download
                            </span>
                        </button>

                    </div>
                </div>    

                {showPopup
                ? <Popup>
                    <div 
                        id="innerEditMenu" 
                        class="h-44 w-1/4 inline-block bg-white rounded-md border-slate-500 border-2 overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle" 
                        role="dialog" 
                        aria-modal="true" 
                        aria-labelledby="modal-title"
                    >
                        
                        <div class="max-h-12 w-100 px-6 mt-4 flex flex-col">
                            <h2 class="ibm text-black w-100 mt-3">{popupMessage[0]}</h2>       
                            <h3 id="loading" class="ibm !text-slate-400 text-md !bg-white mt-2">{popupMessage[1]}</h3>
                        </div>                        
                            
                    </div>
                  </Popup>                
                :null}

                {showDownloadDataMenu
                ? <MenuDownloadData 
                    data={data}
                    toggleDisplay={displayDownloadDataMenu}
                />
                :null}

                {showTableMenu
                ? <Popup>
                    <div class="flex absolute top-5 right-5">
                        <button onClick={() => setShowTableMenu(false)} class="h-10 w-10 bg-gray-200 hover:bg-gray-300 rounded-full flex m-2">
                            <BsX class="m-auto" size={25}/>
                        </button>
                    </div>
                    <div id="innerEditMenu" class="h-4/6 w-1/2 pt-3 pb-0 inline-block bg-gray-200 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle" role="dialog" aria-modal="true" aria-labelledby="modal-title">
                        
                        <div class="max-h-12 flex w-100 px-6 pb-2 border-b-2 border-black">
                            <h3 class="text-black">Order Filters</h3>
                        </div>                        

                        <div class="flex h-[calc(100%-9rem)] overflow-y-scroll">
                            <div class="h-100 flex flex-col w-1/2 px-12 py-6 ml-6 border-right border-r-2 border-black">
                                {/* <p class="ml-2 font-bold">Filter Status</p> */}
                                <div class="flex flex-row">
                                    <Checkbox onClick={() => setFilterStatus({...filterStatus, inventory:!filterStatus.inventory})} checked={filterStatus.inventory}/>
                                    <p class="align-middle ml-2 my-auto flex">Inventory</p>
                                </div>
                                <div class="flex flex-row">
                                    <Checkbox onClick={() => setFilterStatus({...filterStatus, pending:!filterStatus.pending})} checked={filterStatus.pending}/>
                                    <p class="align-middle ml-2 my-auto flex">Pending</p>
                                </div>
                                <div class="flex flex-row">
                                    <Checkbox onClick={() => setFilterStatus({...filterStatus, delivered:!filterStatus.delivered})} checked={filterStatus.delivered}/>
                                    <p class="align-middle ml-2 my-auto flex">Delivered</p>
                                </div>
                                <div class="flex flex-row">
                                    <Checkbox onClick={() => setFilterStatus({...filterStatus, delayed:!filterStatus.delayed})} checked={filterStatus.delayed}/>
                                    <p class="align-middle ml-2 my-auto flex">Delayed</p>
                                </div>
                                <div class="flex flex-row">
                                    <Checkbox onClick={() => setFilterStatus({...filterStatus, damaged:!filterStatus.damaged})} checked={filterStatus.damaged}/>
                                    <p class="align-middle ml-2 my-auto flex">Damaged</p>
                                </div>
                                <div class="flex flex-row">
                                    <Checkbox onClick={() => setFilterStatus({...filterStatus, lost:!filterStatus.lost})} checked={filterStatus.lost}/>
                                    <p class="align-middle ml-2 my-auto flex">Lost</p>
                                </div>
                            </div>

                            <div class="h-100 flex flex-col w-1/2 px-12 py-6 ml-6 border-black">

                                {auth.auth.username=="admin"?
                                <div class="w-100">
                                    <label class="font-bold text-red-600 block uppercase tracking-wide text-xs mb-2" for="grid-first-name">
                                        Client *
                                    </label>
                                    <select onChange={(e) => setFilterClient(e.target.value)} 
                                        class="w-100 bg-white text-gray-700 border border-red-500 rounded py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Jane" >
                                        <option value="All">All</option>
                                        <option class="py-2" disabled>---------------</option>
                                        {clientList.map((client, index) => {                              
                                            return (
                                                <option value={client} selected={client==filterClient?true:false}>{client}</option>
                                            )                                            
                                        })}                            

                                    </select>
                                    {/* <p class="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                </div>
                                :null}                                
                                
                                <div class="w-full">
                                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                        From
                                    </label>
                                    <select onChange={(e) => setFilterDestination(e.target.value)} 
                                        class="w-100 bg-white text-gray-700 border border-red-500 rounded py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Jane" >
                                        <option value="All">All</option>
                                        <option class="py-2" disabled>---------------</option>
                                        {destinationList.slice(1).map((destination, index) => {                                
                                            return (
                                                <option value={destination} selected={destination==filterDestination?true:false}>{destination}</option>
                                            )
                                        })}
                                    </select>
                                    {/* <p class="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                </div>
                                
                            </div>
                        </div>

                        <div class="flex ">
                            <div class="max-h-12 flex px-6 pt-6 border-t-2 border-black">
                                <div class="w-100 mt-auto flex">
                                    {!isLoading?
                                    <div onClick={savePreferences} class=" ml-auto w-36 h-10 flex bg-gray-500 hover:bg-gray-700 text-white font-bold rounded" type="button">
                                        <p class="flex m-auto">Save</p>
                                    </div> 
                                    :   
                                    <div class="  w-36 h-10 flex bg-blue-800 text-white font-bold rounded pointer-events-none ml-auto" type="button">
                                        <span class="flex m-auto"><Loading type={1} /></span> 
                                    </div> 
                                    }
                                </div> 
                            </div>

                            <div class="max-h-12 flex w-100 px-6 pt-6 border-t-2 border-black">
                                <div class="w-100 mt-auto flex">
                                    {!isLoading?
                                    <div onClick={fetchOutgoingData} class=" ml-auto w-36 h-10 flex bg-blue-800 hover:bg-blue-900 text-white font-bold rounded" type="button">
                                        <p class="flex m-auto">Update</p>
                                    </div> 
                                    :   
                                    <div class="  w-36 h-10 flex bg-blue-800 text-white font-bold rounded pointer-events-none ml-auto" type="button">
                                        <span class="flex m-auto"><Loading type={1} /></span> 
                                    </div> 
                                    }
                                </div> 
                            </div>
                        </div>
                            
                    </div>
                  </Popup>                
                :null}

                {showEditMenu 
                ? <MenuEditOutgoing
                    data={editData}
                    divisionMap={auth.auth.divisionMap} 
                    onToggleDisplay={setShowEditMenu}
                    uploadFile={uploadFile}
                    updateParent={fetchOutgoingData}
                    notify={notify}
                />                      
                : null}       

                <div id="table-wrapper" class="w-100 pb-10">
                    <Table 
                        username={auth.auth.username}
                        loading={isLoading}
                        data={data} 
                        columns={columns} 
                        editFunc={editTableRow}
                        fileFunc={fetchPackingslip}
                        onSort={onSort}
                        totalWeight={totalWeight}
                    />
                </div>
            </Layout>

    )
}
import React, { useRef, useEffect, useState } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import LayoutLanding from '../context/layoutLanding.jsx';
import axios from "../context/axios";
import Loading from '../modules/loading';
// right arrow icon
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import { Checkbox } from '@mui/material';

export default function LandingContact({ notify }) {

 var contactForm = {
      formType: 1,
      name: '',
      email: '',
      company: '',
      phone: '',
      industry: '',
      ship_to_us: false,    
      number_of_people: 0,
      location_from: '', 
      location_to: '',
      quantity: 0,
      weight: '',
      unit: '',   
      message: '',
      details: '',
 }

 var applicationForm = {
    name: '',
    email: '',
    phone: '',
    position: 'Driver',
    authorized_to_work: false,
    able_to_work_nights: false,
    able_to_work_weekends: false,
    cover_letter: '',
    details: '',
  }


  const childRef = useRef();
  let location = useLocation();

  const [isLoading, setIsLoading] = useState(false);  
  const [formData, setFormData] = useState(contactForm);
  const [isApplyForm, setIsApplyForm] = useState(false);
  const [formType, setFormType] = useState(1);
  const [boolShipToWarehouse, setBoolShipToWarehouse] = useState(false);

  useEffect(() => {
    // if url param q=apply, set isApplyForm to true
    if (location.search.includes('q=apply')) {
      setIsApplyForm(true);
      setFormData(applicationForm);
    }
  }, [])

  const onChange = (e) => {
    if (!e.target.value.includes('"') && !e.target.value.includes('`')) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  }

  const preflightChecks = () => {

    if (isApplyForm) {

      console.log("----------------------")
      console.log(formData)

      if ( formData.name.length!==0 && formData.email.length!==0 && formData.cover_letter.length!==0 ) {
        return true;
      }

      return false

    } else {

      if ( formData.formType=='1' ) {
        if ( formData.name.length!==0 && formData.email.length!==0 && formData.company.length!==0 && formData.message.length!==0 ) {
          return true;
        }
      }

      if ( formData.formType=='2' ) {
        if ( formData.name.length!==0 && formData.email.length!==0 && formData.company.length!==0 && formData.message.length!==0 ) {
          return true;
        }
      }

      if ( formData.formType=='3' ) {
        if ( formData.name.length!==0 && formData.email.length!==0 && formData.company.length!==0 && formData.message.length!==0 ) {
          return true;
        }
      }

    return false;

    }
  }

  const onSubmitEmail = () => {

    setIsLoading(true);

    if ( preflightChecks() ) {
          
      try {
        if (isApplyForm) {

          // send to employment table
          const response = axios.post('/contact/apply', formData)

          setFormData({
            name: '',
            email: '',
            phone: '',
            position: '',
            authorized_to_work: false,
            able_to_work_nights: false,
            able_to_work_weekends: false,
            cover_letter: '',
            details: '',
          })

          notify('Thank you. We will get back to you shortly.', 'success');
          setIsLoading(false);


        } else {

          // send to mailbox

          const response = axios.post('/contact/inquire', formData)
          //console.log(response);

          setFormData({
            formType: 1,
            name: '',
            email: '',
            company: '',
            phone: '',
            industry: '',
            ship_to_us: false,    
            number_of_people: 0,
            location_from: '', 
            location_to: '',
            quantity: 0,
            weight: '',
            unit: '',   
            message: '',
            details: '',        
          })

          notify('Thank you. We will get back to you shortly.', 'success');
          setIsLoading(false);
        }
      }
      catch {
        console.log('error');
        notify('Error sending email. Please try again.', 'error');
        setIsLoading(false);
      }
    } 
    else  {
      notify('Please fill out required fields', 'info');
      setIsLoading(false);
    }

  }

  const clickedApply = () => {
    console.log('clicked');
    setIsApplyForm(!isApplyForm)
    setFormData(applicationForm);
  }

  return (
    <LayoutLanding>
        <section class="text-gray-600 body-font relative">
          <div class="flex flex-col container px-5 pt-24 pb-6 mx-auto bg-white shadow-lg">
            

            {isApplyForm ?  

              <p class="flex text-blue-500 mr-auto mb-4 hover:cursor-pointer" onClick={clickedApply}>
                <span class="my-auto mr-2"><IoIosArrowBack/></span>
                Back to Contact Form                
              </p>

            :
              
              <p class="flex text-blue-500 ml-auto mb-4 hover:cursor-pointer" onClick={clickedApply}>                
                Apply For a Position 
                <span class="my-auto ml-2"><IoIosArrowForward/></span>
              </p>

            }
            
            {isApplyForm ?  

              <div class="flex flex-col text-center w-full mb-12">
                <p class="mb-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Apply For a Position
                </p>
                <p class="lg:w-2/3 mx-auto leading-relaxed text-base mt-4">
                    Please fill out the form below to apply for a position at Locks and Docks. We offer competitive wages and benefits. 

                </p>
              </div>
            
            :

              <div class="flex flex-col text-center w-full mb-12">
                <p class="mb-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Contact Us
                </p>
                <p class="lg:w-2/3 mx-auto leading-relaxed text-base mt-4">
                    Please fill out the form below to receive an estimate within 24 hours. Our prices are the lowest in the industry. 
                    Please specify your request.
                </p>
              </div>
            
            }

            {isApplyForm ?

              <div class="lg:w-1/2 md:w-2/3 mx-auto">
              <div class="flex flex-wrap -m-2">
                <div class="p-2 w-100">

                  <div class="relative">
                    <label for="name" class="leading-7 text-sm text-black">Full name *</label>
                    <input onChange={(e) => onChange(e)} value={formData.name} autoComplete={false} type="text" id="name" name="name" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                  </div>
                </div>

                <div class="p-2 w-100">
                  <div class="relative">
                    <label for="email" class="leading-7 text-sm text-black">Email *</label>
                    <input onChange={(e) => onChange(e)} value={formData.email} autoComplete={false} type="email" id="email" name="email" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                  </div>
                </div>


                <div class="p-2 w-100">
                  <div class="relative">
                    <label for="email" class="leading-7 text-sm text-black">Phone (optional)</label>
                    <input onChange={(e) => onChange(e)} value={formData.phone} autoComplete={false} type="text" id="phone" name="phone" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                  </div>
                </div>

                {/* divider */}
                <div class="w-full my-4 border-t border-gray-200"></div>


                <div class="p-2 w-100">
                  <div class="relative">
                    <label for="email" class="leading-7 text-sm text-black mb-2">What position are you applying for?</label>
                    {/* <input onChange={(e) => onChange(e)} value={formData.company} autoComplete={false} type="text" id="company" name="company" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" /> */}
                    <select onChange={(e) => onChange(e)} value={formData.position} autoComplete={false} id="position" name="position" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
                      <option value="Driver">Driver</option>
                      <option value="Management">Management</option>
                      <option value="Warehouse">Warehouse</option>
                    </select>
                  </div>
                </div>

                <div class="w-full my-4 border-t border-gray-200"></div>

                <div class="w-100">
                  <div class="flex h-6 my-2">
                      <Checkbox 
                          checked={formData.authorized_to_work}
                          onChange={() => setFormData({...formData, authorized_to_work: !formData.authorized_to_work})}                                                             
                      />
                      <p>Are you legally authorized to work in Canada?</p>                                
                  </div>

                  <div class="flex h-6 my-2">
                      <Checkbox 
                          checked={formData.able_to_work_nights}
                          onChange={() => setFormData({...formData, able_to_work_nights: !formData.able_to_work_nights})}                                                          
                      />
                      <p>Are you able to work nights?</p>                                
                  </div>

                  <div class="flex h-6 my-2">
                      <Checkbox 
                          checked={formData.able_to_work_weekends}
                          onChange={() => setFormData({...formData, able_to_work_weekends: !formData.able_to_work_weekends})}                                                          
                      />
                      <p>Are you able to work weekends?</p>                                
                  </div>                  
                </div>

                <div class="w-full my-4 border-t border-gray-200"></div>

               <div class="p-2 w-full">
                  <div class="relative">
                    <label for="cover_letter" class="leading-7 text-sm text-black">Cover letter</label>
                    <textarea maxLength={300} onChange={(e) => onChange(e)} value={formData.cover_letter} autoComplete='false' id="cover_letter" name="cover_letter" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-48 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
                  </div>
                </div>

                <div class="w-full my-4 border-t border-gray-200"></div>

                <div class="p-2 w-full">
                  <div class="relative">
                    <label for="message" class="leading-7 text-sm text-gray-600">Additional details and references</label>
                    <textarea maxLength={300} onChange={(e) => onChange(e)} value={formData.details} autoComplete='false' id="details" name="details" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-48 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
                  </div>
                </div>

                <div class="w-full my-4 border-t border-gray-200"></div>

                <div class="flex w-full align-center ">
                  
                </div>

                <div class="flex flex-col text-center w-full">
                  {/* <p class="mb-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Apply For a Position
                  </p> */}
                  <p class="lg:w-2/3 mx-auto leading-relaxed text-base mt-4">
                  If you want to send us your resume, please email us a copy at
                  <a class="text-indigo-500 ml-2">service@locksanddocks.com</a></p>
                </div>

                <div class="p-2 w-full flex my-4">
                  {!isLoading?
                    <div onClick={onSubmitEmail} class="mx-auto w-28 h-10 flex bg-gray-700 hover:!bg-gray-800 text-white font-bold rounded" type="button">
                        <p class="flex m-auto">Submit ✓</p>
                    </div> 
                    :   
                    <div class="w-28 h-10 flex bg-blue-500 text-white font-bold rounded mx-auto pointer-events-none" type="button">
                        <span class="flex m-auto"><Loading type={1} /></span>
                    </div> 
                    }
                </div>

                

                <div class="flex p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center">
                  
                  <p class="leading-normal my-5 md:w-1/2 text-right">
                    Phone (24h/7d): (514) 932-9959<br></br>
                    Fax: (514) 932-2331
                  </p>
                  
                  <p class="leading-normal my-5 md: w-1/2 text-left ml-6">
                    4944 Avenue Dunn, Montreal
                    <br></br>Québec, Canada, H4E 1C2
                  </p>

                </div>
              </div>
              </div>

              :

              <div class="lg:w-1/2 md:w-2/3 mx-auto">
               <div class="flex flex-wrap -m-2">
                
                <select onChange={(e) => onChange(e)} value={formData.formType} autoComplete={false} id="formType" name="formType"
                  class="w-100 p-3 my-4 bg-gray-100 border-2 border-blue-400 text-black rounded">
                  <option value="1">General Inquiry</option>
                  <option value="2">Merchandise Transport</option>
                  <option value="3">Personnel Transport</option>
                </select>

                {/* divider */}
                <div class="w-full my-4 border-t border-gray-200"></div>

                <div class="p-2 w-1/2">
                  <div class="relative">
                    <label for="name" class="leading-7 text-sm text-gray-600">Name *</label>
                    <input onChange={(e) => onChange(e)} value={formData.name} autoComplete={false} type="text" id="name" name="name" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                  </div>
                </div>

                <div class="p-2 w-1/2">
                  <div class="relative">
                    <label for="email" class="leading-7 text-sm text-gray-600">Email *</label>
                    <input onChange={(e) => onChange(e)} value={formData.email} autoComplete={false} type="email" id="email" name="email" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                  </div>
                </div>

                <div class="p-2 w-1/2">
                  <div class="relative">
                    <label for="email" class="leading-7 text-sm text-gray-600">Company * </label>
                    <input onChange={(e) => onChange(e)} value={formData.company} autoComplete={false} type="text" id="company" name="company" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                  </div>
                </div>

                <div class="p-2 w-1/2">
                  <div class="relative">
                    <label for="email" class="leading-7 text-sm text-gray-600">Phone (optional)</label>
                    <input onChange={(e) => onChange(e)} value={formData.phone} autoComplete={false} type="text" id="phone" name="phone" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                  </div>
                </div>

                <div class="p-2 w-full">
                  <div class="relative">
                    <label for="email" class="leading-7 text-sm text-gray-600">Your Industry (optional)</label>
                    <input onChange={(e) => onChange(e)} value={formData.industry} autoComplete={false} type="text" id="industry" name="industry" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                  </div>
                </div>

                <div class="w-full my-4 border-t border-gray-200"></div>

                {formData.formType==='1' ?

                      <div class="p-2 w-full hidden">       
                      </div>

                :

                formData.formType==='2' ?

                      <>
                        <div class="w-100 my-2">
                          <div class="flex h-6 my-2">
                              <Checkbox 
                                  checked={formData.ship_to_us}
                                  onChange={() => setFormData({...formData, ship_to_us: !formData.ship_to_us})}                                                             
                              />
                              <p class="text-gray-600">Ship to our warehouse.</p>                                
                          </div>                
                        </div>

                        <div class="p-2 w-full">
                          <div class="relative">
                            <label for="email" class={formData.ship_to_us?"leading-7 text-sm text-gray-400":"leading-7 text-sm text-gray-600"}>Pick up location</label>
                            <input disabled={formData.ship_to_us} onChange={(e) => onChange(e)} value={formData.location_to} autoComplete={false} type="text" id="location_to" name="location_to" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                          </div>
                        </div>

                        <div class="p-2 w-full">
                          <div class="relative">
                            <label class="leading-7 text-sm text-gray-600">Deliver to location</label>
                            <input onChange={(e) => onChange(e)} value={formData.location_from} autoComplete={false} type="text" id="location_from" name="location_from" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                          </div>
                        </div>

                        <div class="p-2 w-1/3">
                          <div class="relative">
                            <label  class="leading-7 text-sm text-gray-600">Quantity</label>
                            <input onChange={(e) => onChange(e)} value={formData.quantity} autoComplete={false} type="text" id="quantity" name="quantity" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                          </div>
                        </div>

                        <div class="p-2 w-1/3">
                          <div class="relative">
                            <label class="leading-7 text-sm text-gray-600">Unit</label>
                            <input onChange={(e) => onChange(e)} value={formData.unit} autoComplete={false} placeholder="Boxes, skids, etc..." type="text" id="unit" name="unit" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                          </div>
                        </div>

                        <div class="p-2 w-1/3">
                          <div class="relative">
                            <label class="leading-7 text-sm text-gray-600">Total weight</label>
                            <input onChange={(e) => onChange(e)} value={formData.total_weight} autoComplete={false} type="text" id="total_weight" name="total_weight" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                          </div>
                        </div>   

                        <div class="w-full my-4 border-t border-gray-200"></div>                       
                        
                      </>
                      
                :        

                formData.formType==='3' ?                

                      <>
                        
                        <div class="p-2 w-full">
                          <div class="relative">
                            <label for="email" class="leading-7 text-sm text-gray-600">Number of people</label>
                            <input type="number" onChange={(e) => onChange(e)} value={formData.industry} autoComplete={false} class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                          </div>
                        </div>

                        <div class="p-2 w-full">
                          <div class="relative">
                            <label for="email" class="leading-7 text-sm text-gray-600">Pick up location</label>
                            <input onChange={(e) => onChange(e)} value={formData.phone} autoComplete={false} type="text" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                          </div>
                        </div>

                        <div class="p-2 w-full">
                          <div class="relative">
                            <label for="email" class="leading-7 text-sm text-gray-600">Drop-off location</label>
                            <input onChange={(e) => onChange(e)} value={formData.industry} autoComplete={false} type="text" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                          </div>
                        </div>

                        <div class="w-full my-4 border-t border-gray-200"></div>
                      </>                                
                
                :null}

                <div class="p-2 w-full">
                  <div class="relative">
                    <label for="message" class="leading-7 text-sm text-gray-600">Requested Service *</label>
                    <textarea onChange={(e) => onChange(e)} value={formData.message} autoComplete='false' id="message" name="message" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
                  </div>
                </div>

                <div class="w-full my-4 border-t border-gray-200"></div>

                <div class="p-2 w-full">
                  <div class="relative">
                    <label for="message" class="leading-7 text-sm text-gray-600">Additional Details (optional)</label>
                    <textarea onChange={(e) => onChange(e)} value={formData.details} autoComplete='false' id="details" name="details" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
                  </div>
                </div>

                <div class="p-2 w-full flex my-4">
                  {!isLoading?
                    <div onClick={onSubmitEmail} class="mx-auto w-28 h-10 flex bg-gray-700
                     hover:!bg-gray-700 text-white font-bold rounded" type="button">
                        <p class="flex m-auto">Submit ✓</p>
                    </div> 
                    :   
                    <div class="w-28 h-10 flex bg-blue-500 text-white font-bold rounded mx-auto pointer-events-none" type="button">
                        <span class="flex m-auto"><Loading type={1} /></span>
                    </div> 
                    }
                </div>


                <a class="text-indigo-500 pt-3 mx-auto">service@locksanddocks.com</a>

                <div class="flex p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center">
                  
                  <p class="leading-normal my-5 md:w-1/2 text-right">
                    Phone (24h/7d): (514) 932-9959<br></br>
                    Fax: (514) 932-2331
                  </p>
                  
                  <p class="leading-normal my-5 md: w-1/2 text-left ml-6">
                    4944 Avenue Dunn, Montreal
                    <br></br>Québec, Canada, H4E 1C2
                  </p>

                </div>
              </div>
            </div>
            
            }


          </div>
        </section>

    </LayoutLanding>
  );
}
















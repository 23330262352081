import React, { useRef, useEffect, useState } from 'react';
//import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';

import '../../static/css/home.css';

import Navbar from '../modules/navbar';
import Footer from '../modules/footer';

import FooterLanding from '../modules/footerLanding';

import LayoutLanding from '../context/layoutLanding';

// import video
import landing_video from '../../static/assets/videos/landing-video-hd.webm';
//import logo from '../../static/assets/images/logo_big_gray.png';
//import logo from '../../static/assets/images/image832_white.png';
import logo from '../../static/assets/images/logo_big_blue.png';

import img2 from '../../static/assets/images/chainlink.png';
import img3 from '../../static/assets/images/calcheckmark.png';
import img4 from '../../static/assets/images/flex-logistic.png';

import showcase1 from '../../static/assets/images/showcase1.webp';
import showcase2 from '../../static/assets/images/showcase2.webp';
import showcase3 from '../../static/assets/images/showcase3.webp';

import { Icon } from '@mui/material';

//import vid_shipping from '../../assets/97854-imprint-genius-hero.mp4'

//ReactGA.initialize(process.env.REACT_APP_GA_CODE);

//const trackPage = page => {
//  ReactGA.set({ page });
//  ReactGA.pageview(page);
//};

import Showcase from '../modules/showcase';

import bollard from '../../static/assets/icons/bollard.svg';

import { LuWarehouse } from "react-icons/lu";


import { TfiCheckBox } from "react-icons/tfi";


// import NavDropdown from 'react-bootstrap/NavDropdown';
// import Nav from 'react-bootstrap/Nav';

const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};



const accordionItems = [
  { title: 'How do you receive my orders?', content: 'You can send us any goods you would like us to hold. Manufacturers or suppliers can send us the goods directly or you can send them yourself. We also offer a pickup service to collect goods from anywhere needed.' }, 
  { title: 'What days do you operate on?', content: 'We operate 24 hours a day, 7 days a week, 365 days a year. We are always open. Call us any time.' },
  { title: 'Do you handle dangerous goods?', content: 'Yes, we handle dangerous goods. We have the necessary permits and equipment to handle all types of goods.' },
  { title: 'How many personnel can you transport?', content: 'Generally, there is no set limit, we can transport as many personnel as you need. We have a fleet of vehicles that can handle most requirements.' },
  { title: 'What is the best way to contact you?', content: 'The best way to contact us is by phone at 1-514-932-9959 or email (service@locksanddocks.com). We are always available and can quote you on a job right away.' },
  { title: 'How do I create an account?', content: 'To create an account, you need to contact us directly. User accounts get created automatically when you use our services.' }, 
  // { title: 'How do I request my inventory to be delivered?', content: 'For now, requesting inventory to be delivered is done by calling us directly or by sending us an email. We are working on implementing a feature that will allow you to request inventory to be delivered directly from your account. This feature will be released in a coming update.'}

];


export default function Home({ notify }) {

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [activeIndex, setActiveIndex] = useState(null);

  const contentRefs = useRef([]);

  //const childRef = useRef();
  //let location = useLocation();

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const getContentHeight = (index) => {
    if (contentRefs.current[index]) {
      return activeIndex === index
        ? `${contentRefs.current[index].scrollHeight}px`
        : '0px';
    }
    return '0px';
  };

  useEffect(() => {
    //const page = location.pathname;
    //document.body.classNameList.add('is-loaded')
    //childRef.current.init();
    //trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, []);


  


  return (

        // <div class="h-100 w-100 overflow-hidden">

      <div class="h-100 w-100">

        <div class="flex flex-col">

              <div class="w-100 h-7 bg-blue-200 z-20 ">
                <p class="indent-2 ibm font-semibold"> For pricing contact us directly !</p>
              </div>

              <div class="bg-white w-100 z-20">
                  <div class="px-12 flex justify-between">
                    <div class="flex space-x-1">
                      <Link href="/" class="flex h-6 my-auto "><img src={logo} height={100} width={100} alt="logo"></img></Link>
                      <div class="w-6 my-3 text-gray-300 border-r-2 border-gray-400"></div>
                      
                      <div class="my-auto pl-10">
                        <div class="relative inline-block text-left">
                              <div>
                                  <button type="button" 
                                      onClick={() => setDropdownOpen(!dropdownOpen)}
                                      class=" h-14 overflow-hidden flex hover:bg-gray-200 w-full max-w-[16rem] justify-center gap-x-1.5 bg-gray-0 px-3 py-2 text-gray-900 " id="menu-button" aria-expanded="true" aria-haspopup="true">                                    
                                      {/* class="h-14 content-center hover:bg-gray-200 navbar_link hover:text-gray-200 text-black no-underline font-medium tracking-wide cursor-pointer px-3" */}
                                        <span class="ibm navbar_link my-auto">Services</span>                 
                                        <svg class="my-auto size-5 text-gray-400 " viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                                            <path fill-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
                                        </svg>
                                  </button>                        
                              </div>
                              <div
                                className={`
                                  absolute left-0 z-10 mt-2 w-72 origin-top-right
                                  rounded-md bg-white ring-1 ring-black/5 shadow-lg focus:outline-hidden 
                                  transform transition-all duration-200
                                  ${dropdownOpen ? 'scale-y-100 opacity-100' : 'scale-y-0 opacity-0 pointer-events-none'}
                                `}
                                role="menu"
                                aria-orientation="vertical"
                                aria-labelledby="menu-button"
                                tabIndex="-1"
                              >
                                <div className="py-1 p-2" role="none">
                                      <div className="py-2">
                                        <Link to="/delivery" 
                                          class="w-100 flex-col no-underline text-base text-gray-900 flex hover:bg-slate-100 px-2 py-1 rounded-lg">
                                          {/* <span class="flex my-auto">
                                              <LuWarehouse class="my-auto" />
                                          </span> */}
                                          <span class="flex text-lg font-bold my-auto whitespace-nowrap text-md ibm">Delivery</span>                    
                                          <p class="ibm text-sm mb-0">Move any type of goods.</p>
                                        </Link>
                                      </div>
                                      <div className="py-2">
                                        <Link to="/transport" 
                                          class="w-100 flex-col no-underline text-base text-gray-900 flex hover:bg-slate-100 px-2 py-1 rounded-lg">
                                          {/* <span class="flex my-auto">
                                              <LuWarehouse class="my-auto" />
                                          </span> */}
                                          <span class="flex text-lg font-bold my-auto whitespace-nowrap text-md ibm">Transport</span>                    
                                          <p class="ibm text-sm mb-0">Transport for personnel and items.</p>
                                        </Link>
                                      </div>
                                      <div className="py-2">
                                        <Link to="/warehousing" 
                                          class="w-100 flex-col no-underline text-base text-gray-900 flex hover:bg-slate-100 px-2 py-1 rounded-lg">
                                          {/* <span class="flex my-auto">
                                              <LuWarehouse class="my-auto" />
                                          </span> */}
                                          <span class="flex text-lg font-bold my-auto whitespace-nowrap text-md ibm">Warehousing</span>                    
                                          <p class="ibm text-sm mb-0">
                                            Store your goods in our warehouses.
                                          </p>
                                        </Link>
                                      </div>
                                      {/* <div className="py-2">
                                        <Link to="/logistics" 
                                          class="w-100 flex-col no-underline text-base text-gray-900 flex hover:bg-slate-100 px-2 py-1 rounded-lg">                   
                                          <span class="flex font-bold text-lg my-auto whitespace-nowrap text-md ibm">Logistics</span>                    
                                          <p class="ibm text-sm mb-0">
                                            Logistics and supply chain management.
                                          </p>
                                        </Link>
                                      </div> */}
                                </div>
                              </div>
                          
                        </div>
                      </div>
                      
                      {/* <a href="" class="h-14 content-center hover:bg-gray-200 navbar_link hover:text-blue-500 text-black no-underline font-medium tracking-wide cursor-pointer px-3">Transport</a> */}
                      {/* <a href="/delivery" class="h-14 content-center hover:bg-gray-200 navbar_link hover:text-gray-200 text-black no-underline font-medium tracking-wide cursor-pointer px-3">Delivery</a> */}
                      {/* <a href="/warehousing" class="h-14 content-center hover:bg-gray-200 navbar_link hover:text-gray-200 text-black no-underline font-medium tracking-wide cursor-pointer px-3">Warehousing</a> */}
                      {/* <a href="/logistics" class="h-14 content-center hover:bg-gray-200 navbar_link hover:text-gray-200 text-black no-underline font-medium tracking-wide cursor-pointer px-3">Logistics</a> */}
                      <Link to="/about" 
                        class="h-14 content-center hover:bg-gray-200 navbar_link hover:text-gray-200 text-black no-underline font-medium tracking-wide cursor-pointer px-3"
                      >
                          About
                        </Link>
                      <Link to="/contact" class="ibm h-14 content-center hover:bg-gray-200 navbar_link hover:text-gray-200 text-black no-underline font-medium tracking-wide cursor-pointer px-3">Contact</Link>                
                      <Link to="/contact?q=apply" class="ibm h-14 content-center hover:bg-gray-200 navbar_link hover:text-gray-200 text-black no-underline font-medium tracking-wide cursor-pointer px-3">Apply</Link>                
                      <a href="#faq" class="ibm h-14 content-center hover:bg-gray-200 navbar_link hover:text-gray-200 text-black no-underline font-medium tracking-wide cursor-pointer px-3">FAQ</a>                
                    </div>

                    <div  class="flex items-center space-x-8 ">
                    </div>
            
                    <div class="flex items-center space-x-6">
                      <Link to="/info" class="bg-gray-700 text-white hover:bg-gray-800 font-medium no-underline tracking-wide py-2 px-4 rounded transition duration-300 ease-in-out">Get Started</Link>
                      <Link to="/login" class="bg-blue-700 text-white hover:bg-blue-800  font-medium no-underline tracking-wide py-2 px-4 rounded transition duration-300 ease-in-out">Sign In</Link>
                      {/* <a href="#" class="text-gray-300 hover:text-white font-medium tracking-wide no-underline">English</a> */}
                    </div>
                  </div>
                </div>   

          </div>

            {/* video goes here */}
            <div class="video-container">
              <video 
                  onContextMenu={e => e.preventDefault()}
                  id="landing_video" 
                  class="w-full h-full overflow-hidden" autoPlay muted loop>
                {/* <source src="https://www.shutterstock.com/shutterstock/videos/1028333156/preview/stock-footage-the-camera-follows-a-white-delivery-van-driving-on-a-desert-highway-into-the-sunset-low-angle-rear.webm" type="video/mp4" /> */}
                {/* <source src="https://www.shutterstock.com/shutterstock/videos/1028333153/preview/stock-footage-the-camera-follows-a-white-delivery-van-driving-on-a-desert-highway-into-the-sunset-side-view.webm"  type="video/mp4" /> */}
                <source src={landing_video} type="video/mp4" />
              </video>
            </div>   
          
          <div class="flex flex-col w-100 h-[90%] overflow-hidden justify-center bg-transparent">

               
                {/* <svg id="dot_svg" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" style={{zIndex: 10, position: 'absolute', marginBottom: '15px'}}>
                  <pattern id="dot-pattern" patternUnits="userSpaceOnUse" width="80" height="40">

                      <circle fill="#ddd" cx="20" cy="20" r="2"></circle>
                      <circle fill="#ddd" cx="60" cy="20" r="2"></circle>
                  </pattern>
                  <rect width="160%" height="100%" fill="url(#dot-pattern)">
                      <animateTransform attributeName="transform" type="translate" from="-80 0" to="0 0" dur="5s" repeatCount="indefinite" />
                  </rect>
              </svg> */}


                  {/* <div className="absolute flex flex-col w-100 top-auto transform -translate-y-1/2 text-center" style={{zIndex: 9999}}>              */}
                  <div className="ibm absolute flex flex-col w-100 top-auto transform text-center pl-20" style={{zIndex: 9999}}>             
                      <p class="text-6xl text-white font-semibold">
                        
                        Transport
                        Anything, Anyone
                        <br></br>
                        <span class="font-regular text-rose-500 ml-3 mr-3 font-semibold">Anytime</span> 
                        
                        in Canada.
                      </p>    

                      {/* <p class="text-2xl text-white font-semibold py-2">
                        24 hours a day. 7 days a week. Holidays. Weekends.
                      </p> */}

                      <p id="home_text" class="relative font-bold py-2">
                        Custom solutions for transporting goods, equipment and personnel.
                        <br></br>
                        Warehousing. Forwarding. Analytics. Lowest prices in the industry.
                        <br></br>                  
                        <p class="font-bold">Since 1988.</p>
                      </p>     

                      <div class="flex">
                        <div class="">
                            {/* create a contact and sign in button */}

                            {/* <div class="flex justify-center w-200 h-14">
                              <button style={{width:"130px"}} className=" bg-black outline-double rounded px-4 w-300 hover:!bg-gray-200 ">
                                <a href="/info" className="no-underline">
                                  <span className="main_btn text-white">Get Quote</span>
                                </a>
                              </button>*/}

                              {/* <div class="w-6"></div>  */}

                              {/* <button style={{width:"130px"}} className=" outline-double bg-gray-800 rounded px-3 hover:bg-gray-500 w-200">
                                <a href="/login" className="no-underline">
                                  <span className="main_btn text-white">Sign In</span>
                                </a>
                              </button> */}
                            {/* </div> */}

                        </div>
                      </div>                    


                  </div>

                  <div class="absolute w-100 justify-center top-30">

                    {/* <div class="w-100 text-center">
                      <p id="home_text" class="font-bold top-100 ">
          2            Custom solutions for transportation of goods, equipment and personnel.
                        <br></br>
                        Warehousing. Forwarding. Analytics. Lowest prices in the industry.
                      </p>
                    </div> */}

                    {/* <div class="w-100 flex h-12 mx-auto justify-center">
                      <button className="bg-blue-800 rounded px-3 hover:bg-blue-900 ">
                        <a href="/info" className="no-underline">
                          <span className="text-white">Get a quote</span>
                        </a>
                      </button> 
                    </div> */}

                  </div>
                  

                  <div class="absolute flex bottom-0 bg-transparent h-6 w-100 p-1">
                    <p class="flex ml-auto text-white text-sm mr-1">Locks and Docks Forwarding Inc. {new Date().getFullYear()}</p>
                </div> 


          </div>


          <Showcase bgColor="bg-white">

              <div class="flex w-100 pb-16">
                  <img class="h-36 mx-auto" src={img2} alt="img"></img>
              </div>

              <div class="flex ibm px-20 w-100">
                <h1 class="ibm mx-auto text-5xl w-3/5 text-center">
                    How are we better than other transport services?                    
                </h1>
              </div>

              <div class="flex w-100 p-10">
                <p class="ibm mx-auto w-2/5 text-center text-xl">
                {/* Reliability, Availability, Flexibility.
                <br></br>
                <br></br> */}

                  Our model is different from other transport companies. 
                  We keep our overhead low and pass the savings on to our clients. 
                  It allows us to provide the best service at the lowest cost.
                </p>
              </div>

              {/* <div class="flex">
                <div class="flex flex-col">
                  <p>Reliability</p>
                </div>

                <div class="flex flex-col">
                  <p>Availability</p>
                </div>

                <div class="flex flex-col">
                  <p>Flexibility</p>
                </div>
              </div> */}


              <div class="flex w-100 py-1">
                  <div class="w-1/3 mx-auto flex space-x-10">                                                
                          {/* <svg 
                            viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"
                            class="h-24 randicon mx-auto my-auto"
                          >
                              <path class="a" d="M5.5,21.8c3.8-2.1,2.2-5.9,7.4-6.9,3.7-.7,5.4,1.5,6,3.6,3.8,12.9,9.5,11.3,13.7,8,2.5-2,5.7-.7,6.4,3.2.8,4.3,2.8,4.1,3.4,4.3"/>
                              <path class="a" d="M40.5,42.5H7.5a2,2,0,0,1-2-2V7.5a2,2,0,0,1,2-2h33a2,2,0,0,1,2,2v33A2,2,0,0,1,40.5,42.5Z"/>
                              <line class="a" x1="12.9" y1="5.5" x2="12.9" y2="5.5"/><line class="b" x1="12.9" y1="9.2" x2="12.9" y2="40.6"/><line class="a" x1="12.9" y1="42.5" x2="12.9" y2="42.5"/><line class="a" x1="20.3" y1="5.5" x2="20.3" y2="5.5"/><line class="b" x1="20.3" y1="9.2" x2="20.3" y2="40.6"/><line class="a" x1="20.3" y1="42.5" x2="20.3" y2="42.5"/><line class="a" x1="27.7" y1="5.5" x2="27.7" y2="5.5"/><line class="b" x1="27.7" y1="9.2" x2="27.7" y2="40.6"/><line class="a" x1="27.7" y1="42.5" x2="27.7" y2="42.5"/><line class="a" x1="35.1" y1="5.5" x2="35.1" y2="5.5"/><line class="b" x1="35.1" y1="9.2" x2="35.1" y2="40.6"/><line class="a" x1="35.1" y1="42.5" x2="35.1" y2="42.5"/><line class="a" x1="42.5" y1="12.9" x2="42.5" y2="12.9"/><line class="b" x1="38.8" y1="12.9" x2="7.4" y2="12.9"/><line class="a" x1="5.5" y1="12.9" x2="5.5" y2="12.9"/><line class="a" x1="42.5" y1="20.3" x2="42.5" y2="20.3"/><line class="b" x1="38.8" y1="20.3" x2="7.4" y2="20.3"/><line class="a" x1="5.5" y1="20.3" x2="5.5" y2="20.3"/><line class="a" x1="42.5" y1="27.7" x2="42.5" y2="27.7"/><line class="b" x1="38.8" y1="27.7" x2="7.3" y2="27.7"/><line class="a" x1="5.5" y1="27.7" x2="5.5" y2="27.7"/><line class="a" x1="42.5" y1="35.1" x2="42.5" y2="35.1"/><line class="b" x1="38.8" y1="35.1" x2="7.3" y2="35.1"/><line class="a" x1="5.5" y1="35.1" x2="5.5" y2="35.1"/>
                          </svg> */}
                          {/* <img class="h-16 my-auto" src={img2} alt="img"></img> */}
                          <TfiCheckBox class="my-auto" size={50}/>
                          <div class="flex flex-col w-100 py-10">
                                <p class="underline ibm text-3xl font-semibold">Reliability</p>
                                <p class="ibm text-left text-xl">                                  
                                    The most reliable and cost effective transport service in Canada.
                                    Our business is specialy designed to help other businesses.
                                                                   
                                </p>
                          </div>
                    </div>
              </div>

              <div class="flex w-100 py-1">
                  <div class="w-1/3 mx-auto flex space-x-10">
                          {/* <div class="flex my-auto w-fit">
                            <img class="h-24" src={img3} alt="img"></img>
                          </div> */}
                          {/* <img class="h-16 my-auto" src={img2} alt="img"></img> */}
                          <TfiCheckBox class="my-auto" size={50}/>
                          <div class="flex flex-col w-fit py-10">
                                <p class="underline ibm text-3xl font-semibold mr-auto">Availability</p>
                                <p class="ibm text-xl text-left">                                  
                                    We are always available to help. 
                                    Our goal is to be a trusted partner that your business can consistently rely on.
                                                                                                                                
                                </p>
                          </div>
                    </div>
              </div>

              <div class="flex w-100 py-1">
                  <div class="w-1/3 mx-auto flex space-x-10">                      
                          {/* <div class="flex my-auto">
                            <img class="h-24" src={img4} alt="img"></img>
                          </div> */}
                          {/* <img class="h-16 my-auto" src={img2} alt="img"></img> */}
                          <TfiCheckBox class="my-auto" size={50}/>
                          <div class="flex flex-col w-100 py-10 ">
                                <p class="underline ibm text-3xl font-semibold">Flexibility</p>
                                <p class="ibm text-left text-xl">                                  
                                    We pride ourselves on our flexibility and adaptability. 
                                    We’re not bound by rigid policies or procedures.
                                    
                                    
                                </p>
                          </div>
                    </div>
              </div>


              {/* <svg 
                width="250px" height="250px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"
                class="randicon mx-auto"
              >
                  <path class="a" d="M5.5,21.8c3.8-2.1,2.2-5.9,7.4-6.9,3.7-.7,5.4,1.5,6,3.6,3.8,12.9,9.5,11.3,13.7,8,2.5-2,5.7-.7,6.4,3.2.8,4.3,2.8,4.1,3.4,4.3"/>
                  <path class="a" d="M40.5,42.5H7.5a2,2,0,0,1-2-2V7.5a2,2,0,0,1,2-2h33a2,2,0,0,1,2,2v33A2,2,0,0,1,40.5,42.5Z"/>
                  <line class="a" x1="12.9" y1="5.5" x2="12.9" y2="5.5"/><line class="b" x1="12.9" y1="9.2" x2="12.9" y2="40.6"/><line class="a" x1="12.9" y1="42.5" x2="12.9" y2="42.5"/><line class="a" x1="20.3" y1="5.5" x2="20.3" y2="5.5"/><line class="b" x1="20.3" y1="9.2" x2="20.3" y2="40.6"/><line class="a" x1="20.3" y1="42.5" x2="20.3" y2="42.5"/><line class="a" x1="27.7" y1="5.5" x2="27.7" y2="5.5"/><line class="b" x1="27.7" y1="9.2" x2="27.7" y2="40.6"/><line class="a" x1="27.7" y1="42.5" x2="27.7" y2="42.5"/><line class="a" x1="35.1" y1="5.5" x2="35.1" y2="5.5"/><line class="b" x1="35.1" y1="9.2" x2="35.1" y2="40.6"/><line class="a" x1="35.1" y1="42.5" x2="35.1" y2="42.5"/><line class="a" x1="42.5" y1="12.9" x2="42.5" y2="12.9"/><line class="b" x1="38.8" y1="12.9" x2="7.4" y2="12.9"/><line class="a" x1="5.5" y1="12.9" x2="5.5" y2="12.9"/><line class="a" x1="42.5" y1="20.3" x2="42.5" y2="20.3"/><line class="b" x1="38.8" y1="20.3" x2="7.4" y2="20.3"/><line class="a" x1="5.5" y1="20.3" x2="5.5" y2="20.3"/><line class="a" x1="42.5" y1="27.7" x2="42.5" y2="27.7"/><line class="b" x1="38.8" y1="27.7" x2="7.3" y2="27.7"/><line class="a" x1="5.5" y1="27.7" x2="5.5" y2="27.7"/><line class="a" x1="42.5" y1="35.1" x2="42.5" y2="35.1"/><line class="b" x1="38.8" y1="35.1" x2="7.3" y2="35.1"/><line class="a" x1="5.5" y1="35.1" x2="5.5" y2="35.1"/>
              </svg> */}

              {/* RELIABILITY

              AVAILABILITY

              EFFICIENCY */}

              

          </Showcase>
            
          {/* home page showcase section */}
          <Showcase bgColor="bg-gray-100"> 


            <div class="py-10">
                
                <div class="flex ibm px-20 w-100">
                  <h1 class="ibm mx-auto text-5xl">
                      Your greatest ally in business.
                    
                  </h1>
                </div>

                <br></br>

                <div class="flex w-100 px-20">
                  <p class="ibm text-xl mx-auto">
                      We provide the best transport solutions for your operation. Grow your business with us.
                  </p>                
                </div>
              </div>
              
              <br></br>
              
              <div class="p-10">
                <div class="showcase-bg px-20 rounded-3xl relative overflow-hidden min-w-[1600px] max-w-[80%] mx-auto">
                      <img
                        class="w-100 h-100 rounded-3xl opacity-0"
                        src={showcase1} 
                        alt=""
                      >
                      </img>
                      <img                    
                          width={1150} 
                          class="shadow absolute z-20 rounded-3xl bottom-10 left-10"
                          src={showcase2}
                          alt="" 
                      />
                      <img           
                          width={800}             
                          class="showcase3-img shadow absolute z-19 rounded-3xl top-10 right-10"
                          src={showcase3} 
                          alt="" 
                      />            
                </div>
              </div>

          </Showcase>

          {/* home page showcase section */}
          <Showcase bgColor="bg-white">
              
              <div class="flex w-100">
                  <div class="ibm w-1/2 px-20 my-auto">
                    <h1 class="ibm">Easily track orders and inventory online.</h1>
                    <p class="mb-0 text-lg ibm">
                        Use our cloud based software to manage inventory and storage, plan deliveries and ship orders.
                    </p>
                  </div>

                  <div class="flex flex-col w-1/2 px-20 ml-auto text-lg py-14">

                      <div class="flex">
                        <span
                          class="my-auto"
                        >
                          <img                             
                            src={img4} alt="landing" 
                            class="object-cover w-20 h-20 mx-2"/>
                        </span>
                        <div class="flex flex-col ml-12 pt-6">
                          <h1 class="ibm">Delivery</h1>                  
                          <p class="ibm">
                              We deliver and forward goods and equipment everywhere. 

                          </p> 
                        </div>               
                      </div>

                      {/* dividing line */}                      
                      <div class="w-100 border-gray-200 border-b-2 h-12 mb-6"></div>

                      <div class="flex">
                        <span
                          class="my-auto"
                        >
                          <img                             
                            src={require("../../static/assets/icons/warehouse.png")}
                            class="object-cover w-20 h-20 mx-2"/>
                        </span>
                        <div class="flex flex-col ml-12 pt-6">
                          <h1 class="ibm">Warehousing</h1>                  
                          <p class="ibm">
                              Safe and secure storage for your goods and equipment.
                          </p> 
                        </div>               
                      </div>


                      <div class="w-100 border-gray-200 border-b-2 h-12 mb-6"></div>

                      <div class="flex">
                        <span
                          class="my-auto"
                        >
                          <img                             
                            src={require("../../static/assets/icons/worker-group.png")}
                            class="object-cover w-20 h-20 mx-2"/>
                        </span>
                        <div class="flex flex-col ml-12 pt-6">
                          <h1 class="ibm">Transport</h1>                  
                          <p class="ibm">
                              Custom transportation solutions for personnel and parcels.
                          </p> 
                        </div>               
                      </div>


                  </div>
              </div>

          </Showcase>

          {/* home page showcase section */}
          <Showcase bgColor="bg-gray-100">
              
              <div class="flex flex-col w-100">

                  <div class="ibm px-20 mx-auto pt-20 pb-6">
                    <h1 class="ibm">
                        Manage all of your transportation needs in one place.
                    </h1>
                    <br></br>
                    
                    <div class="flex w-100 px-20">
                      <p class="ibm text-xl mx-auto">
                      Let us handle the logistics while you focus on the big picture.
                      </p>                
                    </div>
                  </div>

                  <div class="w-8/12 shadow-sm mx-auto px-20 py-16 rounded-3xl mb-10 text-lg mt-10 pt-10 bg-white">

                        <img
                          src={require("../../static/assets/images/infog.png")}
                          alt="landing"
                          class="object-cover mx-auto bg-white px-20 pb-10 rounded-3xl"
                          height={300}
                          width={1200}
                        />
                        


                  </div>
              </div>

          </Showcase>

          {/* home page FAQ section */}
          <Showcase bgColor="bg-white">

            <div id="faq" class="flex flex-col w-100 py-20">

              <div class="ibm px-20 mx-auto">
                <h1 class="ibm">Frequently Asked Questions</h1>
                <p class="text-gray-400 text-center">
                  If you have any additional questions, please 
                  <Link to="/contact" class="mx-1">
                          contact
                  </Link>us directly.
                </p>
              </div>

              
              {/* create an accordian with tailwind css and states */}

              <div className="space-y-4 w-100 max-w-3xl mx-auto py-20">
                {accordionItems.map((item, index) => (
                  <div key={index} className=" rounded-lg shadow-sm">
                    <button
                      className="ibm w-full flex justify-between items-center px-4 py-3 text-left text-lg font-medium bg-white hover:bg-gray-200 rounded-t-lg focus:outline-none"
                      onClick={() => handleToggle(index)}
                    >
                      {/* use bollard svg here */}
                      <img src={bollard} alt="" width={20}/>
                      <span class="mr-auto font-bold ml-3 ibm">{item.title}</span>
                      <span
                        className={`transition-transform duration-300 ${
                          activeIndex === index ? '-rotate-90' : ''
                        }`}
                      >
                        <svg 
                        class="rotate-90"
                        width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width="24" height="24" fill=""/>
                          <path d="M17 9.5L12 14.5L7 9.5" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </span>
                    </button>
                    <div
                      ref={(el) => (contentRefs.current[index] = el)}
                      className="overflow-hidden transition-all duration-300 ease-in-out"
                      style={{ height: getContentHeight(index) }}
                    >
                      <div className="ibm px-4 py-3 text-gray-700 bg-white border-t border-gray-300">
                        
                          {item.content}
                        
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              
              </div>
  
  
              
          </Showcase>

          <FooterLanding />


        </div>

  
  );
}















import React, { useState, useEffect, useRef } from 'react';
import { CiSearch } from "react-icons/ci";


function fLCapital(s) {
    let ss = s.replace("_", " ");
    let sss = ss.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
    return sss
}

// sets = {destinations, suppliers, carriers, units}

export default function Searcher({ preset, datasets, onEnter, notify }) {

    const wrapperRef = useRef(null);

    const [userInput, setUserInput] = useState(preset);
    const [activeOption, setActiveOption] = useState(0);
    const [showOptions, setShowOptions] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [optionsCol, setOptionCols] = useState([]);
    const [currentValue, setCurrentValue] = useState("");

    const [searchOption, setSearchOption] = useState("");
    const [searcherOptionsOpen, setSearcherOptionsOpen] = useState(false);  
   
    useEffect(() => {
        //if (preset) {

        //console.log("PRESET: ", preset) 

        setUserInput(preset)
        //}   
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [preset]);


    const regexFilter = (input) => {

        //console.log("INPUT:  ", input);

        let match_obj = {}           
        var regex_word = new RegExp(`${input}(.|)`, "i"); //  `^${input}(.|)` for 'starts with' entry

        //console.log(datasets);

        const dataset_keys = Object.keys(datasets)

        for (let i=0; i<dataset_keys.length; ++i) {
            var matches = datasets[dataset_keys[i]].filter((item) => item.match(regex_word))
            match_obj[`${dataset_keys[i]}`] = matches.sort()
        }

        //var matched_dest = destinations.filter((dest) => dest.match(regex_word));
        //var matched_supp = suppliers.filter((supp) => supp.match(regex_word))
        //var matched_carr = carriers.filter((carr) => carr.match(regex_word))
        //var matched_unit = units.filter((unit) => unit.match(regex_word))

        //match_obj = {
        //    "destination": matched_dest.sort(),
        //    "supplier": matched_supp.sort(),
        //    "carrier": matched_carr.sort(),
        //    "unit": matched_unit.sort(),
        //}

        //console.log("INPUT:  ", input);
        //console.log(match_obj)

        return match_obj; 
    }

    const onChange = (e) => {

        if (e.target.value.includes('"') || e.target.value.includes('`')) {}
        else {
            const filteredOptions = regexFilter(e.target.value);
            let option_groups = Object.keys(filteredOptions)
            //console.log(option_groups); // ["destination", "supplier", "carrier", "unit"]

            //console.log(filteredOptions);

            //let option_cols = [];
            //for (let j=0; j<option_groups.length; ++j) {
            //    if (filteredOptions[`${option_groups[j]}`].length>0) {
            //        option_cols.push(option_groups[j])
            //    }
            //}

            //console.log("USER INPUT: ", userInput);
            //console.log("e.target.value: ", e.target.value);

            //setShowOptions(false)
            
            if (userInput.startsWith('id:')) {
                
                setShowOptions(false)
                
                setUserInput(`id:${e.target.value}`);                
                
            } else {

                setUserInput(e.target.value);
                setOptionCols(option_groups); //option_cols
                setFilteredOptions(filteredOptions);
                setShowOptions(true);   
                setActiveOption(0);
            }                     
            
        }
    }

    const onClick = (event) => {

        //console.log(event)
              
        let group = event.target.getAttribute('name').split('_')[0]
        let target = event.target.getAttribute('name').split('_')[1]

        //console.log(group, target);

        setActiveOption(0);
        setFilteredOptions({});
        setShowOptions(false);
        setUserInput(target);
        setOptionCols([]);

        var filters = [
            //this.props.toggle_status.Inventory,
            //this.props.toggle_status.Pending,
            //this.props.toggle_status.Delivered,
        ]   
        onEnter(group,target,filters)
    }

    const onKeyDown = (e) => {    

        //console.log("KEYCODE: ", e.keyCode)
        
        if (userInput=='id:' && e.keyCode === 8) {           
            setUserInput("")
        }

        // if (e.keyCode === 13) {
        //   //console.log('kkk', filteredOptions.length!=0?false:true)
        //   setActiveOption(0);
        //   setShowOptions(filteredOptions.length!=0?false:true);
        //   setUserInput(filteredOptions[activeOption]);
        // } else if (e.keyCode === 38) { //up arrow
        //   if (activeOption === 0) {
        //     return;
        //   }
        //   setActiveOption(activeOption + 1);
        // } else if (e.keyCode === 40) { // down arrow
        //   if (activeOption === filteredOptions.length - 1) {
        //     //console.log(activeOption);
        //     return;
        //   }
          
        //   setActiveOption(activeOption + 1);
        // } 
    };

    const handleClick = (event) => {
        if (!showOptions) {// && this.wrapperRef.current === event.target ) {            
            return
        }
        else {
            console.log("TARGET: ", event.target);         
            try {
                if (wrapperRef.current.contains(event.target)) {
                    //console.log("YOU'RE INSIDE 1")                
                }  
                else {
                    //handleClickOutside();
                    setShowOptions(false);
                }
            }
            catch {
                console.log("No Target")
                handleClickOutside();
                setShowOptions(false);
            }
        }
    }

    const handleClickOutside = (e) => {
        //console.log("click target", e.target)
        //console.log(wrapperRef.current.contains(e.target))
        if (!wrapperRef.current.contains(e.target)) {
            //console.log("YOU'RE OUTSIDE")
            setShowOptions(false);
        } else {
            //console.log("YOU'RE INSIDE 2")
        }
    }

    const onSubmit = (e) => {
        if(e) e.preventDefault();
        let matches = regexFilter(userInput);
        let value;
        let group;              
        const match_keys = Object.keys(matches)
        let match_list = [];
        let filters = [];   

        if(userInput) {
            if(matches) {
                //console.table("MATCHES", matches);
                if (matches?.destination?.length>0 || matches?.from?.length>0 || matches?.to?.length>0 || matches?.supplier?.length>0 || matches?.carrier?.length>0 || matches?.unit?.length>0) {
                //if (Object.values(matches).every(value => value === 0)) {
                    for (let k=0; k<match_keys.length; ++k) {                       

                        if (matches?.destination?.length>0) {
                            value = matches.destination[0];
                            group = 'destination';
                            break;
                        }

                        if (matches?.from?.length>0) {
                            console.log("=================>", matches.from)
                            value = matches.from[0];
                            group = 'from';
                            break;
                        }

                        //console.log("MATCHES ks", matches[match_keys[k]].length);

                        if (matches[match_keys[k]].length>0) {
                            value = matches[match_keys[k]][0];
                            group = match_keys[k];
                            break;
                        }
                    }
                }
                else {            
                    group = "reference";
                    value = userInput;
                }

                console.log(value)

                setActiveOption(0);
                setFilteredOptions({});
                setShowOptions(false);
                setUserInput(value);
                setOptionCols([]);
                //console.log(group, value);           
                onEnter(group,value,filters)
            } else {

                setActiveOption(0);
                setFilteredOptions({});
                setShowOptions(false);
                setUserInput("");
                setOptionCols([]);
                onEnter("destination","",filters)
            }
        } else {
            //.log("NOTHING WAS ENTERED")
            // IF NOTHING WAS ENTERED IN THE SEARCH
            setShowOptions(false);
            onEnter(undefined,"",filters)
            
        }
    }   

    return (
        <>
            <div class="w-1/3 first-letter:input-group d-none d-sm-flex">         
                <form class="flex shadow-sm rounded-md border border-gray-300 w-100 h-10 focus:border-none" onSubmit={onSubmit}> 
                    {/* <div class="relative inline-block text-left">   THIS IS FOR THE DROPDOWN*/}
                    <div>
                        <button 
                            type="button"
                            //onClick={() => setSearcherOptionsOpen(!searcherOptionsOpen)}
                            class="flex p-2 hover:cursor-pointer">
                            <CiSearch size={22}/>   
                            { userInput.startsWith('id:')?
                                <p 
                                    class="ibm rounded bg-gray-300 ml-2 px-1.5 text-sm"
                                >
                                    ID
                                </p>
                            :null}                           
                            {/* <svg class="-mr-1 size-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                                <path fill-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
                            </svg> */}
                        </button>
                        {/* {searcherOptionsOpen?
                            <div class="absolute p-2 z-10 mt-2 w-32 rounded-md bg-gray-100 shadow-lg focus:outline-hidden" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                                <div class="py-1 max-h-[24rem]" role="none">                                
                                    <div class="flex flex-col space-y-2">
                                        <button 
                                            type="button"
                                            class="flex bg-gray-100 hover:bg-slate-100"
                                            onClick={()=> setSearchOption("")}
                                        >                                        
                                            <p class="ibm text-md align-middle ml-2 my-auto flex">
                                                None
                                                {searchOption==""?
                                                    <svg class="ml-2" width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5 12H20M12 4V20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                :null}
                                            </p>
                                        </button>
                                        <button 
                                            type="button"
                                            class="flex"
                                            onClick={() => setSearchOption("id")}
                                        >                                        
                                            <p class="ibm text-md align-middle ml-2 my-auto flex">
                                                ID
                                                {searchOption==""?
                                                <svg class="ml-2" width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5 12H20M12 4V20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            :null}
                                            </p>
                                        </button>
                                    </div>
                                                                    
                                </div>
                            </div>
                        :null} */}
                        </div>
                    <input 
                        class="h-100 w-100 focus:border-none focus:outline-none"
                        onKeyDown={(e) => onKeyDown(e)}
                        value={
                            userInput.startsWith('id:')?
                            
                                userInput.slice(3)
                            :           
                                userInput
                        } 
                        onChange={onChange} 
                        type="text" 
                        placeholder={                            
                            userInput.startsWith('id:')?                            
                                "Search order ID..."
                            :           
                                "Search orders..."
                        } 
                        ></input>                        
                    <input class="" type="submit" onSubmit={(e) => this.onSubmit(e)} value={currentValue} style={{display:"none"}}/>                    
                </form>                           
            </div>
            <div 
                id="search_options"
                ref={wrapperRef}
                //onClick={handleClick} 
                class="mt-12 absolute z-20 w-4/5 h-1/2 block bg-white border-2 border-gray-700 shadow-xl opacity-100"
                style={showOptions?{display:"block"}:{display:"none"}}                 
            >
                <div class="block h-full">
                    {showOptions?
                        <div 
                            class="w-full h-100 flex"// overflow-y-scroll"
                            id="search_table" 
                        >                        
                        {optionsCol.map((col) => {
                            return(
                                <div id="searchCategory" 
                                     class=" h-100 min-h-full t-0 b-0 flex flex-col w-1/4"
                                    >
                                    <p class="w-100 bg-gray-700 indent-1 text-white font-bold text-md mb-0">{fLCapital(col)}</p>                                    
                                    <div class="h-100 w-100 overflow-y-scroll border-black border" >
                                        {filteredOptions[col].length>0
                                        ?filteredOptions[col].map((optionName, index) => {
                                            let className = 'option-stock';
                                            if (index === activeOption) {
                                                className = 'option-active';
                                            }
                                            return (                                    
                                                <div 
                                                    name={`${col}_${optionName}`}
                                                    class="hover:bg-gray-100 cursor-pointer w-100 indent-2 text-sm"
                                                    key={optionName} 
                                                    onClick={(e) => onClick(e)}
                                                >    
                                                    <span 
                                                        onClick={(e) => onClick(e)} 
                                                        name={`${col}_${optionName}`}
                                                        class=""
                                                    >
                                                            {fLCapital(optionName)}
                                                    </span>
                                                </div>                                        
                                            );
                                        })                                        
                                        :
                                        <div class="w-100 h-100 flex bg-gray-300 justify-center items-center">
                                            <p class="text-gray-500">No results found</p>
                                        </div>}
                                    </div>
                                </div>
                            )
                        })}         
                    </div>
                    :null}                
                </div>
            </div>
        </>
    )
}
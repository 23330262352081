import React, { useRef, useEffect } from 'react';
import { useLocation, Switch, Link } from 'react-router-dom';

import LayoutLanding from '../context/layoutLanding.jsx';

import { FiMapPin, FiCheckCircle, FiPlusCircle, FiSun, FiMoon, FiChevronRight } from 'react-icons/fi';
import { GiNightSky } from 'react-icons/gi';
import { GiTrade } from 'react-icons/gi';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';

// import svg file

import bollard from '../../static/assets/icons/bollard.svg';

import  { ReactComponent as ChevronRight} from '../../static/assets/icons/chevron-right.svg';


export default function LandingTransport() {

  const childRef = useRef();
  let location = useLocation();

  //useEffect(() => {
  //  const page = location.pathname;
  //  document.body.classNameList.add('is-loaded')
  //  childRef.current.init();
  //  trackPage(page);
  //  // eslint-disable-next-line react-hooks/exhaustive-deps
  //}, [location]);

  return (

    <LayoutLanding>
      
        
      <div class="flex flex-col w-100">
        {/* <div class="bg-white py-24 px-12 sm:py-20  w-100 my-auto"> */}

          <div id="class_wrapper" class="gradient-bg relative w-100 mt-20 p-10 py-40 shadow-md">
                <h2 class="ibm my-6 text-5xl tracking-tight text-center text-gray-700">
                Delivery
                </h2>
                {/* <h2 class="w-full text-3xl font-bold text-center sm:text-4xl md:text-5xl">Custom Logistics</h2> */}
                <p class="ibm w-full py-8 mx-auto -mt-2 text-lg text-center text-gray-700 intro sm:max-w-3xl">
                Are you in need of a delivery service that can get your items where they need to be quickly and reliably? 
                Look no further. We offer comprehensive and customizable transportation solutions 
                for any item, big or small and we operate every day of the week to ensure that they arrive at their 
                destination when you need them to.
                </p>
                <Link
                  to="/contact"
                  class="flex hover:text-white h-12 w-40 no-underline rounded-lg bg-gray-0 border-2 border-gray-700 text-gray-700 px-4 hover:bg-gray-700 mx-auto my-auto"
                >
                  <p class="flex my-auto">Get Quote </p>
                  <span class="flex ml-auto">
                    <ChevronRight class="h-5 flex my-auto stroke-gray-700 fill-gray-700 accent-gray-700"/>
                  </span>                  
                </Link>
            </div>

              {/* <header class="mb-9 space-y-1 px-6">
                <p class="font-display text-sm font-medium text-indigo-600 ">WE HAVE AN EXCELLENT TRACK RECORD THROUGHOUT THE INDUSTRY</p>
                <h2 class="mb-8 text-4xl tracking-tight font-extrabold  text-gray-800" style={{color: "url(#blue-gradient)"}}>
                  Transportation
                </h2>
              </header> */}




          <div class="flex flex-col  py-24 h-full mx-auto space-y-2 xl:px-24 w-100 bg-white">
        
                <div class="py-4 w-1/2 mx-auto">
                  <div class="flex">
                    <img class="h-7 my-auto" src={bollard} alt="" width={20}/>
                    <h2 class="ibm ml-2 flex my-6 text-2xl tracking-tight font-extrabold text-gray-700">                  
                      Fast, Flexible and Reliable
                    </h2>
                  </div>
                    <p class="ibm">
                      With our fast and reliable delivery service, you can trust that your items will be delivered safely and efficiently. 
                      Our drivers and logistics team are dedicated to providing the highest quality service possible, 
                      ensuring that your goods are always handled with care and delivered on time. Whether you need to move a small package across 
                      town or transport large, bulky items across provincial lines, we have the expertise and resources to make it happen.
                      <br></br>
                      <br></br>                
                      We work closely with our clients to ensure that their unique requirements are met. From same-day or scheduled deliveries, 
                      we offer a wide range of options to suit your needs.
                      <br></br>
                      <br></br>
                      In addition to our fast and flexible delivery services, we also prioritize customer service and communication. Someone is
                      available any time of day to answer questions or concerns you may have. You can trust that when you choose to work with us, you'll receive 
                      the highest level of service and attention to detail.          
                    </p>
                </div>

                <div class="mx-auto border-gray-200 w-1/2 border-b-2 h-12 mb-6"></div>

                <div class="py-4 w-1/2 mx-auto pt-10">

                  <div class="flex">
                    <img class="h-7 my-auto" src={bollard} alt="" width={20}/>
                    <h2 class="ibm ml-2 flex my-6 text-2xl tracking-tight font-extrabold text-gray-700">                  
                    Comprehensive Delivery Solutions for Businesses
                    </h2>
                  </div>
                
                  <p class="ibm">
                    What is of utmost importance to us is offering a comprehensive delivery service that prioritizes the protection of your items.
                    From delicate equipment to big machinery, we make sure to handle all items with extreme care. No excuses.                
                    <br></br>
                    <br></br>
                    We understand the importance of transparency and communication when it comes to working with our clients.
                    Our delivery services are fully customizable, allowing you to choose exactly 
                    when and where you want your merchandise to be so that you can focus on running your business 
                    while we take care of all the legwork.  
                    <br></br>
                    <br></br>
                    We know that shipping can be a stressful process, which is why we make it our mission to provide 
                    a hassle-free experience for those who trust us with their merchandise. We are all about results.
                  </p>
                </div>

                <div class="mx-auto border-gray-200 w-1/2 border-b-2 h-12 mb-6"></div>

                <div class="py-4 w-1/2 mx-auto pt-10 ">

                  <div class="flex">
                    <img class="h-7 my-auto" src={bollard} alt="" width={20}/>
                    <h2 class="ibm ml-2 flex my-6 text-2xl tracking-tight font-extrabold text-gray-700">                  
                    The Benefits of Choosing our Transport Company
                    </h2>
                  </div>

                    <p class="ibm">
                      We have been hired to provide a variety of services, such as transporting large delicate medical equipment to hospitals,
                      providing courrier services for the marine industry, laundry services, forwarding emergency equipment, 
                      and much more. We have a wide range of experience and are always ready to take on new challenges. 
                      <br></br>
                      <br></br>
                      Data is something that is important for all companies. We are aware of that and we make sure to keep all of our clients 
                      shipping and order data on hand. On our user dashboard you'll have access to all types of metrics and time series datasets 
                      to help analyze your workflow. 
                      
                      {/* Check out our <Link to="/logistics">Logistics page</Link> for more information. */}
                    </p>
                </div>
              </div>

              {/* <div class="p-4">
                <p class="text-center font-bold text-black my-8">
                    We want to hear from you. Get in touch with us to learn more about our B2B transport, warehousing and logistics services. 
                </p>
                
                <div class="w-100 flex mb-6">
                  <Link to="/contact" class="mx-auto inline-flex no-underline items-center justify-center w-full px-6 py-3 mb-2 text-lg text-white bg-gray-800 hover:!bg-gray-900 rounded sm:w-auto sm:mb-0">
                        Contact Us
                        <span class="ml-2">
                          <IoIosArrowForward />
                        </span>                        
                    </Link>
                  </div>
              </div>*/} 
            </div> 
            



    </LayoutLanding>

  );
}

















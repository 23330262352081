import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import useRefreshToken from "../hooks/useRefreshToken";

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const { auth, persist } = useAuth();

  useEffect(() => {
    let isMounted = true;

    const verifyRefreshToken = async () => {
      try {
        console.log("Refreshing token...");
        await refresh();
      } catch (err) {
        console.error("Error refreshing token:", err);
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    /**
     * If the user wants to persist login:
     *  1) If no access token, attempt to refresh it.
     *  2) Otherwise, just set loading to false.
     *
     * If the user does NOT want to persist:
     *  - Immediately set loading to false (no need to refresh).
     */
    if (persist) {
      //console.log("Persisting login...");
      //console.log("Access token:", auth?.accessToken);
      //console.log("############################################")
      if (!auth?.accessToken) {
        verifyRefreshToken();
      } else {
        console.log("Access token exists, no need to refresh.");
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }

    return () => {
      isMounted = false;
    };
  }, [auth?.accessToken, persist, refresh]);

  return (
    <>
      {!persist ? (
        // If not persisting, just render children
        <Outlet />
      ) : isLoading ? (
        // If persisting but still checking/refreshing token, show loading
        <p>Loading...</p>
      ) : (
        // If done checking, render children
        <Outlet />
      )}
    </>
  );
};

export default PersistLogin;

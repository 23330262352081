import React, { useRef, useEffect, useState } from 'react';
import { Navigate, useNavigate, Link } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

import moment from 'moment';

import Layout from '../context/layoutPortal.jsx';
import Popup from '../context/layoutPopup.jsx';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAxiosPrivateBlob from '../hooks/useAxiosPrivateBlob';

import MenuEditJobs from '../modules/menuEditJobs';
import JobCard from '../modules/jobCard';
import Loading from '../modules/loading';
 
import Bill from '../modules/bill';
import BillPersonnel from '../modules/billPersonnel';
import BillPickup from '../modules/billPickup';

import { Checkbox } from '@mui/material';
import { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { BsCalendar2Week, BsX } from 'react-icons/bs';

import { BsDownload } from "react-icons/bs";
import { BsLayoutThreeColumns } from "react-icons/bs";

import { HiOutlineRefresh } from 'react-icons/hi';

import { BsCalendar4Week } from "react-icons/bs";
import { TbProgressCheck } from "react-icons/tb";
import { LuListFilter } from "react-icons/lu";

// import { Dropdown } from 'react-bootstrap';

function arrayAlphebetize(array) {
    return array.sort((a, b) => a.localeCompare(b));
}

function removeDuplicatesFromArray(array) {
    return array.filter((a, b) => array.indexOf(a) === b);
} 

export default function Jobs({ notify }) {

    const date_options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }//, hour: 'numeric', minute: 'numeric', second: 'numeric' };

    const auth = useAuth();
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const axiosPrivateBlob = useAxiosPrivateBlob();

    const monthList = [["01","January"], ["02","February"], ["03","March"], ["04","April"], ["05","May"], ["06","June"], ["07","July"], ["08","August"], ["09","September"], ["10","October"], ["11","November"], ["12","December"]];

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [editData, setEditData] = useState({}); 
    const [billJobData, setBillJobData] = useState({});   
    const [showEditMenu, setShowEditMenu] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [clientList, setClientList] = useState(arrayAlphebetize(Object.keys(auth.auth.divisionMap)));
    const [destinationList, setDestinationList] = useState(arrayAlphebetize(removeDuplicatesFromArray(arrayAlphebetize(Object.values(auth.auth.divisionMap).flatMap((division) => division.split(';')).slice(1)))));
    const [showWorkOrder, setShowWorkOrder] = useState(false);

    const [filterDate, setFilterDate] = useState("");
    const [filterLimit, setFilterLimit] = useState(25);
    const [filterYear, setFilterYear] = useState("All");
    const [filterMonth, setFilterMonth] = useState("All");    
    const [filterClient, setFilterClient] = useState("All");
    const [filterDestination, setFilterDestination] = useState("All");
    const [filterStatus, setFilterStatus] = useState("All");
    const [filterType, setFilterType] = useState("All");

    const [PDFLink, setPDFLink] = useState("");
    const [PDFBlob, setPDFBlob] = useState(null);
    const [showPdfViewer, setShowPdfViewer] = useState(false);

    const [popupMessage, setPopupMessage] = useState([]);
    const [showPopup, setShowPopup] = useState(false);

    const [destinationFilterOpen, setDestinationFilterOpen] = useState(false);
    const [dateFilterOpen, setDateFilterOpen] = useState(false);
    const [typeFilterOpen, setTypeFilterOpen] = useState(false);
    const [statusFilterOpen, setStatusFilterOpen] = useState(false);
    const [clientFilterOpen, setClientFilterOpen] = useState(false);

    const [searchJobId, setSearchJobId] = useState("");

    const dropdownRef = useRef(null);

    useEffect(() => {

        // https://localhost:9001/jobs?id=1738097858

        // parse URL params
        const urlParams = new URLSearchParams(window.location.search);
        const urlJobId = urlParams.get('id');

        if (urlJobId) {

            // console.log("YES THERE IS A URL JOB ID");
            // console.log("THE JOB ID IS: ", urlJobId);
            fetchJobId(urlJobId);
        }
        else {
            fetchJobsData();
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
            
    }, []);

    const uploadFile = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsSelected(true);
    };

    const editJob = (editData) => {
        //console.log(editData);
        try {
            const orders = JSON.parse(editData.orders);
            editData.orders = orders;
        } catch (err) {}
        setEditData(editData);        
        setShowEditMenu(true);        
    }

    const handleDateChange = (e) => {
        //console.log(e);
        setFilterDate(e);
    }

    const fetchJobId = async (jobId) => {
        setIsLoading(true);
        setSearchJobId(jobId);
        const response = await axiosPrivate.get("/jobs/id", {params:{job_id:jobId}});

        console.log(response.data);

        setData(response.data);
        setIsLoading(false); 
    }
   
    const fetchJobsData = async () => {

        setIsLoading(true); 
        
        //console.log("USER HERE---", auth.auth.name)
        
        const params = {       
            user: auth.auth.name,     
            job_id: searchJobId,
            filter_limit: filterLimit,     
            filter_month: filterMonth=="All"?"%":filterMonth,
            filter_year: filterYear=="All"?"%":filterYear,             
            filter_client: filterClient=="All"?"%":filterClient,
            filter_destination: filterDestination=="All"?"%":filterDestination,
            filter_status: filterStatus=="All"?"%":filterStatus,
            filter_type: filterType=="All"?"%":filterType,
        }

        const response = await axiosPrivate.get("/jobs", {params:params}, { method: "GET", credentials: "include" });
        //console.log(response.data);
        setData(response.data);
        setIsLoading(false); 
    
    }

    const renderWorkOrder = async (jobData) => {
        
        //'console.log("JOB DATA HERE---")
        //'console.log(jobData);

        // -------------
        try {
            var parsedOrders = JSON.parse(jobData.orders);            
        } catch {
            var parsedOrders = jobData.orders;
        }

        const jobDataOrders = Object.keys(parsedOrders).map((d) => { return parsedOrders[d] });

        //console.log("JOB DATA ORDERS HERE---")
        //console.log(jobDataOrders);

        const newJobData = { ...jobData, orders:jobDataOrders };

        setBillJobData(newJobData);
        setShowWorkOrder(true);
        
    }

    const pullDeliveryProof = async (jobData) => {

        setShowPopup(true)
        setPopupMessage(["Please wait","Retrieving delivery proof"])        
    
        //console.log(data);
        setIsLoading(true);   
        let id = jobData.id;
        await axiosPrivateBlob.get("/deliveryProof", {params:{id:id}})
        .then(response => {
            const file = new Blob([response.data], { type: "application/pdf" });
            const fileURL = window.URL.createObjectURL(file);
            window.open(fileURL);
            setPDFBlob(file);
            setPDFLink(fileURL);
            setShowPdfViewer(true);
            setIsLoading(false);
            setShowPopup(false);
        })
        .catch(error => {
            console.log(error);
            setIsLoading(false);
            setShowPopup(false);
        });        
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const urlJobId = urlParams.get('id');

        if (urlJobId) {

            console.log("YES THERE IS A URL JOB ID");
            console.log("THE JOB ID IS: ", urlJobId);
            fetchJobId(urlJobId);
        }
        else {
            fetchJobsData();
        }      
    }, [filterYear, filterMonth, filterClient, filterDestination, filterType, filterStatus])

    const handleClickOutside = (event) => {
        // Check if the click is outside the dropdown
        console.log(event.target.value)
        console.log(event.target.name)
        if (!event.target.value ) {
            setStatusFilterOpen(false);
            setDateFilterOpen(false);
            setDestinationFilterOpen(false);
            setClientFilterOpen(false);
            setTypeFilterOpen(false);
        }
    }


    return (          

            <Layout>

                <div class="flex w-2/3 space-x-4 pb-3">
                    <h1 to="/orders" class="ibm text-3xl font-bold mb-0 text-black ">Jobs</h1>
                </div>

                <div class="w-2/3">
                    <p>
                        A list of past and currently scheduled jobs. 
                        Work orders and delivery proofs can be viewed from the job cards.
                    </p>
                </div>


                <div class="w-100 flex pt-2 pb-2 border-t-2 border-gray-200 border-b-2 ">

                    <div class="flex mr-auto space-x-2">



                        {isLoading?
                            <button 
                                onClick={null}
                                class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-900 ring-1 shadow-xs ring-gray-300 ring-inset hover:bg-gray-50" id="menu-button" aria-expanded="true" aria-haspopup="true"
                            >
                                <span class=" p-1 my-auto">
                                    <Loading type={1} size={16} />
                                </span>
                                <span class="mr-1 my-auto">
                                    Update 
                                </span>
                            </button>
                            : 
                            <button 
                                onClick={fetchJobsData}
                                class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-900 ring-1 shadow-xs ring-gray-300 ring-inset hover:bg-gray-50" id="menu-button" aria-expanded="true" aria-haspopup="true"
                            >                        
                                <span class="p-1 my-auto">
                                    <HiOutlineRefresh size={16} color={"inherit"}/>
                                </span>
                                <span class="mr-1 my-auto">
                                    Update 
                                </span>
                            </button>
                            }



                        {/* DESTINATION  */}
                        <div
                            ref={dropdownRef}
                            class="relative inline-block text-left">
                            <div>
                                <button type="button" 
                                    onClick={() => setDestinationFilterOpen(!destinationFilterOpen)}
                                    //class="overflow-hidden inline-flex w-full max-w-[16rem] justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-900 ring-1 shadow-xs ring-gray-300 ring-inset hover:bg-gray-50" id="menu-button" aria-expanded="true" aria-haspopup="true"
                                    class="inline-flex w-full max-w-[16rem] justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-900 ring-1 shadow-xs ring-gray-300 ring-inset hover:bg-gray-50" id="menu-button" aria-expanded="true" aria-haspopup="true"
                                >
                                    <p class="mb-0 p-1">Destination</p>
                                    <p class="font-bold mb-0 mt-1 whitespace-nowrap overflow-hidden text-ellipsis">                                    
                                        {filterDestination}
                                    </p>
                                    <svg class="-mr-1 my-auto size-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                                        <path fill-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
                                    </svg>
                                </button>                        
                            </div>
                            {destinationFilterOpen?
                                <div class="absolute left-0 z-10 mt-2 w-[400px] origin-top-right rounded-md bg-white ring-1 shadow-lg ring-black/5 focus:outline-hidden" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                                    <div class="py-1 overflow-y-scroll max-h-[24rem]" role="none">                                
                                        <div class="flex flex-row">
                                            <Checkbox onClick={() => setFilterDestination("All")} checked={filterDestination=="All"}/>
                                            <p class="ibm text-md align-middle ml-2 my-auto flex">All</p>
                                        </div>
                                        <hr class="border-gray-300"/>
                                        {destinationList.map((destination) => (
                                            <div class="flex flex-row">
                                                <Checkbox onClick={() => setFilterDestination(destination)} checked={filterDestination==destination}/>
                                                <p class="ibm text-md align-middle ml-2 my-auto flex">{destination}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            :null}

                        

                            
                            
                            {/* <div class="w-full">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                    Destination
                                </label>
                                <select onChange={(e) => setFilterDestination(e.target.value)}                                     
                                    class="shadow w-100 bg-white text-gray-700 rounded-md h-9 border-2 border-gray-500 py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Jane" >
                                    <option value="All">All</option>
                                    <optgroup class="w-100 bg-gray-300 h-10" label="&#xA0;"></optgroup>
                                    {destinationList.slice(1).map((destination, index) => {                                
                                        return (
                                            <option value={destination}>{destination}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            
                            <div class="w-full">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                    Limit
                                </label>
                                <select 
                                    onChange={(e) => setFilterLimit(e.target.value)} 
                                    class="shadow w-100 bg-white text-gray-700 rounded-md h-9 border-2 border-gray-500 py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Jane" >
                                    <option value="25" selected>25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div> */}

                        </div>

                        {/* CLIENT */}
                        {auth.auth.username=="admin"?
                            <div 
                                ref={dropdownRef}
                                class="relative text-left"
                            >
                                <div>
                                        <button type="button" 
                                            onClick={() => setClientFilterOpen(!clientFilterOpen)}
                                            //class="overflow-hidden inline-flex w-full max-w-[16rem] justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-900 ring-1 shadow-xs ring-gray-300 ring-inset hover:bg-gray-50" id="menu-button" aria-expanded="true" aria-haspopup="true"
                                            class="inline-flex w-full max-w-[16rem] justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-900 ring-1 shadow-xs ring-red-300 ring-inset hover:bg-red-50" id="menu-button" aria-expanded="true" aria-haspopup="true"
                                        >
                                            <p class="mb-0 p-1 text-red-500">Client</p>
                                            <p class="font-bold mb-0 mt-1 whitespace-nowrap overflow-hidden text-ellipsis text-red-500">                                    
                                                {filterClient}
                                            </p>
                                            <svg class="-mr-1 my-auto size-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                                                <path fill-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
                                            </svg>
                                        </button>                        
                                    </div>
                                {clientFilterOpen?
                                    <div class="absolute left-0 z-10 mt-2 w-[400px] origin-top-right rounded-md bg-white ring-1 shadow-lg ring-black/5 focus:outline-hidden" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                                        <div class="py-1 overflow-y-scroll max-h-[24rem]" role="none">                                
                                            <div class="flex flex-row">
                                                <Checkbox onClick={() => setFilterClient("All")} checked={filterClient=="All"}/>
                                                <p class="ibm text-md align-middle ml-2 my-auto flex">All</p>
                                            </div>
                                            <hr class="border-gray-300"/>
                                            {clientList.map((client, index) => (
                                                <div class="flex flex-row">
                                                    <Checkbox onClick={() => setFilterClient(client)} checked={filterClient==client}/>
                                                    <p class="ibm text-md align-middle ml-2 my-auto flex">{client}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                :null}

                            </div>
                        :null}


                            {/* {auth.auth.username=="admin"?
                                <div class="mr-auto ml-4 mt-3 w-48">
                                    <select onChange={(e) => setFilterClient(e.target.value)} 
                                        class="shadow w-100 bg-white text-gray-700 rounded-md h-9 border-2 border-gray-500 py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Jane" >
                                        <option value="All">All</option>
                                        <optgroup class="w-100 bg-gray-300 h-10" label="&#xA0;"></optgroup>
                                        {clientList.map((client, index) => {
                                            if (client!="admin"){
                                                return (
                                                    <option value={client}>{client}</option>
                                                )
                                            }
                                        })}                            

                                    </select>
                                </div>
                            :null} */}

                    </div>                    
                    
                    <div class="flex ml-auto space-x-3">     
                          
                        {/* DATE */}
                        <div 
                            ref={dropdownRef}
                            class="relative inline-block text-left">
                            <div>
                                <button type="button" 
                                    onClick={() => setDateFilterOpen(!dateFilterOpen)}
                                    class="inline-flex w-full max-w-[16rem] justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-900 ring-1 shadow-xs ring-gray-300 ring-inset hover:bg-gray-50" id="menu-button" aria-expanded="true" aria-haspopup="true">
                                    <span class="p-1  my-auto">                                    
                                        <BsCalendar4Week size={15}/>
                                    </span>
                                    <p class="mb-0 p-1 my-auto">Date</p>
                                    
                                    <svg class="-mr-1 my-auto size-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                                        <path fill-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
                                    </svg>
                                </button>                        
                            </div>
                            {dateFilterOpen?
                                <div class="absolute p-6 overflow-y-scroll left-0 z-10 mt-2 w-68 origin-top-right rounded-xl bg-white ring-1 shadow-lg ring-black/5 focus:outline-hidden" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">

                                    <div class="flex max-h-[24rem] space-x-8" role="none">     

                                        <div class="flex flex-col p-3">                          
                                            <div class="flex flex-row">
                                                <Checkbox onClick={() => setFilterYear("All")} checked={filterYear=="All"}/>
                                                <p class="ibm text-md align-middle ml-2 my-auto flex">All</p>
                                            </div>                                        
                                            <hr class="border-gray-300 my-2"/>
                                            <div class="flex flex-row">
                                                <Checkbox onClick={() => setFilterYear("2025")} checked={filterYear=="2025"}/>
                                                <p class="ibm text-md align-middle ml-2 my-auto flex">2025</p>
                                            </div>
                                            <div class="flex flex-row">
                                                <Checkbox onClick={() => setFilterYear("2024")} checked={filterYear=="2024"}/>
                                                <p class="ibm text-md align-middle ml-2 my-auto flex">2024</p>
                                            </div>
                                            <div class="flex flex-row">
                                                <Checkbox onClick={() => setFilterYear("2023")} checked={filterYear=="2023"}/>
                                                <p class="ibm text-md align-middle ml-2 my-auto flex">2023</p>
                                            </div>
                                            <div class="flex flex-row">
                                                <Checkbox onClick={() => setFilterYear("2022")} checked={filterYear=="2022"}/>
                                                <p class="ibm text-md align-middle ml-2 my-auto flex">2022</p>
                                            </div>
                                        </div>

                                        <div class="flex p-3 flex-col border-gray-600">
                                            <div class="flex flex-row">
                                                <Checkbox onClick={() => setFilterMonth("All")} checked={filterMonth=="All"}/>
                                                <p class="ibm text-md align-middle ml-2 my-auto flex">All</p>
                                            </div>
                                            <hr class="border-gray-300 my-2"/>

                                            {monthList.map((month, index) => {
                                                return (

                                                    <div class="flex flex-row">
                                                        <Checkbox onClick={() => setFilterMonth(month[0])} checked={filterMonth==month[0]}/>
                                                        <p class="ibm text-md align-middle ml-2 my-auto flex">{month[1]}</p>
                                                    </div>                                                    
                                                )
                                            })}
                                        </div>                                        

                                    
                                            {/* onChange={(e) => setFilterMonth(e.target.value)} 

                                            <option value="All">All</option>
                                            <optgroup class="w-100 bg-gray-300 h-10" label="&#xA0;"></optgroup>
                                            {monthList.map((month, index) => {
                                                return (
                                                    <option value={month[0]}>{month[1]}</option>
                                                )
                                            })} */}
                                    
                                    </div>

                                </div>
                            :null}
                        </div>

                        {/* STATUS */}
                        <div 
                            ref={dropdownRef}
                            class="relative inline-block text-left">
                            <div>
                                <button type="button" 
                                    onClick={() => setStatusFilterOpen(!statusFilterOpen)}
                                    class="inline-flex w-full max-w-[16rem] justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-900 ring-1 shadow-xs ring-gray-300 ring-inset hover:bg-gray-50" id="menu-button" aria-expanded="true" aria-haspopup="true"
                                >
                                    <span class="p-1 my-auto">                                    
                                        <TbProgressCheck size={16}/>
                                    </span>
                                    <p class="mb-0 p-1">Status</p>
                                    
                                    <svg class="-mr-1 my-auto size-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                                        <path fill-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
                                    </svg>

                                </button>                        
                            </div>
                            
                            {statusFilterOpen?
                                <div class="absolute left-0 z-10 mt-2 w-44 origin-top-right rounded-xl bg-white ring-1 shadow-lg ring-black/5 focus:outline-hidden" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                                    <div class="py-2 p-2" role="none">                                                                        
                                        
                                        <div class="flex flex-row">
                                            <Checkbox onClick={(e) => setFilterStatus("All")} checked={filterStatus=="All"}/>
                                            <p class="ibm text-md align-middle ml-2 my-auto flex">All</p>
                                        </div>
                                        <hr class="border-gray-300 my-2"/>                                        
                                        <div class="flex flex-row">
                                            <Checkbox onClick={() => setFilterStatus("Completed")} checked={filterStatus=="Completed"}/>
                                            <p class="ibm text-md align-middle ml-2 my-auto flex">Completed</p>
                                        </div>
                                        <div class="flex flex-row">
                                            <Checkbox onClick={() => setFilterStatus("Pending")} checked={filterStatus=="Pending"}/>
                                            <p class="ibm text-md align-middle ml-2 my-auto flex">Pending</p>
                                        </div>
                                        <div class="flex flex-row">
                                            <Checkbox onClick={() => setFilterStatus("Cancelled")} checked={filterStatus=="Cancelled"}/>
                                            <p class="ibm text-md align-middle ml-2 my-auto flex">Cancelled</p>
                                        </div>   
                                    </div>
                                </div>
                            :null}
                        </div>

                        {/* TYPE */}
                        <div 
                            ref={dropdownRef}
                            class="relative inline-block text-left">
                            <div>
                                <button type="button" 
                                    onClick={() => setTypeFilterOpen(!typeFilterOpen)}
                                    class="inline-flex w-full max-w-[16rem] justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-900 ring-1 shadow-xs ring-gray-300 ring-inset hover:bg-gray-50" id="menu-button" aria-expanded="true" aria-haspopup="true"
                                >
                                    <span class="p-1 my-auto">                                    
                                        <LuListFilter size={16}/>
                                    </span>
                                    <p class="mb-0 p-1">Type</p>
                                    
                                    <svg class="-mr-1 my-auto size-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                                        <path fill-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
                                    </svg>

                                </button>                        
                            </div>
                            {typeFilterOpen?
                                <div class="absolute z-10 mt-2 w-[12rem] right-1 rounded-xl bg-white ring-1 shadow-lg ring-black/5 focus:outline-hidden" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                                    <div class="py-1 p-3" role="none">                                     
                                        
                                        <div class="flex flex-row">
                                            <Checkbox onClick={(e) => setFilterType("All")} checked={filterType=="All"}/>
                                            <p class="ibm text-md align-middle ml-2 my-auto flex">All</p>
                                        </div>

                                        <hr class="border-gray-300 my-2"/>                                                                     
                                        
                                        <div class="flex flex-row">
                                            <Checkbox onClick={(e) => setFilterType(1)} checked={filterType==1}/>
                                            <p class="ibm text-md align-middle ml-2 my-auto flex">Order</p>
                                        </div>
                                        <div class="flex flex-row">
                                            <Checkbox onClick={(e) => setFilterType(4)} checked={filterType==4}/>
                                            <p class="ibm text-md align-middle ml-2 my-auto flex">Outgoing</p>
                                        </div>
                                        <div class="flex flex-row">
                                            <Checkbox onClick={(e) => setFilterType(3)} checked={filterType==3}/>
                                            <p class="ibm text-md align-middle ml-2 my-auto flex">Pickup</p>
                                        </div>
                                        <div class="flex flex-row">
                                            <Checkbox onClick={(e) => setFilterType(2)} checked={filterType==2}/>
                                            <p class="ibm text-md align-middle ml-2 my-auto flex">Personnel</p>
                                        </div>

                                        {/* <select 
                                            onChange={(e) => setFilterType(e.target.value)} 
                                            class="shadow w-100 bg-white text-gray-700 rounded-md h-9 border-2 border-gray-500 py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Jane" >
                                            <option value="All">All</option>
                                            <optgroup class="w-100 bg-gray-300 h-10" label="&#xA0;"></optgroup>
                                            <option value="1">Orders</option>
                                            <option value="4">Outgoing</option>
                                            <option value="3">Pickup</option>
                                            <option value="2">Personnel</option>
                                        </select> */}
  
                                    </div>
                                </div>
                            :null}
                        </div>

                        {/* <div class="border-l border-gray-300 h-6 my-auto"></div>

                        <button 
                            onClick={null}
                            class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-slate-200 px-3 py-2 text-sm text-gray-900 ring-1 shadow-xs ring-slate-200 ring-inset hover:bg-slate-300" id="menu-button" aria-expanded="true" aria-haspopup="true">                        
                            <span class="p-1 mr-1">
                                <BsDownload size={16} />
                            </span>
                            <span class="my-auto">
                                Download
                            </span>
                        </button> */}

                    </div>
                </div> 

                <div class="flex flex-col overflow-auto">
                    {showEditMenu 
                    ? <MenuEditJobs
                        data={editData} 
                        divisionMap={auth.auth.divisionMap} 
                        onToggleDisplay={setShowEditMenu}
                        uploadFile={uploadFile}
                        updateParent={fetchJobsData}
                        notify={notify}
                    />                      
                    : null} 
                    
                    {showPopup
                    ? <Popup>
                        <div 
                            id="innerEditMenu" 
                            class="h-44 w-1/4 inline-block bg-white rounded-md border-slate-500 border-2 overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle" 
                            role="dialog" 
                            aria-modal="true" 
                            aria-labelledby="modal-title"
                        >
                            
                            <div class="max-h-12 w-100 px-6 mt-4 flex flex-col">
                                <h2 class="ibm text-black w-100 mt-3">{popupMessage[0]}</h2>       
                                <h3 id="loading" class="ibm !text-slate-400 text-md !bg-white mt-2">{popupMessage[1]}</h3>
                            </div>                        
                                
                        </div>
                    </Popup>                
                    :null}

                    {showWorkOrder
                    ?   <Popup>
                            {/* close button for popup */}
                            <div class="flex absolute top-5 right-5">
                                <button onClick={() => setShowWorkOrder(false)} class="h-10 w-10 bg-gray-200 hover:bg-gray-300 rounded-full flex m-2">
                                    <BsX class="m-auto" size={25}/>
                                </button>
                            </div>
                            
                            <div id="innerEditMenu" class="h-4/5 pb-0 w-3/4 inline-block bg-gray-200 rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle" role="dialog" aria-modal="true" aria-labelledby="modal-title">
                                {billJobData.type==1?                                
                                    <Bill 
                                        reference={String(billJobData?.id)}
                                        date={new Date(billJobData?.start_datetime).toLocaleString('en-US',date_options).replace(" at",";")}                                        
                                        //moment(billJobData?.start_datetime).format('YYYY-MM-DD')}
                                        orders={billJobData?.orders} 
                                        client={billJobData?.client}
                                        destination={billJobData?.destination} 
                                        location={billJobData?.location}
                                        service={billJobData?.service}
                                        clientReference={billJobData?.clientRef}
                                        employee={billJobData?.employee}
                                    />
                                :billJobData.type==2?
                                    <BillPersonnel
                                        reference={String(billJobData?.id)}
                                        date={new Date(billJobData?.start_datetime).toLocaleString('en-US',date_options).replace(" at",";")}                                        
                                        //moment(billJobData?.start_datetime).format('YYYY-MM-DD')}
                                        orders={billJobData?.orders} 
                                        client={billJobData?.client}
                                        destination={billJobData?.destination} 
                                        location={billJobData?.location}
                                        service={billJobData?.service}
                                        clientReference={billJobData?.clientRef}
                                        employee={billJobData?.employee}
                                    />
                                :billJobData.type==3?
                                    <BillPickup
                                        reference={String(billJobData?.id)}
                                        date={new Date(billJobData?.start_datetime).toLocaleString('en-US',date_options).replace(" at",";")}                                                                                
                                        orders={billJobData?.orders} 
                                        client={billJobData?.client}
                                        destination={billJobData?.destination} 
                                        location={billJobData?.location}
                                        service={billJobData?.service}
                                        clientReference={billJobData?.clientRef}
                                        employee={billJobData?.employee}
                                        pickedUp={billJobData?.pickedUp}
                                        pickedUpLocation={billJobData?.pickedUpLocation}
                                    />
                                :billJobData.type==4?
                                    <Bill 
                                        reference={String(billJobData?.id)}
                                        date={new Date(billJobData?.start_datetime).toLocaleString('en-US',date_options).replace(" at",";")}                                        
                                        //moment(billJobData?.start_datetime).format('YYYY-MM-DD')}
                                        orders={billJobData?.orders} 
                                        client={billJobData?.client}
                                        destination={billJobData?.destination} 
                                        location={billJobData?.location}
                                        service={billJobData?.service}
                                        clientReference={billJobData?.clientRef}
                                        employee={billJobData?.employee}
                                    />
                                :null}
                            </div>
                        </Popup>
                    : null}
                </div>

                <div class="flex flex-col w-100 h-[calc(100%-12rem)] mt-4">
                    {/* <button onClick={testFetch} class="bg-black text-white">FETCH JOBS</button> */}
                    <div class="overflow-auto ">
                        {data.length>0?
                            data.map((d) => {
                                return (
                                <JobCard 
                                    user={auth.auth.username}
                                    data={d}
                                    editFunc={editJob}
                                    renderWorkOrder={renderWorkOrder}    
                                    pullFile={pullDeliveryProof}                            
                                />
                                )
                            })
                        :
                        isLoading?
                            <section className="flex items-center h-full p-16 relative w-100">
                            <div className="container w-100 flex flex-col items-center justify-center px-5 mx-auto my-20">
                                <div className="max-w-md text-center">
                                    <h2 className="ibm mb-8 font-extrabold text-5xl dark:text-gray-600">
                                        <span className="sr-only font-bold"></span>LOADING
                                    </h2>
                                    <p className="ibm text-2xl font-semibold md:text-3xl">Searching for jobs...</p>
                                    {/* <p className="mt-4 mb-8 dark:text-gray-400">But dont worry, you can find plenty of other things on our homepage.</p> */}
                                    <div className="max-w-md text-center h-4 w-100 pt-4 px-12">
                                        <Loading type={3} class="w-100 h-32"/>
                                    </div>
                                </div>
                            </div>
                            </section>
                            :
                            <section className="flex items-center h-full p-16 relative w-100">
                            <div className="container w-100 flex flex-col items-center justify-center px-5 mx-auto my-20">                          
                                <div className="max-w-md text-center">                              
                                    <h2 className="ibm mb-4 font-extrabold text-5xl text-gray-600">                                  
                                        <span className="sr-only">Error</span>NO DATA
                                    </h2>
                                    <p className="ibm text-gray-600 text-2xl font-semibold md:text-3xl">No data available for this search.</p>
                                    
                                    {/* <p className="mt-4 mb-8 dark:text-gray-400">But dont worry, you can find plenty of other things on our homepage.</p> */}
                                </div>
                            </div>
                            </section>
                        
                        }   
                    </div>
                </div>                   
            </Layout>
        
    )
}
import React, { useRef, useEffect } from 'react';
import { useLocation, Switch, Link } from 'react-router-dom';

import info_pic from '../../static/assets/images/info-hp.png';

import LayoutLanding from '../context/layoutLanding';

// import forward arrow icon
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';

export default function LandingInfo() {

  const childRef = useRef();
  let location = useLocation();

  //useEffect(() => {
  //  const page = location.pathname;
  //  document.body.classNameList.add('is-loaded')
  //  childRef.current.init();
  //  trackPage(page);
  //  // eslint-disable-next-line react-hooks/exhaustive-deps
  //}, [location]);

  return (
    <LayoutLanding>

        <div class="w-100 ">
            <div class="min-h-screen rounded-xl flex w-100 px-20 py-20">

              <div class="w-1/3 h-full bg-white mx-auto my-auto text-left p-6 md:text-center">
                  {/* <h1 class="mb-8 text-4xl font-extrabold leading-none tracking-normal text-gray-900 md:text-6xl md:tracking-tight">
                      <span>LDF </span> 
                      <span class="block w-full py-2 text-transparent bg-clip-text leading-12 bg-gradient-to-r from-green-400 to-purple-500 lg:inline">
                        building a buzz
                      </span> 
                      <span>
                        around your product ?
                      </span>
                  </h1> */}

                  <div class="flex-col flex my-auto p-6">
                      <h1 class="ibm text-left my-8 font-bold tracking-tight text-gray-700">
                        Get a quote.
                      </h1>
                      {/* <p class="text-left mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        Get a quote
                      </p> */}

                      <div class="">
                          <p class="ibm px-0 mb-8 text-md text-gray-600 md:text-md lg:px-24 text-left">
                            For almost 40 years we've been moving people and merchandise to and from thousands of locations in Canada.
                            LDF provides its services to businesses in multiple industries and handles all types
                            of transportation needs.                     
                          </p>
                          <p class="ibm px-0 mb-8 text-md text-gray-600 md:text-md lg:px-24 text-left">
                              We offer a full service for warehousing, forwarding and inventory management
                              as well as handling shipping, direct delivery and general logistics.
                              We are setup to handle deliveries to specialized locations such as ports, airports and more.
                              Contact us about your needs and we will be happy to provide you with a quote.
                          </p>

                          <p class=" ibm px-0 mb-8 text-red-400 text-md lg:px-24 text-left ">
                              * At this time account creation is only available to our clients.
                              To get started please contact us directly.
                          </p>
                          <div class="mb-4 space-x-0 md:space-x-2 md:mb-8">
                              <Link to="/contact" class="inline-flex no-underline items-center justify-center w-full px-6 py-3 mb-2 text-lg text-gray-700 border-2 border-gray-700  hover:!bg-gray-800 rounded sm:w-auto sm:mb-0 hover:text-white transition duration-300 ease-in-out">
                                  Contact Us
                                  <span class="ml-2">
                                    <IoIosArrowForward />
                                  </span>
                              </Link>
                          </div>
                      </div>
                  </div>
              </div>

              <div class="w-2/3 h-full p-16 mx-auto my-auto md:w-10/12">
                  <div class="relative z-0 w-full mt-8">
                      <div class="relative overflow-hidden shadow-2xl rounded-3xl border2 border-gray-500">                                                    
                          <img
                            class="w-100 h-100 rounded-3xl opacity-1"
                            src={
                                require('../../static/assets/images/showcase1.webp')
                            } 
                            alt=""
                          />
                      </div>
                  </div>
              </div>

          </div>
        </div>
      
    </LayoutLanding>
  );
}

















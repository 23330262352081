import axios from 'axios';

const env = process.env.NODE_ENV;

//const BASE_URL = env=='dev' ? 'https://localhost:8443' : 'https://locksanddocks.com:8443';
//const BASE_URL = "https://localhost:8443";
const BASE_URL = "https://locksanddocks.com:8443";

export default axios.create({
    baseURL: BASE_URL
});

// For private routes
export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
    credentials: 'include'
});

// For downloading files
export const axiosPrivateBlob = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
    credentials: 'include',
    responseType: 'blob'
});







import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Route, Routes, Switch } from 'react-router-dom';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

import useAuth from './components/hooks/useAuth';

import LandingWarehousing from './components/pages/warehousing.jsx';
import LandingTransport from './components/pages/transport.jsx';
import LandingMission from './components/pages/mission.jsx';
import LandingLogistics from './components/pages/logistics';
import LandingDelivery from './components/pages/delivery.jsx';
import LandingAbout from './components/pages/about.jsx';
import LandingContact from './components/pages/contact.jsx';
import LandingEmployment from './components/pages/employment.jsx';
import LandingInfo from './components/pages/info.jsx';

import Home from './components/pages/home.jsx';
import Login from './components/views/login.jsx';
import Dashboard from './components/views/dashboard.jsx';
import Orders from './components/views/orders.jsx';
import Jobs from './components/views/jobs.jsx';
import Profile from './components/views/profile.jsx';
import Registration from './components/views/registration.jsx';
import Data from './components/views/data.jsx';
import Help from './components/views/help.jsx';
import Missing from './components/pages/missing.jsx';

import Form1 from './components/views/form1.jsx';
import Form2 from './components/views/form2.jsx';
import Form3 from './components/views/form3.jsx';
import Tools from './components/views/tools.jsx';
import Settings from './components/views/settings.jsx';
import Status from './components/views/status.jsx';
import Inventory from './components/views/inventory.jsx';
import Outgoing from './components/views/outgoing.jsx';

import PersistLogin from './components/context/persistLogin';
import RequireAuth from './components/context/requireAuth';
import RequireAdmin from './components/context/requireAdmin';

export default function App() {

  // MOVE TO LOWER LEVEL MAYBE
  const [aggDestinations, setAggDestinations] = useState(null);

  const notify = (msg, flag) => {  
      toast.clearWaitingQueue();  
      if (flag=="info") toast.info(msg);// { style:{color:"white", backgroundColor:"black"}, autoClose: 3000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, }); break;
      if (flag=="success") toast.success(msg);
      if (flag=="warning") toast.warning(msg);
      if (flag=="error") toast.error(msg, { autoClose: false,  });
  }

  const auth = useAuth();

  useEffect(() => {

    // database check on refresh token when access token expires


    //console.log("CHECKING AUTH ----------")
    //console.log(auth);
    //console.log("CHECKED")
  }, []);

  return (
    <div className="App"> 

      <Routes>

        {/* PUBLIC ROUTES */}
        <Route path="/" element={<Home notify={notify}/>} />    
        
        <Route path="/warehousing" element={<LandingWarehousing notify={notify}/>} />
        <Route path="/transport" element={<LandingTransport notify={notify}/>} />
        <Route path="/mission" element={<LandingMission notify={notify}/>} />
        {/* <Route path="/logistics" element={<LandingLogistics notify={notify}/>} /> */}
        <Route path="/delivery" element={<LandingDelivery notify={notify}/>} />
        <Route path="/about" element={<LandingAbout notify={notify}/>} />
        <Route path="/contact" element={<LandingContact notify={notify}/>} /> 
        {/* <Route path="/contact/apply" element={<LandingContact notify={notify}/>} /> */}
        {/* <Route path="/employment" element={<LandingEmployment notify={notify}/>} /> */}
        <Route path="/info" element={<LandingInfo notify={notify}/>} />

        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth />}>

            <Route path="/login" element={<Login notify={notify} isLogged />} />

            {/* USER */}
            <Route path="/dashboard" element={<Dashboard notify={notify} />} />
            <Route path="/orders" element={<Orders notify={notify} />} />
            <Route path="/jobs" element={<Jobs notify={notify} />} />
            <Route path="/registration" element={<Registration notify={notify}/>} />
            <Route path='/inventory' element={<Inventory notify={notify}/>} />
            <Route path="/profile" element={<Profile notify={notify}/>} />
            <Route path='/data' element={<Data notify={notify}/>} />
            <Route path="/outgoing" element={<Outgoing notify={notify}/>} />  
            <Route path="/help" element={<Help notify={notify}/>} />

            {/* ADMIN */}
            
              <Route element={<RequireAdmin />}>
                <Route path="/addMerchandise" element={<Form1 notify={notify}/>} />
                <Route path="/pickupForm" element={<Form3 notify={notify}/>} />
                <Route path="/newWorkOrder" element={<Form2 notify={notify}/>} />
                <Route path="/adminTools" element={<Tools notify={notify}/>} />
                {/* <Route path="/settings" element={<Settings notify={notify}/>} /> */}
                <Route path="/status" element={<Status notify={notify}/>} />                                      
              </Route>            

          </Route>
        </Route>

        {/* handle_access={this.handle_access}  */}
        {/* user_data={this.handle_user_data} */}
        {/* verify_access={this.verify_access} */}
        {/* fetch_client_list={this.fetch_client_list} */}
        {/* display_message={this.display_message}  */}

        {/* CATCH ALL ROUTES */}
        <Route path="*" element={<Missing notify={notify}/>} />

      </Routes>

      <ToastContainer 
        position="top-center"
        autoClose={2000}
        limit={2}
        //hideProgressBar={false}
        //newestOnTop={false}
        closeOnClick={true}
        //rtl={false}
        //pauseOnFocusLoss
        //draggable
        //pauseOnHover
        //theme="dark"
      />

    </div>
  );
}

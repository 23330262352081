import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';

const BarGraph = ({ data }) => {
  const svgRef = useRef();
  const wrapperRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    // Set up a resize observer to track the size of the wrapper div
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries.length === 0) return;
      const { width, height } = entries[0].contentRect;
      setDimensions({ width, height });
    });

    if (wrapperRef.current) {
      resizeObserver.observe(wrapperRef.current);
    }

    return () => {
      if (wrapperRef.current) {
        resizeObserver.unobserve(wrapperRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!data || data.length === 0 || dimensions.width === 0 || dimensions.height === 0) return;

    const { width, height } = dimensions;
    const margin = { top: 30, right: 30, bottom: 50, left: 50 };

    const svg = d3
      .select(svgRef.current)
      .attr('viewBox', `0 0 ${width} ${height}`)
      .attr('preserveAspectRatio', 'none');

    svg.selectAll('*').remove();

    // Set scales
    const xScale = d3
      .scaleBand()
      .domain(data.map((d) => d.date))
      .range([margin.left, width - margin.right])
      .padding(0.2);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.value)])
      .range([height - margin.bottom, margin.top]);

    // Draw X-axis
    svg
      .append('g')
      .attr('transform', `translate(0, ${height - margin.bottom})`)
      .call(d3.axisBottom(xScale).tickFormat(d3.timeFormat('%b %d')))
      .selectAll('text')
      .attr('transform', 'rotate(-45)')
      .style('text-anchor', 'end');

    // Draw Y-axis
    svg
      .append('g')
      .attr('transform', `translate(${margin.left}, 0)`)
      .call(d3.axisLeft(yScale));

    // Draw many gridlines
    const numGridlines = 20;
    const yGridlines = d3.ticks(0, d3.max(data, (d) => d.value), numGridlines);

    // draw horizontal gridlines
    svg
      .append('g')
      .attr('class', 'grid')
      .selectAll('line')
      .data(yGridlines)
      .enter()
      .append('line')
      .attr('x1', margin.left)
      .attr('x2', width - margin.right)
      .attr('y1', (d) => yScale(d))
      .attr('y2', (d) => yScale(d))
      .attr('stroke', '#eaeaea')
      .attr('stroke-width', 0.8)
      //.attr('stroke-dasharray', '3,3');

    // Draw vertical gridlines
    svg
      .append('g')
      .attr('class', 'grid')
      .selectAll('line')
      .data(data)
      .enter()
      .append('line')
      .attr('x1', (d) => xScale(d.date) + xScale.bandwidth() / 2)
      .attr('x2', (d) => xScale(d.date) + xScale.bandwidth() / 2)
      .attr('y1', margin.top)
      .attr('y2', height - margin.bottom)
      .attr('stroke', '#eaeaea')
      .attr('stroke-width', 0.8)
      //.attr('stroke-dasharray', '3,3');

    // Draw bars
    svg
      .selectAll('.bar')
      .data(data)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', (d) => xScale(d.date))
      .attr('y', (d) => yScale(d.value))
      .attr('width', xScale.bandwidth())
      .attr('height', (d) => height - margin.bottom - yScale(d.value))
      .attr('fill', '#3b82f6')
      .on('mouseover', function () {
        d3.select(this).attr('fill', '#1e3a8a');
      })
      .on('mouseout', function () {
        d3.select(this).attr('fill', '#3b82f6');
      });
  }, [data, dimensions]);

  return (
    <div ref={wrapperRef} className="w-full h-[400px]">
      <svg ref={svgRef} className="w-full h-full"></svg>
    </div>
  );
};

export default BarGraph;


import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireAdmin = () => {
    const { auth } = useAuth();
    const location = useLocation();

    console.log("RequireAdmin: $$%%^^????>>>>>", auth);

    return ( 

        auth.username!="admin" && location.pathname !== '/dashboard'
            ? <Navigate to="/dashboard" state={{ from: location }} replace />        
            : <Outlet />
    )
}

export default RequireAdmin;
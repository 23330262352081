import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireAuth = () => {
    const { auth } = useAuth();
    const location = useLocation();

    console.log("REQUIRE AUTH ---> ", auth)
    console.log(location);

    return ( 

        !auth?.accessToken && location.pathname !== '/login'
            ? <Navigate to="/login" state={{ from: location }} replace />
            : <Outlet />
    )
}

export default RequireAuth;
import React from "react"
import Sidebar from "../modules/sidebar"

import AccountNavbar from "../modules/accountNavbar"



const Layout = ({ children, scroll = false }) => {
    return (
      <React.Fragment>
        <AccountNavbar />
        <main
          className={
            scroll ? "h-full w-full overflow-auto" : "h-full w-full overflow-hidden"
          }
        >
          <div className="py-10 px-16 flex-2 h-full w-full justify-center bg-white mt-16">
            {children}
          </div>
        </main>
      </React.Fragment>
    );
  };

export default Layout
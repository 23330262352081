import React, { useRef, useEffect, useState } from 'react';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import moment from 'moment';
import useAuth from '../hooks/useAuth';
import useRefreshToken from '../hooks/useRefreshToken';

import Layout from '../context/layoutPortal.jsx';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

import Loading from '../modules/loading.jsx';

import MenuChecklist from '../modules/menuChecklist.jsx';

import { BsX } from 'react-icons/bs';


function arrayAlphebetize(array) {
  return array.sort((a, b) => a.localeCompare(b));
}

function removeDuplicatesFromArray(array) {  
  return array.filter((a, b) => array.indexOf(a) === b);
} 


export default function Form3() {

  const axiosPrivate = useAxiosPrivate();

  const auth = useAuth();
  const refresh = useRefreshToken();
  const navigate = useNavigate();
  const childRef = useRef();
  //let location = useLocation();

  const list_of_clients = arrayAlphebetize(Object.keys(auth.auth.divisionMap));

  const [clientList, setClientList] = useState(list_of_clients);

  const [isLoading, setIsLoading] = useState(true);
  const [client, setClient] = useState(list_of_clients[0] );
  const [destination, setDestination] = useState(auth.auth.divisionMap[list_of_clients[0]].split(";")[0],);

  const [customDestination, setCustomDestination] = useState(false);
  const [orderList, setOrderList] = useState([]);

  useEffect(() => {
    //const page = location.pathname;

    //console.log(auth);
    
  }, []);


  const addNewOrder = () => {
    setOrderList([...orderList, {quantity:"",unit:"",weight:"",description:""}])
  }

  const removeOrder = (index) => {
    const newPersonnelList = orderList.filter((item, i) => i !== index)
    setOrderList(newPersonnelList)
  }

  const onChangeOrder = (index, e) => {
    let newPersonnelList = orderList.map((item, i) => {
      if (i === index) {
        if (e.target.value.includes('"') || e.target.value.includes('`')) {}
        else {
          let newItem = item;
          newItem[e.target.name] = e.target.value
          return newItem
        }
      } else {
        return item
      }
    })
    setOrderList(newPersonnelList)
  }



  return (

    // MODULES

    // sidebar 
    // plot
    // miniTable

    <>    

    <Layout>      

      <>
          <div class="flex flex-wrap -mx-3 px-6 py-4">

              <h3 class="w-100 ml-4" >Form for picked up items</h3>

              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                      Client *
                  </label>
                  <select name="client" onChange={(e) => onChangeOrder(e)} class="shadow block w-full bg-white text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="client" type="text">
                      {auth.auth?
                      list_of_clients.map(d => {
                      return <option value={d}>{d}</option> 
                      })
                  :
                  <option value="default">default</option>
                  }
                  </select>
                  {/* <p class="text-red-500 text-xs italic">Please fill out this field.</p> */}
              </div>

              <div class="w-full md:w-1/2 px-3">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                      From *
                      {customDestination
                      ?<button onClick={() => setCustomDestination(false)} class="text-blue-500 ml-4 hover:text-blue-600 cursor-pointer hover:underline">CUSTOM</button>
                      :<button onClick={() => setCustomDestination(true)} class="text-blue-500 ml-4 hover:text-blue-600 cursor-pointer hover:underline">PREDEFINED</button>                                                    
                      
                      }
                  </label>
                  {customDestination
                  ?
                  <select name="destination" onChange={(e) => onChangeOrder(e)} class="shadow block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="destination" type="text" >
                      {auth.auth?
                      auth.auth.divisionMap[client].split(";").map(d => {
                          return <option value={d}>{d}</option>
                      })
                      :
                      <option value="default">default</option>
                      }
                  </select>
                  :
                  <input autoComplete='off' name="destination" onChange={(e) => onChangeOrder(e)} placeholder="Input custom destination..." class="shadow block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text"></input>
                  }
              </div>
          </div>

          <div class="h-100 w-100">

            <div class="flex w-100 pb-3 px-4">  
              <p class="text-black text-lg font-bold">Add Item List</p>              
              <button onClick={addNewOrder} class="flex ml-auto bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded " type="button">Add New</button>
            </div>

            <div class="h-[calc(100%-5em)] w-100 px-4">

              <div class="w-100 h-3/4 p-1 bg-gray-400 overflow-scroll">
                {orderList.map((order, index) => {
                  //console.log(orderList);
                  return (
                    <div class="w-100 flex h-10 my-2">

                      <div class="w-100">
                        <input  
                            onChange={(e) => onChangeOrder(index,e)} 
                            name="description" 
                            placeholder="Description" 
                            class="w-9/12 p-2 mb-2 ml-1 bg-white rounded-md border border-black" 
                            value={order.description}>                            
                        </input>
                      </div>
                      
                      <p class="w-6 mx-1 h-100 p-2 bg-white rounded">{index+1}</p>
                      <div class="w-100 mx-auto">
                        <input onChange={(e) => onChangeOrder(index,e)} name="name" placeholder="Personnel name" class="w-2/12 p-2 mb-2 bg-white rounded-md border border-black" value={order.name}></input>
                        
                      </div>
                      <button onClick={() => {removeOrder(index)}} class="p-2 my-1 hover:bg-red-500 bg-red-400 rounded mx-1">
                        <BsX color="white"/>
                      </button>
                    </div>     
                  )}
                )}
              </div>                                  
            
            </div>

          </div> 
  
      </>

    </Layout>
                
    </>
  );
}



import React, { useRef, useEffect } from 'react';
import { useLocation, Switch, Link } from 'react-router-dom';

import LayoutLanding from '../context/layoutLanding.jsx';

import { FiMapPin, FiCheckCircle, FiPlusCircle, FiSun, FiMoon, FiChevronRight } from 'react-icons/fi';
import { GiNightSky } from 'react-icons/gi';
import { GiTrade } from 'react-icons/gi';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';

// import svg file
import  { ReactComponent as ChevronRight} from '../../static/assets/icons/chevron-right.svg';


import bollard from '../../static/assets/icons/bollard.svg';


export default function LandingTransport() {

  const childRef = useRef();
  let location = useLocation();

  //useEffect(() => {
  //  const page = location.pathname;
  //  document.body.classNameList.add('is-loaded')
  //  childRef.current.init();
  //  trackPage(page);
  //  // eslint-disable-next-line react-hooks/exhaustive-deps
  //}, [location]);

  return (

    <LayoutLanding>
      
        
      <div class="flex flex-col w-100">
        {/* <div class="bg-white py-24 px-12 sm:py-20  w-100 my-auto"> */}

          <div id="class_wrapper" class="gradient-bg relative w-100 mt-20 p-10 py-40 shadow-md">
                <h2 class="ibm my-6 text-5xl tracking-tight text-center text-gray-700">
                Warehousing
                </h2>
                {/* <h2 class="w-full text-3xl font-bold text-center sm:text-4xl md:text-5xl">Custom Logistics</h2> */}
                <p class="ibm w-full py-8 mx-auto -mt-2 text-lg text-center text-gray-700 intro sm:max-w-3xl">
                At our warehouses, we understand that businesses' storage requirements can change rapidly based on their demand. 
                Our flexible warehousing solutions offer scalability to help businesses adapt to changing circumstances. Whether you're 
                expanding or downsizing, we can help you adjust your storage capacity to meet your needs. 
                </p>
                <Link
                  to="/contact"
                  class="flex hover:text-white h-12 w-40 no-underline rounded-lg bg-gray-0 border-2 border-gray-700 text-gray-700 px-4 hover:bg-gray-700 mx-auto my-auto"
                >
                  <p class="flex my-auto">Get Quote </p>
                  <span class="flex ml-auto">
                    <ChevronRight class="h-5 flex my-auto stroke-gray-700 fill-gray-700 accent-gray-700"/>
                  </span>                  
                </Link>
            </div>

              {/* <header class="mb-9 space-y-1 px-6">
                <p class="font-display text-sm font-medium text-indigo-600 ">WE HAVE AN EXCELLENT TRACK RECORD THROUGHOUT THE INDUSTRY</p>
                <h2 class="mb-8 text-4xl tracking-tight font-extrabold  text-gray-800" style={{color: "url(#blue-gradient)"}}>
                  Transportation
                </h2>
              </header> */}




          <div class="flex flex-col shadow-lg py-12 h-full mx-auto space-y-2 xl:px-24 w-100 bg-white">

                
                <div class="py-4 w-1/2 mx-auto pt-10">
                  <div class="flex">
                    <img class="h-7 my-auto" src={bollard} alt="" width={20}/>
                    <h2 class="ibm ml-2 flex my-6 text-2xl tracking-tight font-extrabold text-gray-700">                  
                      Almost Anything
                    </h2>
                  </div>
        
                <p class="ibm">
                Our warehousing services are designed to accommodate almost anything you need stored, no matter how big or small. 
                We have a variety of storage options to cater to 
                different types of products, ensuring the safety and security of your goods. We can provide flexible arrangements for both long-term 
                and short-term storage solutions.
                <br></br>
                <br></br>
                Our storage options are designed to meet your specific business needs. Our team works closely with you to tailor solutions that  
                help streamline your operations, reduce costs, and maximize 
                efficiency. Our online platform also helps
                businesses optimize their inventory management by providing visibility of their stock, helping them avoid stockouts
                and minimize holding costs.
                <br></br>
                <br></br>
                We also offer value-added services that can help businesses streamline their operations. 
                We provide packing and unpacking, palletization, labeling, and repacking services. We can also handle returns, 
                reverse logistics, and provide order fulfillment services. We are equipped to handle all your 
                logistics needs and can help you save time and money by providing turnkey solutions that work for your business.           
                </p>
                </div>

                <div class="mx-auto border-gray-200 w-1/2 border-b-2 h-12 mb-6"></div>

                <div class="py-4 w-1/2 mx-auto pt-10">
                  <div class="flex">
                    <img class="h-7 my-auto" src={bollard} alt="" width={20}/>
                    <h2 class="ibm ml-2 flex my-6 text-2xl tracking-tight font-extrabold text-gray-700">                  
                      Complete Storage
                    </h2>
                  </div>              
                <p class="ibm">
                We are able to handle all types of equipment, dangerous goods and delicate materials. 
                We use quality packaging material 
                and secure storage options to prevent any damage during storage or transport. 
                This flexibility allows businesses to adjust their storage requirements to meet changing demand, avoiding 
                the need to invest in expensive infrastructure and equipment. 
                <br></br>
                <br></br>
                We also take security very seriously at our warehouses. Our facilities are equipped with security measures to ensure the safety and 
                protection of your goods. We have 24/7 surveillance cameras, access control systems, and alarm systems to monitor our facilities. 
                You can trust that your goods are in safe hands.
                </p>
                </div>

                <div class="mx-auto border-gray-200 w-1/2 border-b-2 h-12 mb-6"></div>

                <div class="py-4 w-1/2 mx-auto pt-10">
                  <div class="flex">
                    <img class="h-7 my-auto" src={bollard} alt="" width={20}/>
                    <h2 class="ibm ml-2 flex my-6 text-2xl tracking-tight font-extrabold text-gray-700">                  
                      
                      Quick Access
                    </h2>
                  </div>

                <p class="ibm">
                We place a lot of importance on maintaining quick turnaround times. Our streamlined processes and experienced personnel 
                ensure that your products are received, stored, and dispatched in the shortest time possible. We leverage technology to 
                facilitate efficient order processing and tracking and our inventory management system provides real-time visibility of your 
                goods. We offer flexible 
                delivery options, including same-day and next-day delivery, to meet urgent or time-sensitive needs. 
                This means that you can expect high levels of consistency and reliability from our warehousing services.
                <br></br>
                <br></br>                        
                In summary, our warehousing services offer businesses flexible, scalable, and cost-effective solutions to manage their 
                storage and logistics operations. With a wide range of storage options and value-added services, we can accommodate any 
                business need. Our focus on security, reliability, and customer service ensures that your goods are in safe 
                hands and that you can trust us to manage your logistics effectively. Contact us today to learn how we can help your 
                business succeed.                  
                <br></br>
                <br></br>
                </p>
                </div>
              </div>

              {/* <div class="p-4 border-t-2 border-gray-700 prose prose-slate max-w-none dark:prose-invert dark:text-slate-400 prose-headings:scroll-mt-28 prose-headings:font-display prose-headings:font-normal lg:prose-headings:scroll-mt-[8.5rem] prose-lead:text-slate-500 dark:prose-lead:text-slate-400 prose-a:font-semibold dark:prose-a:text-sky-400 prose-a:no-underline prose-a:shadow-[inset_0_-2px_0_0_var(--tw-prose-background,#fff),inset_0_calc(-1*(var(--tw-prose-underline-size,4px)+2px))_0_0_var(--tw-prose-underline,theme(colors.sky.300))] hover:prose-a:[--tw-prose-underline-size:6px] dark:[--tw-prose-background:theme(colors.slate.900)] dark:prose-a:shadow-[inset_0_calc(-1*var(--tw-prose-underline-size,2px))_0_0_var(--tw-prose-underline,theme(colors.sky.800))] dark:hover:prose-a:[--tw-prose-underline-size:6px] prose-pre:rounded-xl prose-pre:bg-slate-900 prose-pre:shadow-lg dark:prose-pre:bg-slate-800/60 dark:prose-pre:shadow-none dark:prose-pre:ring-1 dark:prose-pre:ring-slate-300/10 dark:prose-hr:border-slate-800">
                <p class="text-center font-bold text-black my-8">
                    We want to hear from you. Get in touch with us to learn more about our B2B transport, warehousing and logistics services. 
                </p>
                
                <div class="w-100 flex mb-6">
                  <a href="/contact" class="mx-auto inline-flex no-underline items-center justify-center w-full px-6 py-3 mb-2 text-lg text-white bg-gray-800 hover:!bg-gray-900 rounded sm:w-auto sm:mb-0">
                        Contact Us
                        <span class="ml-2">
                          <IoIosArrowForward />
                        </span>
                    </a>
                  </div>
              </div> */}


            </div>
            



    </LayoutLanding>

  );
}
































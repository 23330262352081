import React from "react"
import Navbar from "../modules/navbar"
import Footer from "../modules/footer"
import FooterLanding from "../modules/footerLanding"

const Layout = ({ children }) => {
    return (
        <main class="flex h-100 w-full overflow-hidden">

            <div className=" h-100 overflow-scroll pb-0 pt-0 flex flex-col h-100 w-100 bg-transparent scrollbar-hide">

            {/* <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
                <pattern id="dot-pattern" width="40" height="40" patternUnits="userSpaceOnUse">
                    <circle fill="#aaa" cx="20" cy="20" r="2"></circle>
                </pattern>
                <rect width="100%" height="100%" fill="url(#dot-pattern)"></rect>
            </svg> */}
                
                <Navbar />                
                {children}
                <FooterLanding />                
            </div>
            
        </main>
    )
}

export default Layout

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

//import logo from '../../static/assets/images/image832_white.png';
import logo from '../../static/assets/images/logo_big_blue.png';


import { LuWarehouse } from "react-icons/lu";

export default function Navbar() {

    const [dropdownOpen, setDropdownOpen] = useState(false);
  
    return (

              <div class="bg-white w-100 z-20 shadow">
                  <div class="px-12 flex justify-between">
                    <div class="flex space-x-1">
                      <Link to="/" class="flex h-6 my-auto "><img src={logo} height={100} width={100} alt="logo"></img></Link>
                      <div class="w-6 my-3 text-gray-300 border-r-2 border-gray-400"></div>
                      
                      <div class="my-auto pl-10">
                        <div class="relative inline-block text-left">
                              <div>
                                  <button type="button" 
                                      onClick={() => setDropdownOpen(!dropdownOpen)}
                                      class=" h-14 overflow-hidden flex hover:bg-gray-200 w-full max-w-[16rem] justify-center gap-x-1.5 bg-gray-0 px-3 py-2 text-gray-900 " id="menu-button" aria-expanded="true" aria-haspopup="true">                                    
                                      {/* class="h-14 content-center hover:bg-gray-200 navbar_link hover:text-gray-200 text-black no-underline font-medium tracking-wide cursor-pointer px-3" */}
                                        <span class="navbar_link ibm my-auto">Services</span>                 
                                        <svg class="my-auto size-5 text-gray-400 " viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                                            <path fill-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
                                        </svg>
                                  </button>                        
                              </div>
                              <div
                                className={`
                                  absolute left-0 z-10 mt-2 w-72 origin-top-right
                                  rounded-md bg-white ring-1 ring-black/5 shadow-lg focus:outline-hidden 
                                  transform transition-all duration-200
                                  ${dropdownOpen ? 'scale-y-100 opacity-100' : 'scale-y-0 opacity-0 pointer-events-none'}
                                `}
                                role="menu"
                                aria-orientation="vertical"
                                aria-labelledby="menu-button"
                                tabIndex="-1"
                              >
                                <div className="py-1 p-2" role="none">
                                      <div className="py-2">
                                        <Link to="/delivery" 
                                          class="w-100 flex-col no-underline text-base text-gray-900 flex hover:bg-slate-100 px-2 py-1 rounded-lg">
                                          {/* <span class="flex my-auto">
                                              <LuWarehouse class="my-auto" />
                                          </span> */}
                                          <span class="flex text-lg font-bold my-auto whitespace-nowrap text-md ibm">Delivery</span>                    
                                          <p class="ibm text-sm mb-0">Move any type of goods.</p>
                                        </Link>
                                      </div>
                                      <div className="py-2">
                                        <Link to="/transport" 
                                          class="w-100 flex-col no-underline text-base text-gray-900 flex hover:bg-slate-100 px-2 py-1 rounded-lg">
                                          {/* <span class="flex my-auto">
                                              <LuWarehouse class="my-auto" />
                                          </span> */}
                                          <span class="flex text-lg font-bold my-auto whitespace-nowrap text-md ibm">Transport</span>                    
                                          <p class="ibm text-sm mb-0">Transport for personnel and items.</p>
                                        </Link>
                                      </div>
                                      <div className="py-2">
                                        <Link to="/warehousing" 
                                          class="w-100 flex-col no-underline text-base text-gray-900 flex hover:bg-slate-100 px-2 py-1 rounded-lg">
                                          {/* <span class="flex my-auto">
                                              <LuWarehouse class="my-auto" />
                                          </span> */}
                                          <span class="flex text-lg font-bold my-auto whitespace-nowrap text-md ibm">Warehousing</span>                    
                                          <p class="ibm text-sm mb-0">
                                            Store your goods in our warehouses.
                                          </p>
                                        </Link>
                                      </div>
                                      {/* <div className="py-2">
                                        <Link to="/logistics" 
                                          class="w-100 flex-col no-underline text-base text-gray-900 flex hover:bg-slate-100 px-2 py-1 rounded-lg">
                                          <span class="flex font-bold text-lg my-auto whitespace-nowrap text-md ibm">Logistics</span>                    
                                          <p class="ibm text-sm mb-0">
                                            Logistics and supply chain management.
                                          </p>
                                        </Link>
                                      </div> */}
                                </div>
                              </div>
                          
                        </div>
                      </div>
                      
                      {/* <a href="" class="h-14 content-center hover:bg-gray-200 navbar_link hover:text-blue-500 text-black no-underline font-medium tracking-wide cursor-pointer px-3">Transport</a> */}
                      {/* <a href="/delivery" class="h-14 content-center hover:bg-gray-200 navbar_link hover:text-gray-200 text-black no-underline font-medium tracking-wide cursor-pointer px-3">Delivery</a> */}
                      {/* <a href="/warehousing" class="h-14 content-center hover:bg-gray-200 navbar_link hover:text-gray-200 text-black no-underline font-medium tracking-wide cursor-pointer px-3">Warehousing</a> */}
                      {/* <a href="/logistics" class="h-14 content-center hover:bg-gray-200 navbar_link hover:text-gray-200 text-black no-underline font-medium tracking-wide cursor-pointer px-3">Logistics</a> */}
                      <Link to="/about" 
                        class="h-14 content-center hover:bg-gray-200 navbar_link hover:text-gray-200 text-black no-underline font-medium tracking-wide cursor-pointer px-3"
                      >
                          About
                        </Link>
                      <Link to="/contact" class="ibm h-14 content-center hover:bg-gray-200 navbar_link hover:text-gray-200 text-black no-underline font-medium tracking-wide cursor-pointer px-3">Contact</Link>                
                      <Link to="/contact?q=apply" class="ibm h-14 content-center hover:bg-gray-200 navbar_link hover:text-gray-200 text-black no-underline font-medium tracking-wide cursor-pointer px-3">Apply</Link>                
                      <a href="/#faq" class="ibm h-14 content-center hover:bg-gray-200 navbar_link hover:text-gray-200 text-black no-underline font-medium tracking-wide cursor-pointer px-3">FAQ</a>                
                    </div>

                    <div  class="flex items-center space-x-8 ">
                    </div>
            
                    <div class="flex items-center space-x-6">
                      <Link to="/info" class="bg-gray-700 text-white hover:bg-gray-800 font-medium no-underline tracking-wide py-2 px-4 rounded transition duration-300 ease-in-out">Get Started</Link>
                      <Link to="/login" class="bg-blue-700 text-white hover:bg-blue-800  font-medium no-underline tracking-wide py-2 px-4 rounded transition duration-300 ease-in-out">Sign In</Link>
                      {/* <a href="#" class="text-gray-300 hover:text-white font-medium tracking-wide no-underline">English</a> */}
                    </div>
                  </div>
                </div>

    )

}
import React, { useRef, useEffect, useState } from 'react';
import { Navigate, useNavigate, Link } from 'react-router-dom';

import moment from 'moment';

import useAuth from '../hooks/useAuth';

import Searcher from '../modules/searcher.jsx'
import Table from '../modules/table';
import MenuEditOrders from '../modules/menuEditOrders';
import MenuDownloadData from '../modules/menuDownloadData';
import Loading from '../modules/loading';
import Layout from '../context/layoutPortal.jsx';
import Popup from '../context/layoutPopup.jsx';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAxiosPrivateBlob from '../hooks/useAxiosPrivateBlob';

import { BsX } from 'react-icons/bs';
import { Checkbox } from '@mui/material';
import { IoMdDownload } from 'react-icons/io';

import { PDFViewer } from '@react-pdf/renderer';
import { Document, Page } from '@react-pdf/renderer';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { HiOutlineRefresh } from 'react-icons/hi';
import { GrDocumentText } from "react-icons/gr";
import { IoFilterSharp } from "react-icons/io5";

import { Buffer } from 'buffer';

import { Tooltip } from 'react-tooltip'
import {Button, ButtonGroup} from "@nextui-org/button";
import { BsDownload } from "react-icons/bs";
import { BsLayoutThreeColumns } from "react-icons/bs";
import { csvParse, local } from 'd3';

import Dropdown from "../modules/dropdown.jsx"


function arrayAlphebetize(array) {
    return array.sort((a, b) => a.localeCompare(b));
}

function removeDuplicatesFromArray(array) {
    return array.filter((a, b) => array.indexOf(a) === b);
} 

function toTimestamp(strDate) {
    var datum = Date.parse(strDate);
    return datum;// /1000;
}
  
function toDatetimeString(timestamp) {
    var date = new Date(timestamp)
  
    var second = "00"
    var minute = date.toLocaleString("en-US", {minute: "numeric"})
    var hour = date.toLocaleString("en-US", {hour12: false, hour:"numeric"})
    var day = date.toLocaleString("en-US", {day: "numeric"}) 
    var month = date.toLocaleString("en-US", {month: "numeric"}) 
    var year = date.toLocaleString("en-US", {year: "numeric"})
    
    if (minute.length == 1) { minute = `0${minute}`}
    if (hour.length == 1) { hour = `0${hour}`}
    if (day.length == 1) { day = `0${day}`}  
    if (month.length == 1) { month = `0${month}` }
  
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`
}

function objectToCSV(data) {
    const csvRows = [];
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(','));

    for (const row of data) {
        const values = headers.map(header => {
            const escaped = (''+row[header]).replace(/"/g, '\\"');
            return `"${escaped}"`;
        });
        csvRows.push(values.join(','));
    }

    return csvRows.join('\n');
}


export default function Orders({ notify }) {

    const auth = useAuth();    
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const axiosPrivateBlob = useAxiosPrivateBlob();

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [editData, setEditData] = useState({});
    const [showEditMenu, setShowEditMenu] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isSelected, setIsSelected] = useState(false);
    const [showTableMenu, setShowTableMenu] = useState(false);
    
    const [clientList, setClientList] = useState(arrayAlphebetize(Object.keys(auth.auth.divisionMap)));
    const [destinationList, setDestinationList] = useState(arrayAlphebetize(removeDuplicatesFromArray(arrayAlphebetize(Object.values(auth.auth.divisionMap).flatMap((division) => division.split(';')).slice(1)))));

    const [filterClient, setFilterClient] = useState("All");
    const [filterDestination, setFilterDestination] = useState("All");
    
    const [searchGroup, setSearchGroup] = useState("");
    const [searchValue, setSearchValue] = useState("");

    const [popupMessage, setPopupMessage] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [showDownloadDataMenu, setShowDownloadDataMenu] = useState(false);

    const [PDFLink, setPDFLink] = useState("");
    const [PDFBlob, setPDFBlob] = useState(null);
    const [showPdfViewer, setShowPdfViewer] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);

    const [totalWeight, setTotalWeight] = useState(0.0);

    const [sort, setSort] = useState("up");

    const [statusFilterOpen, setStatusFilterOpen] = useState(false);
    const [destinationFilterOpen, setDestinationFilterOpen] = useState(false);
    const [columnFilterOpen, setColumnFilterOpen] = useState(false);
    const [clientFilterOpen, setClientFilterOpen] = useState(false)

    const [searcherPreset, setSearcherPreset] = useState("");

    const dropdownRef = useRef(null);

    const searchPreferences = localStorage.getItem("searchPreferences") ? JSON.parse(localStorage.getItem("searchPreferences")) 
    : {
        inventory: true,    
        pending: true,
        delivered: false,
        damaged: false,
        delayed: false,
        lost: false,
    };


    const [filterStatus, setFilterStatus] = useState(searchPreferences);

    const [datasets, setDatasets] = useState({
        from:[],
        to:[], 
        carriers:[]
    });

    const [columns, setColumns] = useState([
            { 
                id: 'edit', 
                label: 'EDIT', 
                minWidth: 50,
                align: 'left',
                hidden: false,
                format: (value) => value.toLocaleString('en-US'),
            },
            { 
                id: 'doc', 
                label: 'DOC', 
                minWidth: 50,
                align: 'left',
                maxWidth: 50,
                hidden: false,
                format: (value) => value.toLocaleString('en-US'),
            },     
            {
                id: 'status',
                label: 'STATUS',
                minWidth: 120,
                align: 'center',
                hidden: false,
                format: (value) => value.toLocaleString('en-US'),
            },   
            {
                id: 'datetime',
                label: 'DATE/TIME',
                minWidth: 220,
                align: 'left',
                hidden: false,
                render: value => moment(new Date(value)).format('YYYY-MM-DD HH:mm:ss')
                //format: (value) => value.toLocaleString('en-US'),
            },        
            {
                id: 'client',
                label: 'CLIENT',
                minWidth: 170,
                align: 'left',
                hidden: false,
                format: (value) => value.toFixed(2),
            },
            {
                id: 'destination',
                label: 'DESTINATION',
                minWidth: 170,
                align: 'left',
                hidden: false,
                format: (value) => value.toLocaleString('en-US'),
            },
            {
                id: 'reference',
                label: 'REFERENCE',
                minWidth: 170,
                align: 'left',
                hidden: false,
                format: (value) => value.toLocaleString('en-US'),
            },
            {
                id: 'supplier',
                label: 'SUPPLIER',
                minWidth: 170,
                align: 'left',
                hidden: false,
                format: (value) => value.toLocaleString('en-US'),
            },
            {
                id: 'unit',
                label: 'UNIT',
                minWidth: 170,
                align: 'left',
                hidden: false,
                format: (value) => value.toLocaleString('en-US'),
            },
            {
                id: 'quantity',
                label: 'QUANTITY',
                minWidth: 170,
                align: 'left',
                hidden: false,
                format: (value) => value.toLocaleString('en-US'),
            },
            {
                id: 'weight',
                label: 'WEIGHT',
                minWidth: 170,
                align: 'left',
                hidden: false,
                format: (value) => value.toLocaleString('en-US'),
            },
            {
                id: 'weightType',
                label: 'LBS/KGS',
                minWidth: 170,
                align: 'left',
                hidden: false,
                format: (value) => value.toLocaleString('en-US'),
            },
            {
                id: 'carrier',
                label: 'CARRIER',
                minWidth: 170,
                align: 'left',
                hidden: false,
                format: (value) => value.toLocaleString('en-US'),
            },
            {
                id: 'waybill',
                label: 'WAYBILL',
                minWidth: 170,
                align: 'left',
                hidden: false,
                format: (value) => value.toLocaleString('en-US'),
            },
            {
                id: 'id',
                //label: 'Size\u00a0(km\u00b2)',
                label: 'ID',
                minWidth: 170,
                align: 'left',
                hidden: false,
                format: (value) => value.toLocaleString('en-US'),
            },
            {
                id: 'checked',
                label: 'LAST UPDATE',
                minWidth: 170,
                align: 'left',
                hidden: false,
                format: (value) => value.toLocaleString('en-US'),
            },
            {
                id: 'description',
                label: 'DESCRIPTION',
                minWidth: 250,
                align: 'left',
                hidden: false,
                format: (value) => value.toLocaleString('en-US'),
            }, 
            {
                id: 'note',
                label: 'NOTE',
                minWidth: 170,
                align: 'left',
                hidden: false,
                format: (value) => value.toLocaleString('en-US'),
            },        
    ]);

    useEffect(() => {
        fetchOrdersData();

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);     

    const uploadFile = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsSelected(true);
    };
    
    const onSubmitSearch = async (group,value,filters) => {

        // check for URL param id
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        
        if(!value) {
            if (id) {
                navigate(`/orders`);
            }
            fetchOrdersData();
        }
        else {

            if (value.startsWith("id:")) {

                // update the URL param id
                navigate(`/orders?id=${value.split(":")[1]}`);

                const id = value.split(":")[1];
                const response = await axiosPrivate.get("/orders/id/"+id, { method: "GET", credentials: "include" });

                const destinationsList = [];
                const suppliersList = [];
                const unitsList = [];
                const carriersList = [];
                const data = [];

                let total_weight = 0.0;

                for (let i=0; i<response.data.length; ++i) {
                    if(response.data[i].destination) destinationsList.push(response.data[i].destination);
                    if(response.data[i].supplier) suppliersList.push(response.data[i].supplier);
                    if(response.data[i].unit) unitsList.push(response.data[i].unit);
                    if(response.data[i].carrier) carriersList.push(response.data[i].carrier);
                    
                    if(response.data[i].weight) {
                        if(response.data[i].weightType=="KGS"){                    
                            total_weight = total_weight + Number(response.data[i].weight)/2.20462
                        } else {
                            total_weight = total_weight + Number(response.data[i].weight)*1
                        }
                    }
        
                    data.push({
                        ...response.data[i], 
                        checked: moment(response.data[i].checked).format('YYYY-MM-DD HH:mm:ss'), 
                        datetime: moment(response.data[i].datetime).format('YYYY-MM-DD HH:mm:ss')
                    });
                }

                setTotalWeight(total_weight.toFixed(2));
                setDatasets({            
                    destination: [... new Set(destinationsList)],
                    supplier: [... new Set(suppliersList)],
                    unit: [... new Set(unitsList)],
                    carrier: [... new Set(carriersList)],                        
                })

                setData(data);
                setSearcherPreset(`id:${id}`);
                return;
            }

            // the value doesn't start with id, so its not an ID search, therefore 
            // the URL should just be /orders
            navigate("/orders");

            // const urlParams = new URLSearchParams(window.location.search);
            // const id = urlParams.get('id');
            // if (id) { 
            

            setIsLoading(true);
            setData([]);

            const params = {
                user: auth.auth.name,
                filter_client: filterClient=="All"?"%":filterClient,
                filter_destination: filterDestination=="All"?"%":filterDestination,
                filter_status: filterStatus,
                search_group: group,
                search_value: value,
            }

            //if (group == "reference" || group == "waybill" || group == "id" || group == "units") {
            const data = [];
            let response = await axiosPrivate.get("/orders/search", {params:params}, { method: "GET", credentials: "include" });                       

            let total_weight = 0.0
            
            for (let i=0; i<response.data.length; ++i) {

                if(response.data[i].weight) {
                    if(response.data[i].weightType=="KGS"){                    
                        total_weight = total_weight + Number(response.data[i].weight)/2.20462
                    } else {
                        total_weight = total_weight + Number(response.data[i].weight)*1
                    }
                }

                data.push({
                    ...response.data[i], 
                    checked: moment(response.data[i].checked).format('YYYY-MM-DD HH:mm:ss'), 
                    datetime: moment(response.data[i].datetime).format('YYYY-MM-DD HH:mm:ss')
                });
            }
            //console.log(data);
            setTotalWeight(total_weight.toFixed(2));
            setData(data);
            setIsLoading(false);            
        }
    }    
    
    const fetchOrdersData = async () => {   
        
        setIsLoading(true);      
        setData([]);

        // if there is a URL param for id, fetch that order
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');

        let response;

        if (id) {           

            response = await axiosPrivate.get("/orders/id/"+id, { method: "GET", credentials: "include" });

            setSearcherPreset(`id:${id}`);

        } else {

            const params = {
                user: auth.auth.name,
                filter_client: filterClient=="All"?"%":filterClient,
                filter_destination: filterDestination=="All"?"%":filterDestination,
                filter_status: filterStatus,
                search_group: searchGroup,
                search_value: searchValue,
            }
    
            response = await axiosPrivate.get("/orders", {params:params}, { method: "GET", credentials: "include" });                          
        }


        const destinationsList = [];
        const suppliersList = [];
        const unitsList = [];
        const carriersList = [];
        const data = [];        

        let total_weight = 0.0;

        for (let i=0; i<response.data.length; ++i) {
            if(response.data[i].destination) destinationsList.push(response.data[i].destination);
            if(response.data[i].supplier) suppliersList.push(response.data[i].supplier);
            if(response.data[i].unit) unitsList.push(response.data[i].unit);
            if(response.data[i].carrier) carriersList.push(response.data[i].carrier);
            
            if(response.data[i].weight) {
                if(response.data[i].weightType=="KGS"){                    
                    total_weight = total_weight + Number(response.data[i].weight)/2.20462
                } else {
                    total_weight = total_weight + Number(response.data[i].weight)*1
                }
            }

            data.push({
                ...response.data[i], 
                checked: moment(response.data[i].checked).format('YYYY-MM-DD HH:mm:ss'), 
                datetime: moment(response.data[i].datetime).format('YYYY-MM-DD HH:mm:ss')
            });
        };

        setTotalWeight(total_weight.toFixed(2));
        setDatasets({            
            destination: [... new Set(destinationsList)],
            supplier: [... new Set(suppliersList)],
            unit: [... new Set(unitsList)],
            carrier: [... new Set(carriersList)],                        
        })
        setData(data);        
        setIsLoading(false);
            
    }

	const editTableRow = (editData, code) => {
        setEditData(editData);
        setShowEditMenu(true);
    }

    const fetchPackingslip = async (data) => {

        setShowPopup(true)
        setPopupMessage(["Please wait","Retrieving packing slip"])

        setIsLoading(true);   
        let id = data.id;
        await axiosPrivateBlob.get("/packingSlip", {params:{id:id}})
        .then(response => {
            const file = new Blob([response.data], { type: "application/pdf" });
            const fileURL = window.URL.createObjectURL(file);
            window.open(fileURL);
            setPDFBlob(file);
            setPDFLink(fileURL);
            setShowPdfViewer(true);
            setIsLoading(false);
            setShowPopup(false);
        })
        .catch(error => {
            console.log(error);
            setIsLoading(false);
            setShowPopup(false);
        });        
    }

    const onSort = (colName) => {

        // ascending/descending: id/reference, quantity, weight, datetime, checked
        
        // alphabetical: destination, supplier, unit type, carrier, client
  
        // let colName = col.target.innerText.toLowerCase();
    
        let data_list = JSON.parse(JSON.stringify(data));
  
        //console.log(colName);
  
        
        if (colName=="waybill"||colName=="note") {
            sorted_data = data_list
        }
  
        // NUMERICAL
        if (colName=="quantity"||colName=="id") {
  
            if (sort=="up") {
                var sorted_data = data_list.sort((a, b) => Number(b[colName])-Number(a[colName]));
            }
            if (sort=="down") {
                var sorted_data = data_list.sort((a, b) => Number(a[colName])-Number(b[colName]));
            }          
        }
  
        // WEIGHT
        if (colName=="weight") {
            if (sort=="up") {
                var sorted_data = data_list.sort((a, b) => Number(b[colName].split(" ")[0])-Number(a[colName].split(" ")[0]));
            }
            if (sort=="down") {
                var sorted_data = data_list.sort((a, b) => Number(a[colName].split(" ")[0])-Number(b[colName].split(" ")[0]));
            }  
        }
  
        // REFERENCE
        if (colName=="reference") {
  
            if (sort=="up") {
                var sorted_data = data_list.sort((a, b) => Number(b[colName].replace("-",""))-Number(a[colName].replace("-","")));
            }
            if (sort=="down") {
                var sorted_data = data_list.sort((a, b) => Number(a[colName].replace("-",""))-Number(b[colName].replace("-","")));
            }  
        }
  
        // ALPHABETICAL
        if (colName=="destination"||colName=="supplier"||colName=="unit"||colName=="carrier"||colName=="status"||colName=="client"||colName=="weightType") {
  
            if (sort=="up") {
                var sorted_data = data_list.sort(function SortArrayObjects(x, y){
                    if (x[colName] < y[colName]) {return -1;}
                    if (x[colName] > y[colName]) {return 1;}
                    return 0;
                })
            }
            if (sort=="down") {
                var sorted_data = data_list.sort(function SortArrayObjects(x, y){
                    if (x[colName] > y[colName]) {return -1;}
                    if (x[colName] < y[colName]) {return 1;}
                    return 0;
                })
            }
        }
  
        // CHRONOLOGICAL
        if (colName=="datetime"||colName=="checked") {
  
            if (sort=="up") {
                var sorted_data = data_list.sort((a, b) => toTimestamp(b["datetime"])-toTimestamp(a["datetime"]));
            }
            if (sort=="down") {
                var sorted_data = data_list.sort((a, b) => toTimestamp(a["datetime"])-toTimestamp(b["datetime"]));
            }      
        }

        setData(sorted_data);
        setSort(sort=="up"?"down":"up");   
    }

    const savePreferences = async (preferences) => {
        setIsLoading(true)
        //console.log(preferences);
        // save preferences to localStorage
        localStorage.setItem("searchPreferences", JSON.stringify(filterStatus));
        setIsLoading(false)
        notify("Preferences saved", "success");
    }

    function downloadCSV(data) {
        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
    
        // Get current datetime
        const date = new Date();
        const timestamp = date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate() + "_" + date.getHours() + "-" + date.getMinutes() + "-" + date.getSeconds();
    
        a.setAttribute('hidden','');
        a.setAttribute('href', url);
        a.setAttribute('download', 'ldf_orders_' + timestamp + '.csv'); // Append timestamp to file name
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
    
    const clickDownload = () => {
        const csvData = objectToCSV(data);
        downloadCSV(csvData);
    }

    const displayDownloadDataMenu = () => {
        setShowDownloadDataMenu(!showDownloadDataMenu);
    }

    useEffect(() => {
        fetchOrdersData(); 
    }, [filterClient, filterDestination, filterStatus]);

    const onColumnFilter = (column) => {

        // { 
        //     id: 'edit', 
        //     label: 'EDIT', 
        //     minWidth: 50,
        //     align: 'left',
        //     format: (value) => value.toLocaleString('en-US'),
        // },

        console.log("COLUMN ---")
        console.log(column)
        
        // update the columns state, hide or show the column
        let column_array = JSON.parse(JSON.stringify(columns));
        for (let i=0; i<column_array.length; ++i) {
            if (column_array[i].id==column.id) {
                column_array[i].hidden = !column_array[i].hidden;
            }
        }

        setColumns(column_array);
    }

    const changeStatusFilter = (status_obj) => {

        // change the searchPreferences status filter
        setFilterStatus(status_obj);

        // update the searchPreferences in localStorage
        localStorage.setItem("searchPreferences", JSON.stringify(status_obj));

    }

    const handleClickOutside = (event) => {
        // Check if the click is outside the dropdown
        console.log(event.target.value)
        console.log(event.target.name)
        if (!event.target.value ) {
            setStatusFilterOpen(false);            
            setColumnFilterOpen(false);
            setClientFilterOpen(false);
            setDestinationFilterOpen(false);
        }
    }


    return (

            <Layout>
                
                {/* <button class="bg-black text-white" onClick={testFetch}>FETCH ORDERS</button> */}
                <div class="flex w-2/3 space-x-4 pb-3">
                    <Link to="/orders" class="ibm text-2xl font-bold mb-0 border-b-2 border-black text-black ">Orders</Link>
                    <Link to="/outgoing" class="ibm text-2xl text-gray-300 hover:text-gray-600 mb-0 cursor-pointer no-underline">Outgoing</Link>
                    <Link to="/inventory" class="ibm text-2xl text-gray-300 hover:text-gray-600 mb-0 cursor-pointer no-underline">Inventory</Link>
                </div>
                <div class="w-2/3">
                    <p>
                        These orders have been 
                        processed by our warehousing facility and are ready to be shipped to their
                        destination.
                    </p>
                </div>
                <div class="flex w-100 pt-1 h-[50px] pb-16">     
                    <Searcher                   
                        preset={searcherPreset}      
                        datasets={datasets}
                        notify={notify}                        
                        onEnter={onSubmitSearch}
                    />
                </div>
                <div 
                    class="w-100 flex pt-2 pb-2 border-t-2 border-gray-200">

                    <div class="flex mr-auto space-x-2">

                        {/* STATUS FILTER */}
                        <div
                            name="status"
                            ref={dropdownRef}
                            class="relative inline-block text-left">
                            <div>
                                <button type="button" 
                                    onClick={() => setStatusFilterOpen(!statusFilterOpen)}
                                    class="overflow-hidden inline-flex w-full max-w-[16rem] justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-900 ring-1 shadow-xs ring-gray-300 ring-inset hover:bg-gray-50" id="menu-button" aria-expanded="true" aria-haspopup="true">
                                    <p class="mb-0">Status</p>
                                    <p class="font-bold mb-0 ml-1 whitespace-nowrap overflow-hidden text-ellipsis">
                                        {filterStatus.inventory?"Inventory":""}
                                        {filterStatus.pending?", Pending":""}
                                        {filterStatus.delivered?", Delivered":""}
                                        {filterStatus.delayed?", Delayed":""}
                                        {/* if all none */}
                                        {filterStatus.inventory==false&&filterStatus.pending==false&&filterStatus.delivered==false&&filterStatus.delayed==false?"None":""}
                                    </p>
                                    <svg class="-mr-1 size-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                                        <path fill-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
                                    </svg>
                                </button>                        
                            </div>
                            {/* {statusFilterOpen? */}
                                {/* <div class="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white ring-1 shadow-lg ring-black/5 focus:outline-hidden" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1"> */}
                            <div
                                className={`
                                  absolute left-0 z-10 mt-2 w-56 origin-top-right
                                  rounded-md bg-white ring-1 ring-black/5 shadow-lg focus:outline-hidden 
                                  transform transition-all duration-200
                                  ${statusFilterOpen ? 'scale-y-100 opacity-100' : 'scale-y-0 opacity-0 pointer-events-none'}
                                `}
                                role="menu"
                                aria-orientation="vertical"
                                aria-labelledby="menu-button"
                                tabIndex="-1"
                              >
                                    <div class="py-1" role="none">                                                                        
                                        
                                        <div class="flex flex-row">
                                            <Checkbox onClick={() => changeStatusFilter({...filterStatus, inventory:!filterStatus.inventory})} checked={filterStatus.inventory}/>
                                            <p class="ibm text-md align-middle ml-2 my-auto flex">Inventory</p>
                                        </div>
                                        <div class="flex flex-row">
                                            <Checkbox onClick={() => changeStatusFilter({...filterStatus, pending:!filterStatus.pending})} checked={filterStatus.pending}/>
                                            <p class="ibm text-md align-middle ml-2 my-auto flex">Pending</p>
                                        </div>
                                        <div class="flex flex-row">
                                            <Checkbox onClick={() => changeStatusFilter({...filterStatus, delivered:!filterStatus.delivered})} checked={filterStatus.delivered}/>
                                            <p class="ibm text-md align-middle ml-2 my-auto flex">Delivered</p>
                                        </div>
                                        <div class="flex flex-row">
                                            <Checkbox onClick={() => changeStatusFilter({...filterStatus, delayed:!filterStatus.delayed})} checked={filterStatus.delayed}/>
                                            <p class="ibm text-md align-middle ml-2 my-auto flex">Delayed</p>
                                        </div>
                                        {/* <div class="flex flex-row">
                                            <Checkbox onClick={() => setFilterStatus({...filterStatus, damaged:!filterStatus.damaged})} checked={filterStatus.damaged}/>
                                            <p class="ibm text-md align-middle ml-2 my-auto flex">Damaged</p>
                                        </div>
                                        <div class="flex flex-row">
                                            <Checkbox onClick={() => setFilterStatus({...filterStatus, lost:!filterStatus.lost})} checked={filterStatus.lost}/>
                                            <p class="ibm text-md align-middle ml-2 my-auto flex">Lost</p>
                                        </div> */}

                                    </div>
                                </div>
                            {/* :null} */}
                        </div>
                        
                        {/* DESTINATION FILTER */}
                        <div 
                            ref={dropdownRef}
                            class="relative inline-block text-left">
                            <div>
                                <button type="button" 
                                    onClick={() => setDestinationFilterOpen(!destinationFilterOpen)}
                                    class="overflow-hidden inline-flex w-full max-w-[16rem] justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-900 ring-1 shadow-xs ring-gray-300 ring-inset hover:bg-gray-50" id="menu-button" aria-expanded="true" aria-haspopup="true">
                                    <p class="mb-0">Destination</p>
                                    <p class="font-bold mb-0 ml-1 whitespace-nowrap overflow-hidden text-ellipsis">
                                        {filterDestination}
                                    </p>
                                    <svg class="-mr-1 size-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                                        <path fill-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
                                    </svg>
                                </button>                        
                            </div>
                            {/* {destinationFilterOpen? */}
                                {/* <div class="absolute p-2 left-0 z-10 mt-2 w-60 origin-top-right rounded-md bg-white shadow-lg  focus:outline-hidden" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1"> */}
                                <div
                                className={`
                                  absolute left-0 z-10 mt-2 w-[400px] origin-top-right
                                  rounded-md bg-white ring-1 ring-black/5 shadow-lg focus:outline-hidden 
                                  transform transition-all duration-200
                                  ${destinationFilterOpen ? 'scale-y-100 opacity-100' : 'scale-y-0 opacity-0 pointer-events-none'}
                                `}
                                role="menu"
                                aria-orientation="vertical"
                                aria-labelledby="menu-button"
                                tabIndex="-1"
                              >
                                    <div class="py-1 overflow-y-scroll max-h-[24rem]" role="none">                                
                                        <div class="flex flex-row">
                                            <Checkbox onClick={() => setFilterDestination("All")} checked={filterDestination=="All"}/>
                                            <p class="ibm text-md align-middle ml-2 my-auto flex">All</p>
                                        </div>
                                        <hr class="border-gray-400"/>
                                        {destinationList.map((destination) => (
                                            <div class="flex flex-row">
                                                <Checkbox onClick={() => setFilterDestination(destination)} checked={filterDestination==destination}/>
                                                <p class=" text-md align-middle ml-2 my-auto flex">{destination}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            {/* :null} */}
                        </div>

                        {/* CLIENT */}
                        {auth.auth.username=="admin"?
                        <div 
                            ref={dropdownRef}
                            class="relative inline-block text-left">
                           <div>
                                <button type="button" 
                                    onClick={() => setClientFilterOpen(!clientFilterOpen)}
                                    class="overflow-hidden inline-flex w-full max-w-[16rem] justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-900 ring-1 shadow-xs ring-red-300 ring-inset hover:bg-red-50" id="menu-button" aria-expanded="true" aria-haspopup="true">
                                    <p class="mb-0 text-red-500">Client</p>
                                    <p class="font-bold mb-0 ml-1 text-red-500 whitespace-nowrap overflow-hidden text-ellipsis">
                                        {filterClient}
                                    </p>
                                    <svg class="-mr-1 size-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                                        <path fill-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
                                    </svg>
                                </button>                        
                            </div>
                            <div
                                className={`
                                  absolute left-0 z-10 mt-2 w-[400px] origin-top-right
                                  rounded-md bg-white ring-1 ring-black/5 shadow-lg focus:outline-hidden 
                                  transform transition-all duration-200
                                  ${clientFilterOpen ? 'scale-y-100 opacity-100' : 'scale-y-0 opacity-0 pointer-events-none'}
                                `}
                                role="menu"
                                aria-orientation="vertical"
                                aria-labelledby="menu-button"
                                tabIndex="-1"
                              >
                                    <div class="py-1 overflow-y-scroll max-h-[24rem]" role="none">                                
                                        <div class="flex flex-row">
                                            <Checkbox onClick={() => setFilterClient("All")} checked={filterClient=="All"}/>
                                            <p class="ibm text-md align-middle ml-2 my-auto flex">All</p>
                                        </div>
                                        <hr class="border-gray-400"/>
                                        {clientList.map((client, index) => {                              
                                            return (
                                                <div class="flex flex-row">
                                                    <Checkbox onClick={() => setFilterClient(client)} checked={filterClient==client}/>
                                                    <p class=" text-md align-middle ml-2 my-auto flex">{client}</p>
                                                </div>
                                            )                                                                                    })} 

                                    </div>
                                </div>

                        </div>
                        :null}   

                    </div>
                    

                    <div class="flex ml-auto space-x-4">         

                        {/* <div 
                            //onClick={clickDownload} 
                            onClick={displayDownloadDataMenu}
                            class="mr-2 w-36 h-10 outline flex bg-green-800 hover:bg-green-900 text-white font-bold rounded " type="button">
                            <p class="flex m-auto">Download<IoMdDownload class="ml-1" size={24}/></p>
                        </div>       
                        <div 
                            onClick={() => setShowTableMenu(true)} 
                            class="w-28 h-10 outline flex bg-blue-800 hover:bg-blue-900 text-white font-bold rounded " type="button">
                            <p class="flex m-auto">Filters</p>
                        </div>  */}

                        {/* REFRESH BUTTON */}
                        {isLoading?
                                <button 
                                    onClick={null}
                                    class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-900 ring-1 shadow-xs ring-gray-300 ring-inset hover:bg-gray-50" id="menu-button" aria-expanded="true" aria-haspopup="true"
                                >
                                    <span class="p-1 ml-1">
                                        <Loading type={1} size={16} />
                                    </span>
                                    <span class="mr-1 my-auto">
                                        Refresh 
                                    </span>
                                </button>
                                : 
                                <button 
                                    onClick={fetchOrdersData}
                                    class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-900 ring-1 shadow-xs ring-gray-300 ring-inset hover:bg-gray-50" id="menu-button" aria-expanded="true" aria-haspopup="true"
                                >                        
                                    <span class="p-1 ">
                                        <HiOutlineRefresh size={16} color={"inherit"}/>
                                    </span>
                                    <span class="mr-1 my-auto">
                                        Refresh 
                                    </span>
                                </button>
                        }

                        {/* COLUMN FILTER */}
                        <div 
                            ref={dropdownRef}
                            class="relative inline-block text-left">
                            <div>
                                <button type="button" 
                                    onClick={() => setColumnFilterOpen(!columnFilterOpen)}
                                    class="inline-flex w-full max-w-[16rem] justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-900 ring-1 shadow-xs ring-gray-300 ring-inset hover:bg-gray-50" id="menu-button" aria-expanded="true" aria-haspopup="true">
                                    <span class="p-1 mr-1">                                    
                                        <BsLayoutThreeColumns />
                                    </span>
                                    <p class="mb-0 my-auto">Columns</p>
                                    
                                    <svg class="-mr-1 size-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                                        <path fill-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
                                    </svg>
                                </button>                        
                            </div>
                            {/* {columnFilterOpen? */}
                                {/* <div class="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white ring-1 shadow-lg ring-black/5 focus:outline-hidden" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1"> */}
                                <div
                                className={`
                                  absolute left-0 z-10 mt-2 w-56 origin-top-right
                                  rounded-md bg-white ring-1 ring-black/5 shadow-lg focus:outline-hidden 
                                  transform transition-all duration-200
                                  ${columnFilterOpen ? 'scale-y-100 opacity-100' : 'scale-y-0 opacity-0 pointer-events-none'}
                                `}
                                role="menu"
                                aria-orientation="vertical"
                                aria-labelledby="menu-button"
                                tabIndex="-1"
                              >
                                    <div class="py-1 overflow-y-scroll max-h-[24rem]" role="none">                                  
                                        {/* <div class="flex flex-row">
                                            <Checkbox onClick={() => setFilterDestination("All")} checked={filterDestination=="All"}/>
                                            <p class="ibm text-md align-middle ml-2 my-auto flex">All</p>
                                        </div> */}
                                        {/* { 
                                            id: 'edit', 
                                            label: 'EDIT', 
                                            minWidth: 50,
                                            align: 'left',
                                            format: (value) => value.toLocaleString('en-US'),
                                        }, */}
                                        {/* <hr class="border-gray-300"/> */}
                                        {columns.map((column) => (
                                            column.id != "edit" && column.id !="doc"?
                                                <div class="flex flex-row pl-2">
                                                    <Checkbox onClick={() => onColumnFilter(column)} checked={!column.hidden}/>
                                                    <p class="ibm text-sm align-middle ml-2 my-auto flex">{column.label}</p>
                                                </div>
                                            :null                                            
                                        ))}
                                    </div>
                                </div>
                            {/* :null} */}
                        </div>

                        <div class="border-l border-gray-300 h-6 my-auto"></div>

                        <button 
                            onClick={displayDownloadDataMenu}
                            class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-slate-200 px-3 py-2 text-sm text-gray-900 ring-1 shadow-xs ring-slate-200 ring-inset hover:bg-slate-300" id="menu-button" aria-expanded="true" aria-haspopup="true">                        
                            <span class="p-1 mr-1">
                                <BsDownload size={16} />
                            </span>
                            <span class="my-auto">
                                Download
                            </span>
                        </button>

                    </div>
                </div>   

                {showPopup
                ? <Popup>
                    <div 
                        id="innerEditMenu" 
                        class="h-44 w-1/4 inline-block bg-white rounded-md border-slate-500 border-2 overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle" 
                        role="dialog" 
                        aria-modal="true" 
                        aria-labelledby="modal-title"
                    >
                        
                        <div class="max-h-12 w-100 px-6 mt-4 flex flex-col">
                            <h2 class="ibm text-black w-100 mt-3">{popupMessage[0]}</h2>       
                            <h3 id="loading" class="ibm !text-slate-400 text-md !bg-white mt-2">{popupMessage[1]}</h3>
                        </div>                        
                            
                    </div>
                  </Popup>                
                :null}

                {showTableMenu
                ? <Popup>
                    <div class="flex absolute top-5 right-5">
                        <button onClick={() => setShowTableMenu(false)} class="h-10 w-10 bg-gray-200 hover:bg-gray-300 rounded-full flex m-2">
                            <BsX class="m-auto" size={25}/>
                        </button>
                    </div>
                    <div id="innerEditMenu" class="h-3/6 w-1/2 max-h-[500px] pt-3 pb-0 inline-block bg-gray-200 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle" role="dialog" aria-modal="true" aria-labelledby="modal-title">
                        
                        <div class="max-h-12 flex w-100 px-6 pb-2 border-b-2 border-black">
                            <h3 class="text-gray-700 ibm">Order Filters</h3>
                        </div>                        

                        <div class="flex h-[calc(100%-9rem)] overflow-y-scroll">
                            <div class="h-100 flex flex-col w-1/2 px-12 py-6 ml-6 border-right border-r-2 border-black">
                                {/* <p class="ml-2 font-bold">Filter Status</p> */}
                                <div class="flex flex-row">
                                    <Checkbox onClick={() => setFilterStatus({...filterStatus, inventory:!filterStatus.inventory})} checked={filterStatus.inventory}/>
                                    <p class="ibm text-lg align-middle ml-2 my-auto flex">Inventory</p>
                                </div>
                                <div class="flex flex-row">
                                    <Checkbox onClick={() => setFilterStatus({...filterStatus, pending:!filterStatus.pending})} checked={filterStatus.pending}/>
                                    <p class="ibm align-middle ml-2 my-auto flex text-lg">Pending</p>
                                </div>
                                <div class="flex flex-row">
                                    <Checkbox onClick={() => setFilterStatus({...filterStatus, delivered:!filterStatus.delivered})} checked={filterStatus.delivered}/>
                                    <p class="ibm text-lg align-middle ml-2 my-auto flex">Delivered</p>
                                </div>
                                <div class="flex flex-row">
                                    <Checkbox onClick={() => setFilterStatus({...filterStatus, delayed:!filterStatus.delayed})} checked={filterStatus.delayed}/>
                                    <p class="ibm text-lg align-middle ml-2 my-auto flex">Delayed</p>
                                </div>
                                <div class="flex flex-row">
                                    <Checkbox onClick={() => setFilterStatus({...filterStatus, damaged:!filterStatus.damaged})} checked={filterStatus.damaged}/>
                                    <p class="ibm text-lg align-middle ml-2 my-auto flex">Damaged</p>
                                </div>
                                <div class="flex flex-row">
                                    <Checkbox onClick={() => setFilterStatus({...filterStatus, lost:!filterStatus.lost})} checked={filterStatus.lost}/>
                                    <p class="ibm text-lg align-middle ml-2 my-auto flex">Lost</p>
                                </div>
                            </div>

                            <div class="h-100 flex flex-col w-1/2 px-12 py-6 ml-6 border-black">

                                {auth.auth.username=="admin"?
                                <div class="w-100">
                                    <label class="ibm font-bold text-red-600 block uppercase tracking-wide text-md mb-2" for="grid-first-name">
                                        Client *
                                    </label>
                                    <select onChange={(e) => setFilterClient(e.target.value)} 
                                        class="w-100 bg-white text-gray-700 border-gray-700 border-2 rounded py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Jane" >
                                        <option value="All">All</option>
                                        <option class="py-2" disabled>---------------</option>
                                        {clientList.map((client, index) => {                              
                                            return (
                                                <option value={client} selected={client==filterClient?true:false}>{client}</option>
                                            )                                            
                                        })}                            

                                    </select>
                                    {/* <p class="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                </div>
                                :null}                                
                                
                                <div class="w-full">
                                    <label class="ibm text-md block uppercase tracking-wide text-gray-700 font-bold mb-2" for="grid-first-name">
                                        Destination
                                    </label>
                                    <select onChange={(e) => setFilterDestination(e.target.value)} 
                                        class="w-100 bg-white border-2 border-gray-700 text-gray-700  rounded py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Jane" >
                                        <option value="All">All</option>
                                        <option class="py-2" disabled>---------------</option>
                                        {destinationList.slice(1).map((destination, index) => {                                
                                            return (
                                                <option value={destination} selected={destination==filterDestination?true:false}>{destination}</option>
                                            )
                                        })}
                                    </select>
                                    {/* <p class="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                </div>
                                
                            </div>
                        </div>

                        <div class="flex ">
                            <div class="max-h-12 flex px-6 pt-6 border-t-2 border-black">
                                <div class="w-100 mt-auto flex">
                                    {!isLoading?
                                    <div onClick={savePreferences} class=" ml-auto w-36 h-10 flex bg-blue-600 hover:bg-blue-700 text-white font-bold rounded" type="button">
                                        <p class="ibm flex m-auto">Save Settings</p>
                                    </div> 
                                    :   
                                    <div class="  w-36 h-10 flex bg-slate-500 text-white font-bold rounded pointer-events-none ml-auto" type="button">
                                        <span class="flex m-auto"><Loading type={1} /></span> 
                                    </div> 
                                    }
                                </div> 
                            </div>

                            <div class="max-h-12 flex w-100 px-6 pt-6 border-t-2 border-black">
                                <div class="w-100 mt-auto flex">
                                    {!isLoading?
                                    <div onClick={fetchOrdersData} class=" ml-auto w-36 h-10 flex bg-slate-100 hover:bg-slate-300 text-gray-700 border-2 border-gray-700 font-bold rounded" type="button">
                                        <p class="ibm flex m-auto">Update</p>
                                    </div> 
                                    :   
                                    <div class="  w-36 h-10 flex bg-slate-300 text-gray-700 border-2 border-gray-700 font-bold rounded pointer-events-none ml-auto" type="button">
                                        <span class="flex m-auto"><Loading type={1} /></span> 
                                    </div> 
                                    }
                                </div> 
                            </div>
                        </div>
                            
                    </div>
                  </Popup>                
                :null}

                {showDownloadDataMenu
                ? <MenuDownloadData 
                    data={data}
                    toggleDisplay={displayDownloadDataMenu}
                />
                :null}

                {showEditMenu 
                ? <MenuEditOrders 
                    data={editData}
                    divisionMap={auth.auth.divisionMap} 
                    onToggleDisplay={setShowEditMenu}
                    updateParent={fetchOrdersData}
                    uploadFile={uploadFile}
                    notify={notify}
                />                      
                :null}       

                <div id="table-wrapper" class="w-100 pb-10">
                    <Table 
                        username={auth.auth.username}
                        data={data} 
                        loading={isLoading}
                        editQuantities={false}
                        columns={columns} 
                        editFunc={editTableRow}
                        fileFunc={fetchPackingslip}
                        onSort={onSort}          
                        totalWeight={totalWeight}          
                    />
                </div>
            </Layout>

    )
}
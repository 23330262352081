
import React, { useState, useEffect } from 'react';



export default function Showcase({ children, bgColor = "bg-white" }) {
    
  
    return (

        <div className={`flex-1 overflow-auto ${bgColor} py-20 shadow`}>

            {children}
        </div> 

    )

}

